let frontTraceId = '';

/** 仿uuid格式生成前端traceId,保持和mk对齐，不使用uuidv4，保证qps1000不重复 */
function generateUniqueTraceId() {
    const hex = '0123456789abcdef';

    // 生成指定长度的随机十六进制字符串
    function randomHex(length) {
        return Array.from({ length }, () => hex[Math.floor(Math.random() * 16)]).join('');
    }

    // 获取当前时间戳(毫秒)并转换为16进制
    const timestamp = Date.now().toString(16);

    // 生成UUID的各个部分
    const p1 = timestamp.padStart(12, '0'); // 使用时间戳作为前12位
    const p2 = randomHex(4);
    const p3 = '4' + randomHex(3); // 版本4
    const p4 = hex[(Math.random() * 4) | (0 + 8)] + randomHex(3); // 变体
    const p5 = randomHex(12);

    // 组合各部分,使用连字符分隔
    return `${p1}-${p2}-${p3}-${p4}-${p5}`;
}

export const getFrontTraceId = () => {
    if (!frontTraceId?.length) {
        frontTraceId = generateUniqueTraceId();
    }
    return frontTraceId;
};

export const enableExtraHeaderHost = [
    //建站c端staging
    'https://lp.staging.kuaishou.com',
    //建站b端beta
    'https://beta-moli.kuaishou.com',
    //建站c端prt
    'https://moli.prt.kuaishou.com',
    //建站b端online
    'https://moli.kuaishou.com',
    //建站c端online
    'https://chenzhongkj.com',
    //线索通staging
    'https://ad-clue-laotie.staging.kuaishou.com',
    //线索通beta
    'https://clue-beta.corp.kuaishou.com',
    //线索通prt
    'https://xiansuo.prt.kuaishou.com',
    //线索通online
    'https://xiansuo.kuaishou.com',
];
/**
 * 根据请求URL和原始headers，处理并返回新的headers。
 * 如果请求URL匹配预设的域名列表，则在headers中添加'front-trace-id'字段，用于核心链路前后端串联
 *
 * @param requestUrl 请求的URL
 * @param headers 原始的headers对象
 * @returns 处理后的headers对象
 */
export const handleRequestHeaders = (requestUrl: string) => {
    if (
        !requestUrl.includes('https') ||
        enableExtraHeaderHost.some((item) => {
            const requestUrlHost = requestUrl.split('?')?.[0];
            return requestUrlHost?.includes(item);
        })
    ) {
        return getFrontTraceId();
    }
    return undefined;
};
