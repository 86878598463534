import * as React from 'react';
import cs from 'classnames';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';

export interface DragMarqueeProps {
    dataList: ListItem[];
    wrapperStyle?: any;
    avatarStyle?: any;
    descStyle?: any;
}

export interface ListItem {
    id: number;
    iconUrl: string;
    desc: string;
    fade?: boolean;
}
let uid = 0;
const MARQUEE_LO0P_TIME = 1200;

const DragMarquee: React.FunctionComponent<DragMarqueeProps> = (props) => {
    const { dataList, wrapperStyle } = props;
    const [list, setList] = React.useState<ListItem[]>(
        [...dataList].map((item) => Object.assign({}, item, { id: ++uid })),
    );
    const [startAnimation, setStartAnimation] = React.useState(false);

    React.useEffect(() => {
        setList([...dataList].map((item) => Object.assign({}, item, { id: ++uid })));
        console.log('DragMarquee list', dataList);
    }, [dataList]);

    const loopList = React.useCallback(() => {
        setList((pre) => {
            const res = pre.slice(1);
            res.push({ ...pre.shift(), id: ++uid } as ListItem);
            return res;
        });
    }, []);
    React.useEffect(() => {
        let subTimer: number;
        const timer = window.setInterval(() => {
            setStartAnimation(true);
            subTimer = window.setTimeout(() => {
                loopList();
                setStartAnimation(false);
            }, MARQUEE_LO0P_TIME);
        }, MARQUEE_LO0P_TIME * 2);
        return () => {
            clearInterval(timer);
            clearTimeout(subTimer);
        };
    }, [loopList]);
    return (
        <Div className={styles.root}>
            <Div
                style={{
                    ...(wrapperStyle || {}),
                }}
            >
                {list.map((item, i) => (
                    <div
                        key={item.id}
                        className={cs(styles.wrapper, {
                            [styles.fadeout]: i === 0 && startAnimation,
                            [styles.fadeIn]: i !== 0 && startAnimation,
                        })}
                    >
                        <img className={styles.avatar} src={item.iconUrl} alt="头像" />
                        <div className={styles.desc}>{item.desc}</div>
                    </div>
                ))}
            </Div>
        </Div>
    );
};

export default DragMarquee;
