import * as React from 'react';
import { useCallback } from 'react';
import { useMount } from 'react-use';
import { useComContext, useRenderState } from 'omega-render';
import { useSelector } from 'react-redux';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import cs from 'classnames';
import Button, { ButtonProps, DynamicType } from '../ButtonDrag/Button';
import { Div } from '../Blank/Blank';
import styles from './style.module.less';
import { getText, useAppDownloadActions, TYPE, LINK_TYPE } from './redux';
import { useSecureNote } from './securityAlter';
// import CardImg from './icons/cardImg.svg';
import CardImg from './icons/cardImage.png';
import { CircleBtn } from './components/Circle';
import HotArea from './components/HotArea';
import PicDownload from './components/PicDownload';
import PageFloat from './components/PageFloat';
import PicBook from './components/PicBook';
import CardDownload from './components/CardDownload';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { getUrlParam } from 'omega-shared/utils';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { DEVICE_TYPE, getDeviceType } from 'landingPage/common/utils/getDeviceInfo';
import { AppointmentModal } from './components/AppointmentModal';
import { Toast } from '@ad/sharpui';
import { getPicCDNSource } from '@/landingPage/common/utils/getUrlParam';
import { sendAdBtnShowLog } from '@/landingPage/common/adTrack/adBridgeLog';
import { EnumPageType } from 'omega-shared/types';

export const AppointmentFlag = 'local_appointment';
export const AppNameOptionList = [
    '立即下载',
    '安装体验',
    '立即安装',
    '免费安装',
    '免费下载',
    '去下载',
    '下载参与',
    '下载领钱',
    '下载领取',
    '下载抢购',
    '下载试玩',
    '下载体验',
    '一键下载',
    '立刻下载',
    '立刻预约',
    '预约',
];
export enum btnType {
    'normalBtn' = 0, // 普通下载按钮
    'cardBtn' = 5, // 应用下载
    'circleIconBtn' = 6,
    'circleBtn' = 7,
    'hotArea' = 8, // 下载热区
    'picDownload' = 9, // 下载图
    'pageFloat' = 10, // 浮层页卡
    'picBook' = 11, // 下载图集
    'cardDownload' = 12, // 大图中间页下载组件样式
}
export interface IHotArea {
    relativeX: number;
    relativeY: number;
    width: number;
    height: number;
}

export enum CardBkgStyleType {
    img = 'img',
    color = 'color',
}

export interface IAppInfo {
    appId: number;
    accountId: number;
    appVersion: string;
    appName: string;
    packageId?: string;
    packageName: string;
    sysPackageName: null;
    packageSize: number;
    url: string;
    appIconUrl: string;
    h5App: boolean;
    platform: string;
    virusScore: number;
    updateTime: number;
    date: number;
    appScore: number;
    iosAppId: null;
    useSdk: boolean;
    downloadCount: number;
}
export interface AppDownloadProps extends Omit<ButtonProps, 'jumpLink'> {
    linkType: LINK_TYPE;
    appInfo?: IAppInfo;
    appType?: 'app' | 'subapp' | 'legacy';
    quickAppLink?: string;
    iOSLink?: string;
    index?: number;
    buttonInfo?: string;
    imgLink?: string;
    appName?: string;
    quickLinkBrand?: number;
    appNameStyle?: {
        fontSize?: number;
        color?: string;
        fontFamily?: string;
    };
    appDesc?: string;
    appDescStyle?: {
        fontSize?: number;
        color?: string;
        fontFamily?: string;
    };
    backgroundFillMode?: boolean;
    deepLink?: string;
    position?: 'base' | 'bottomBlank' | 'rightBottom';
    // 热区设置
    hotAreaInfo?: {
        picUrl: string;
        hotAreas: IHotArea[];
        defaultBgColor?: string;
        defaultBorderColor?: string;
    };
    btnSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    appNameSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    descSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    picFloatInfo?: {
        appDescription?: string;
        color?: string;
        textAlign?: string;
    };
    picFloatStyle?: {
        bgColor?: string;
        appNameColor?: string;
        appDesColor?: string;
    };
    bgImgs?: string[];
    picContent?: {
        title?: string;
        desc?: string;
        titleColor?: string;
        descColor?: string;
    };
    picTags?: {
        tagName?: string;
        x: number;
        y: number;
    };
    cardBackgroundStyle?: {
        type: CardBkgStyleType;
        bkgColor?: string;
        bkgImg?: string;
    };
    clickCallback?: () => void;
    btnText?: string; //覆盖当前计算按钮文案
    radiusStyle?: string; // 圆角类型
    circleBtnSize?: string; // 全局悬浮按钮大小类型
    isAppointmentUser?: boolean; // 是否是预约下载用户
    hideCom?: boolean; // 是否隐藏下载按钮
}

/**
 * @param text 默认展示的文本
 * @param id
 */
export const useProgress = (text: string, id?: string, compType?: string, btnText?: string) => {
    const { downLoadProgress, clickedId } = useRenderState((state) => {
        return {
            downLoadProgress: state.appDownload?.downLoadProgress || {
                status: 0,
                progress: 0,
            },
            clickedId: state.appDownload?.clickedId,
        };
    });
    const { status } = downLoadProgress;

    const content = getText(downLoadProgress);
    let buttonText = text;
    if (compType === 'appdownload') {
        buttonText = btnText
            ? btnText
            : AppNameOptionList.includes(text)
              ? text
              : AppNameOptionList[0];
    }
    if (typeof content === 'string') {
        buttonText = content;
    }
    if (status === 2) {
        // 多个下载按钮，只有被点击的展示进度。图片不传id即可
        if (id !== clickedId) {
            buttonText = '下载中';
        } else {
            const pecentNum = Number(Number(content) * 100).toFixed(0);
            buttonText = `${pecentNum === 'NaN' ? '0' : pecentNum}%`;
        }
    }
    return {
        buttonText,
        status,
        clickedId,
    };
};

const AppDownload: React.FunctionComponent<AppDownloadProps> = (props) => {
    const appState = useSelector((s) => s);

    const {
        appInfo,
        linkType,
        quickAppLink,
        text,
        children,
        iOSLink,
        index,
        imgLink = '',
        appName,
        buttonInfo,
        appDesc,
        appNameStyle,
        appDescStyle,
        bottom,
        dynamicStatus,
        deepLink,
        position,
        cardBackgroundStyle,
        clickCallback,
        btnText,
        btnSetting,
        ...rest
    } = props;
    const { buttonStyle } = props;
    const { id, preview, edit } = useComContext();
    const actions = useAppDownloadActions();
    const [hasAppointment, setHasAppointment] = React.useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = React.useState(false);
    React.useEffect(() => {
        if (!appInfo?.appId && !edit) {
            actions.setModelState({
                init: false,
            });
            actions.init({ appInfo });
        }
    }, [appInfo, edit]);

    useSecureNote(styles, preview, appState, linkType, props?.hideCom);

    useMount(() => {
        actions.setModelState({ hasAppDownload: true });
        if (!preview) {
            actions.init({ appInfo });
            if (
                !props?.hideCom &&
                [EnumPageType.智能中间页, EnumPageType.PLC中间页].includes(
                    JSON.parse(window.pageJsonString ?? '{}').type,
                )
            ) {
                sendAdBtnShowLog();
            }
        }
    });
    const isAppointment = React.useMemo(() => {
        // step1: 白名单  根据是否是预约下载用户
        if (!props.isAppointmentUser) {
            return false;
        }
        // step2:  是否预约下载链路
        if (getUrlParam('appointmentJumpFromGame') !== 'EVENT_APPOINT_JUMP_CLICK') {
            return false;
        }
        // step3: 是否在快手和极速版
        if (getProduct() === 0) {
            return false;
        }
        // step4: 是否是安卓系统
        if (getDeviceType() !== DEVICE_TYPE.ANDROID) {
            return false;
        }
        // step5: 判断是否登录及获取登录信息
        return true;
    }, []);
    const onClick = useCallback(() => {
        clickCallback?.();
        if (isAppointment) {
            if (hasAppointment) {
                Toast.info('已预约，请勿重复点击');
            } else {
                setShowAppointmentModal(true);
            }
        } else {
            actions.openOrDownload({
                type: TYPE.DOWNLOAD,
                appInfo,
                linkType,
                quickAppLink,
                id,
                iOSLink,
                deepLink,
            });
        }
    }, [isAppointment, actions, appInfo, iOSLink, id, linkType, quickAppLink, deepLink]);
    let { buttonText, status } = useProgress(
        index === 5 ? (buttonInfo ?? '') : text,
        id,
        linkType !== 4 ? 'appdownload' : '',
        btnText,
    );
    // 已预约的标识已预约, 已预约到下载时，展示下载状态
    if (isAppointment && ['立刻预约', '预约'].includes(buttonText) && hasAppointment) {
        buttonText = '已预约';
    }
    let shadow: any = null;

    if (status === 2) {
        shadow = <div className={styles.shadow} style={{ width: buttonText }} />;
    }
    const commonRest = index === btnType.cardBtn ? { ...rest } : { ...rest, bottom };

    const getBottomBlankHeight = () => {
        if (index === btnType.cardDownload) {
            return 97;
        }
        if (index === btnType.cardBtn) {
            return 72;
        }
        return 66;
    };

    const isGlobalBtn =
        position === 'rightBottom' &&
        (index === btnType.circleBtn || index === btnType.circleIconBtn);

    const renderBtn = () => {
        return (
            <>
                <Button
                    className={cs({
                        [styles.progress]: status === 2,
                        [styles.dynamic]: dynamicStatus === DynamicType.DYNAMIC,
                        [styles.dynamic2]: dynamicStatus === DynamicType.DYNAMIC_SHINING,
                    })}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonRest}
                    onClick={onClick}
                    text={buttonText}
                >
                    {shadow}
                    {children}
                </Button>
            </>
        );
    };
    const renderCard = () => {
        return (
            <Div
                className={cs(styles.appDownloadInfo)}
                style={
                    cardBackgroundStyle?.type === CardBkgStyleType.color
                        ? {
                              backgroundColor: cardBackgroundStyle?.bkgColor ?? '#fff',
                          }
                        : {
                              backgroundImage: getPicCDNSource(cardBackgroundStyle?.bkgImg ?? ''),
                          }
                }
                // onClick={() => {
                //     onClick();
                // }}
            >
                {imgLink ? (
                    <div
                        className={styles.appIconInfo}
                        style={{
                            backgroundImage: `url(${getPicCDNSource(imgLink)})`,
                        }}
                    />
                ) : (
                    <div
                        style={{
                            backgroundImage: `url(${getPicCDNSource(CardImg)})`,
                            width: '48px',
                            height: '48px',
                            backgroundSize: 'cover',
                        }}
                    />
                )}

                <div className={cs(styles.appInfo)}>
                    <p style={appNameStyle} className={cs(styles.appName)}>
                        {appName}
                    </p>
                    <p style={appDescStyle} className={cs(styles.appDesc)}>
                        {appDesc}
                    </p>
                </div>
                <Button
                    className={cs(
                        {
                            [styles.progress]: status === 2,
                            [styles.dynamic]: !!dynamicStatus,
                        },
                        styles.downloadBtn,
                    )}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonRest}
                    text={buttonText}
                    // 避免button组件打form-submit打点
                    onClick={() => {
                        onClick();
                    }}
                >
                    {shadow}
                    {children}
                </Button>
                {/* {bottom && <BlockPlaceholder pos="BOTTOM" preview={edit} />} */}
            </Div>
        );
    };
    if (props?.hideCom) return null;
    return (
        <>
            {index === btnType.cardBtn ? (
                renderCard()
            ) : isGlobalBtn ? (
                <Div onClick={onClick}>
                    <CircleBtn
                        text={buttonText}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...buttonStyle}
                        btnType={index}
                        dynamic={!!dynamicStatus}
                    />
                </Div>
            ) : index === btnType.hotArea ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <HotArea {...props} onClick={onClick} />
            ) : index === btnType.picDownload ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <PicDownload {...props} onClick={onClick} buttonText={buttonText} status={status}>
                    {shadow}
                </PicDownload>
            ) : index === btnType.pageFloat ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <PageFloat {...props} onClick={onClick} buttonText={buttonText} status={status}>
                    {shadow}
                </PageFloat>
            ) : index === btnType.picBook ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <PicBook {...props} onClick={onClick} buttonText={buttonText} status={status}>
                    {shadow}
                </PicBook>
            ) : index === btnType.cardDownload ? (
                <CardDownload {...props} onClick={onClick} buttonText={buttonText} status={status}>
                    {shadow}
                </CardDownload>
            ) : (
                renderBtn()
            )}
            {position === 'bottomBlank' && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} height={getBottomBlankHeight()} />
                    <SafeAreaPlace />
                </>
            )}
            {showAppointmentModal && (
                <AppointmentModal
                    onClose={() => {
                        setShowAppointmentModal(false);
                    }}
                    onSuccess={() => {
                        setHasAppointment(true);
                    }}
                    appInfo={appInfo}
                />
            )}
        </>
    );
};

export default AppDownload;
