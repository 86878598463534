/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { invoke } from '@yoda/bridge';
import qs from 'qs';
import { callApp } from 'landingPage/common/utils/callApp';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { trackBridgeError, trackBridgeSuccess } from 'landingPage/common/adTrack/monitor';
import { PopupType, WechatActionSceneEnum } from '../WeiXin';
import DirecSubscribetPng from '../image/directSubscribe.png';
import styles from '../style.module.less';
import { wechatDataTrack } from './commonFun';
import { sendFormSubmitAndEffectiveCustomerReport } from '../util';
// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

// encode一下url参数，分享中台会错误识别参数中的=符号
function fixURLComponentEncode(url: string) {
    const [host, urlParams] = url.split('?');
    const encodedParams = qs.stringify(qs.parse(urlParams));
    return `${host}?${encodedParams}`;
}
export interface DirectSubscribeProps {
    info?: string;
    wechatId?: string;
    onClose?: () => void;
    itemId?: string;
    appId?: string;
    packId?: string;
    againstFlowPrefixSwitch?: boolean; // 是否分流
    againstFlowPrefix?: string; // 分流前缀（公众号名称）
    directSubscribeSwitch?: boolean; // 是否直达关注
    directSubscribeLink?: string; // 公众号链接
    wechatStay?: boolean;
    needSendEffectiveAction?: boolean;
    renderType?: string;
}

export const DirectSubscribe: React.FunctionComponent<DirectSubscribeProps> = (props) => {
    const {
        info,
        wechatId = '',
        onClose,
        itemId,
        packId,
        directSubscribeLink = '',
        appId,
        wechatStay,
        needSendEffectiveAction,
        renderType,
    } = props;

    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                lp_page_type: 1,
                wechatActionScene: WechatActionSceneEnum.DIRECT_SUBSCRIBE,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                }),
            });
    }, [info, itemId, packId, wechatId]);

    const callDownloadWx = useCallback(() => {
        callApp({
            link: 'weixin:// ',
            url: 'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
        });
    }, []);
    const shareZone = useCallback(() => {
        onClose?.();
        invoke('social.share', {
            param: {
                // 必选，分享对应的业务
                subBiz: 'KS_MOLI_SITE',
                // 必选，分享业务对象的唯一标识，举例：userId，photoId等
                shareObjectId: uuidv4(),
                // 可选，若为false，则不展示面板直接发起分享，否则展示面板
                showSharePanel: false,
                // 直接发起分享的必要参数
                shareDirectActionUrl: 'kwaishare://shareAny/wechatMoments',
                // 可选，不同的分享渠道特殊配置
                extraChannelOptions: [
                    {
                        shareChannel: 'wechatMoments',
                        shareMethod: 'CARD',
                        shareMode: 'app',
                        configs: {
                            shareUrl: fixURLComponentEncode(directSubscribeLink),
                            // "shareMessage": "看了这么多快手，还是「Ivan44033」最好玩了！ https://v.kuaishou.com/6KhVqL 复制此链接，打开【快手】直接观看！",
                            title: '神奇的测试',
                            subTitle: '神奇的副标题',
                        },
                    },
                ],
            },
        })
            .then(() => {
                trackBridgeSuccess('share');
            })
            .catch((e) => {
                callDownloadWx();
                console.log('分享失败');
                wechatDataTrack({
                    itemId,
                    invokeSuccess: false,
                    sence: WechatActionSceneEnum.DIRECT_SUBSCRIBE,
                    wechatId,
                    appId,
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                });
                trackBridgeError('share', JSON.stringify(e));
            });
    }, [callDownloadWx, directSubscribeLink, itemId, wechatId, appId, onClose]);

    const callWX = useCallback(
        debounce(
            () => {
                // 多转化事件优化目标，不打除表单转化的EVENT_FORM_SUBMIT点
                if (!multiConversion) {
                    // dataTrack({
                    //     eventType: 'EVENT_FORM_SUBMIT',
                    //     wechatItemId: '0',
                    //     lp_page_type: 1,
                    //     WechatActionStatus: WechatActionSceneEnum.DIRECT_SUBSCRIBE,
                    //     diyExtraInfo: JSON.stringify({
                    //         renderType: renderType || 'WEI_XIN_DRAG',
                    //         landing_page_infos: JSON.stringify({
                    //             page_module_type: getTransCompList(),
                    //         }),
                    //     }),
                    // });
                    sendFormSubmitAndEffectiveCustomerReport({
                        wechatActionStatus: WechatActionSceneEnum.DIRECT_SUBSCRIBE,
                        wechatItemId: '0',
                        enableSend: needSendEffectiveAction,
                        renderType: renderType,
                    });
                }

                shareZone();
            },
            300,
            { trailing: false, leading: true },
        ),
        [itemId, onClose],
    );

    return (
        <div className={styles.directSubscribe}>
            <div className={styles.exit} onClick={onClose} />
            <div className={styles.info}>点击这里关注公众号</div>
            <img src={DirecSubscribetPng} alt="直达关注" className={styles.img} />
            <div className={styles.close} onClick={callWX}>
                去微信
            </div>
        </div>
    );
};
