/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import cs from 'classnames';
import { useComContext } from 'omega-render';
import Modal from '../../common/components/Modal';
import { ICustmPrivacyAgreement } from '../XiansuoFormDrag/XiansuoForm';
import AgreementModel from './AgreementModel';
import styles from './style.module.less';

export const AGREE_DEAL = 'agree_deal';
interface AgreementModalProps extends ICustmPrivacyAgreement {
    show: boolean;
    onChange: () => void;
    hasCunstomContent: boolean;
}

export function AgreementModal(props: AgreementModalProps) {
    const { hasCunstomContent, show, onChange, ...rest } = props;
    const { edit } = useComContext();
    return (
        <>
            {show && (
                <Modal
                    style={{
                        zIndex: 999999,
                        // pointerEvents: edit ? 'none' : 'auto',
                    }}
                    parent={
                        edit ? (document.getElementById('preview-app') ?? document.body) : undefined
                    }
                >
                    <div className={styles.modelContainer}>
                        {/* <h2 className={styles.agreementHeader}>个人信息授权与保护声明</h2> */}
                        <AgreementModel hasCunstomContent={hasCunstomContent} {...rest} />
                        {/* eslint-disable-next-line react/button-has-type */}
                        <button
                            className={styles.confirmButton}
                            onClick={() => {
                                if (edit) return;
                                onChange();
                            }}
                        >
                            知道了
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default function Agreement() {
    return (
        /** 警告: #lp-user-agreement为磁力建站FMP统计标志位，请不要移除这个ID */
        <div id="lp-user-agreement">
            <div className={cs(styles.flexCenter, styles.desc)}>磁力建站仅向商家提供技术服务</div>
        </div>
    );
}
