import { getCallbackInfo } from '@/landingPage/common/adTrack/util/sendData';
import { setCountdownNum } from '@/landingPage/common/api';
import { getPageJson } from '@/landingPage/common/utils/fetchCompPropsInPageJson';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { componentsForEach } from 'omega-render';
import qs from 'query-string';
import { getJuXingCPL2WhiteList } from '@/landingPage/common/utils/firefly';
import { getRadar } from '@/landingPage/common/weblog';

let countDownNum = -1;
let startTimeStamp = 0;

/** 设置页面全局倒计时时间
 * @param countDown 倒计时时间
 */
export const setABCountDownNum = (countDown: number) => {
    //保证页面的倒计时只设置一次
    if (countDownNum < 0) {
        countDownNum = countDown;
        startTimeStamp = new Date().getTime();
    }
};

/** 离开页面时发送剩余的倒计时时间给后端保存 */
export const sendABCountDownNum = async () => {
    //如果倒计时时间小于0（即没有设置过倒计时，没有命中ab实验倒计时样式）则直接返回
    if (countDownNum < 0) {
        return;
    }
    //设置剩余倒计时时间，最小为0
    const endTimeStamp = new Date().getTime();
    const newCountDownNum = countDownNum - (endTimeStamp - startTimeStamp);
    countDownNum = newCountDownNum < 0 ? 0 : newCountDownNum;

    const params = {
        lpPageId: getUrlParam('pageId'),
        countdown: countDownNum,
    };
    //发送剩余倒计时时间，不需要等待接口返回
    try {
        setCountdownNum(params);
    } catch (e) {
        console.log('e');
    }
};

/**
 * 根据传入的 formSetting和页面的pageJson做判断
 * 如果count开关为true，则不能展示角标提交数 和 轮播样式
 * 如果apply开关为true，则不能展示轮播样式
 * 根据页面json缓存，如果组件中有倒计时组件，则不能展示倒计时样式
 *
 * @param formSetting - 表单设定对象，决定如何设置 extraFormSetting。
 * @returns {Object} 返回设置好的 extraFormSetting 对象。
 */
export const handleExtraFormSetting = (formSetting: any) => {
    const extraFormSetting = {
        enableShowSubmit: true,
        enableShowUser: true,
        enableCountDown: true,
    };

    const pageJSONCache = getPageJson();

    if (formSetting?.count?.switch) {
        extraFormSetting.enableShowSubmit = false;
    }
    if (formSetting?.apply?.switch) {
        extraFormSetting.enableShowUser = false;
    }
    if (pageJSONCache) {
        componentsForEach(pageJSONCache.components, (com) => {
            if (com.type === 'COUNTDOWN_DRAG') {
                extraFormSetting.enableCountDown = false;
            }
        });
    }

    return { ...extraFormSetting };
};

/**
 * 给表单外跳链接拼接参数
 * 对__CALLBACK__ 宏参数进行替换
 * 如果链接上拼接了jumpSourceForSocial字段，并且jumpSourceForSocial字段值在firefly配置的白名单内
 * 则在链接后拼接photoId，jumpSourceForSocial，callback参数
 *
 * @param {string} jumpUrl - 表单外跳链接
 * @returns {Promise<string|undefined>} 返回修改后的跳转链接，如果jumpUrl未定义则返回undefined
 */
export const handleFormJumpUrl = async (jumpUrl?: string) => {
    if (!jumpUrl) {
        return;
    }
    let newJumpUrl = jumpUrl;
    const callback = getCallbackInfo();

    if (callback) {
        if (newJumpUrl?.includes('__CALLBACK__')) {
            newJumpUrl = newJumpUrl?.replace('__CALLBACK__', callback);
        }
    }

    newJumpUrl = await handleJuxingJumpUrl(newJumpUrl);

    return newJumpUrl;
};

/** 处理聚星链接特殊的参数拼接逻辑 */
export const handleJuxingJumpUrl = async (jumpUrl: string) => {
    let newJumpUrl = jumpUrl;
    let whiteList = ['plc', 'resultPage'];

    try {
        whiteList = await getJuXingCPL2WhiteList();
    } catch (e) {
        console.log('-----error', e);
    }

    const [callback, jumpSourceForSocial, photoId] = getUrlParam([
        'callback',
        'jumpSourceForSocial',
        'photoId',
    ]);

    //是聚星plc链路，需要拼接cpl2相关参数
    if (whiteList.includes(jumpSourceForSocial)) {
        const [host = '', urlParams = ''] = newJumpUrl?.split('?') || [];
        const h5UrlParams = {
            ...qs.parse(urlParams),
            photoId,
            jumpSourceForSocial,
            callback,
        };

        newJumpUrl = `${host}?${qs.stringify({ ...h5UrlParams })}`;

        (await getRadar())?.event({
            name: 'juxing_cpl2_track', // 必填
            event_type: 'juxing_cpl2_track', // 【可选】
            category: 'traceLog', // 【可选】
            extra_info: JSON.stringify({
                jumpSourceForSocial,
                photoId,
                callback,
                url: window.location.href,
            }),
        });
    }

    return newJumpUrl;
};
