/* eslint-disable react/no-array-index-key */
import cs from 'classnames';
import * as React from 'react';
import { useRenderState } from 'omega-render';
import { EnumPageType } from 'omega-shared/types';

import { Div } from '../Blank/Blank';
import { ELinkType, EUrlType, ImageProps } from '../Image/Image';
import ImageSingle from '../ImageSingle';
import defaultMultiImage from './defaultMultiImage.png';
import default_4_3 from './default_4_3.png';
import styles from './style.module.less';

const Image: React.FunctionComponent<ImageProps> = (props) => {
    const { mutiInfo, imgRatio } = props;

    const pageJsonType = useRenderState((state) => state.rolex.opt?.originalPageJson?.type);
    const realMultiInfo = React.useCallback(() => {
        const { length } = mutiInfo;
        const newFillMultiData = mutiInfo.map((imageData) => {
            const newData = { ...imageData };
            newData.imgUrl = imageData.imgUrl
                ? imageData.imgUrl
                : imgRatio === '4:3'
                  ? default_4_3
                  : defaultMultiImage;
            return newData;
        });
        if (length >= 3) return newFillMultiData;
        const ret = [...newFillMultiData];

        // plc中间页产品要求应用详情图片展示是一个动态图片张数，所以图片数量不能固定死，这里需要额外判断下
        pageJsonType !== EnumPageType.PLC中间页 &&
            new Array(3 - length).fill('').forEach(() => {
                ret.push({
                    linkType: ELinkType.NormalLink,
                    imgUrl: imgRatio === '4:3' ? default_4_3 : defaultMultiImage,
                    urlType: EUrlType.MOLI, // moli ;normal
                    targetUrl: '',
                    loadend: () => {},
                });
            });
        return ret;
    }, [mutiInfo, imgRatio]);
    return (
        <Div className={cs(styles.root)}>
            <div className={cs(styles.imageWrapper)}>
                {realMultiInfo() &&
                    realMultiInfo().map((imageInfo, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    imgRatio === '9:16'
                                        ? styles.style_9_16
                                        : imgRatio === '4:3'
                                          ? styles.style_4_3
                                          : styles.style_1_1
                                }
                            >
                                <ImageSingle
                                    key={index}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...imageInfo}
                                    className={cs(styles.singleImage)}
                                />
                            </div>
                        );
                    })}
            </div>
        </Div>
    );
};

export default Image;
