import { checkVersion } from '@/landingPage/common/utils/compareVersion';
import { fetchMarketDirectDeeplink } from 'landingPage/common/api';
import { bridgeGetDeviceInfo } from 'landingPage/common/bridge';
import { getProduct, senderPkgNameList } from 'landingPage/common/utils/getProduct';
import { AppDownloadProps } from './AppDownload';
import { DeeplinkAppType } from '../ButtonDrag/Button';

export const getMarketDirectLink = async (packageName?: string) => {
    let appMarketUri = `market://details?id=${packageName}`;

    try {
        const res = await bridgeGetDeviceInfo();
        const { manufacturer } = res?.data || {};
        const appType = getProduct();
        const checkRes = await checkVersion({ appVersionLimit: '12.1.10' });
        if (manufacturer.toLowerCase().includes('honor') && !checkRes) {
            return appMarketUri;
        }
        const result = await fetchMarketDirectDeeplink({
            id: packageName || '',
            senderPackageName: senderPkgNameList[appType],
            detailStyle: '1',
            manufacture: manufacturer,
        });
        if (result?.length) {
            appMarketUri = result;
        }
    } catch (e) {
        console.log('-----error', e);
    }

    return appMarketUri;
};
/**
 * 转化服务端的appinfo到客户端
 * @param {*} appInfo
 */
export const mapAppInfo = (appInfo: AppDownloadProps['appInfo']) => {
    const {
        appName,
        packageName,
        appVersion,
        versionCode,
        size,
        md5,
        url,
        icon,
        desc,
        appIconUrl,
    } = appInfo ?? ({} as any);
    return {
        appName,
        pkgName: packageName,
        version: appVersion,
        versionCode,
        appSize: size,
        md5,
        url,
        icon: icon || appIconUrl, // 中间页下载没有icon问题
        desc,
    };
};

export const getDeepLinkType = (deeplink: string) => {
    if (deeplink.startsWith('openApp.jdMobile://')) return DeeplinkAppType.JD;
    if (deeplink.startsWith('tbopen://')) return DeeplinkAppType.TAOBAO;
    if (deeplink.startsWith('alipays://')) return DeeplinkAppType.ALIPAY_APPLET;
    return DeeplinkAppType.UNKNOWN_DEEPLINK_APP_TYPE;
};
