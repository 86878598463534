export const iconList = [
    {
        icon: 'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-07/1730984872514.8a0d4ffde99c6a03.png',
        title: '免费咨询',
    },
    {
        icon: 'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-07/1730984910319.1994bb9a81ab2f90.png',
        title: '省心服务',
    },
    {
        icon: 'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-07/1730984932325.fc75daab4e276ca7.png',
        title: '定制方案',
    },
    {
        icon: 'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-07/1730984957610.b882df07c9bafca1.png',
        title: '全天在线',
    },
    {
        icon: 'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-07/1730984984011.b18ddc84ee1ab1ac.png',
        title: '快速响应',
    },
    {
        icon: 'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-07/1730985002785.2d6825623efc1fa2.png',
        title: '精准高效',
    },
];

/** 从6个图标里随机4个出来 */
export const getRandomIconList = (num) => {
    let numbers = [0, 1, 2, 3, 4, 5];
    for (let i = 5; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
    }
    const randomNums = numbers.slice(0, num);

    let results: typeof iconList = [];
    for (let index of randomNums) {
        results.push(iconList[index]);
    }
    return results;
};

/** 随机5-10分钟的倒计时 */
export const generateRandomEndTime = () => {
    // 随机生成一个5到10之间的整数
    const minutes = Math.floor(Math.random() * 6) + 5;
    // 将分钟转换成秒
    const seconds = minutes * 60;
    // 随机生成0到59之间的整数，表示秒中的偏移量
    const offset = Math.floor(Math.random() * 60);
    // 计算出最终的时间戳
    const timestamp = (seconds + offset) * 1000 + new Date().getTime();
    return timestamp;
};
