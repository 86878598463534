import { createModel } from 'rt-model';
import { useRenderActions } from 'omega-render';
import {
    checkShowFormModal,
    fetchShowFormNewStayModal,
    getCommitCount,
    getIndustryText,
    getPageABBlackList,
    getPopupInfo,
} from '@/landingPage/common/api';
import { getUrlParam } from 'omega-shared/utils';

//当实验参数为1时，页面停留时间超过3s展示
//当实验参数为2是，页面停留时长超过5s展示
export const WaitTimer = {
    1: 3,
    2: 5,
};

enum ResolvePromise {
    cancelAnchorForm = 0,
    anchorModal = 1,
    stayModal = 2,
    noOperateModal = 3,
    blackList = 4,
}

enum ResolveModalData {
    getCountdown = 0,
    getHeadImg = 1,
}

// 默认头像列表
export const defaultHeadImgList = [
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00873_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00481_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00471_.png',
];

/** 调用查询展示、发送曝光接口时候上传的弹窗类型 */
export const modalType = {
    leaveModal: 1, // 挽留弹窗
    anchorModal: 2, // 锚点弹窗
};

export interface LeavePageModalStyleProps {
    waitTimer: number;
    showCountDown: number;
    showHeadImg: number;
}

export enum ProfitCardType {
    bigSize = 1,
    smallSize = 2,
}
export interface AnchorModalStyleProps {
    // 展示利益卡片
    profitCardType?: ProfitCardType; // 1=展示利益大卡; 2=展示利益小卡
}

export const defaultLeavePageModalStyle = {
    waitTimer: 0,
    showCountDown: 0,
    showHeadImg: 0,
};

export enum XiansuoFormModalType {
    none = 0,
    noOperateModal = 1,
    leavePageModal = 2,
}

export interface xiansuoFormState {
    isShowAnchorModal?: boolean;
    showModalType: XiansuoFormModalType;
    noOperateModalStyle: number;
    leavePageModalStyle: LeavePageModalStyleProps;
    anchorModalStyle: AnchorModalStyleProps;
    commitFormCount?: number | string;
    headImgList?: string[];
    hasGetFormModalData: boolean; // TODO:删除，直接取值时候判断，如果有值就直接使用，不进行请求
    hasCancelAnchorModal?: boolean; // 当存在popUpInfo时，不展示锚点弹窗 // TODO: getPopUp目前会在表单渲染时候调一次，getPageMeta调一次，需要统一收一下
}

export const defaultState: xiansuoFormState = {
    isShowAnchorModal: undefined, // 是否展示锚点弹窗（接口返回）
    showModalType: XiansuoFormModalType.none,
    noOperateModalStyle: 0,
    leavePageModalStyle: defaultLeavePageModalStyle,
    anchorModalStyle: {
        profitCardType: undefined,
    },
    // headImgList: defaultHeadImgList,
    // commitFormCount: '99+',
    hasGetFormModalData: false, // 是否获取过表单弹窗数据; 页面同时存在挽留+锚点时候，只调用一次保持转化信息一致性
    hasCancelAnchorModal: undefined, // 当存在popUpInfo时，不展示锚点弹窗
};

/** 管理表单弹窗的store
 * store包含内容：
 * showModalType：展示的弹窗类型，分为不展示，展示挽留弹窗，展示留资弹窗
 * noOperateModalStyle：留资弹窗样式类型
 * leavePageModalStyle：挽留弹窗样式类型
 * headImgList：头图list
 * commitFormCount：页面提交数
 */
export const xiansuoFormModal = createModel({
    namespace: 'xiansuoForm',
    defaultState,
    reducers: {},
    effects: {
        /**
         * 初始化弹窗展示类型及内容。
         * 通过请求几种弹窗展示类型及是否命中黑名单，最后设置到底展示哪种表单弹窗
         */
        initModalStatus: () => {
            console.log('initModalStatusinitModalStatus======1');
            return async (dispatch) => {
                // 请求表单挽留弹窗、留资弹窗是否展示，及获取页面是否命中弹窗黑名单
                const pageId = getUrlParam('pageId');
                if (!pageId) {
                    return;
                }
                const requestList = [
                    getPopupInfo({
                        pageId,
                    }),
                    fetchShowFormNewStayModal({
                        lpPageId: pageId, // 新增锚点弹窗请求接口
                        type: modalType.anchorModal,
                    }),
                    fetchShowFormNewStayModal({
                        lpPageId: pageId,
                        type: modalType.leaveModal,
                    }),
                    checkShowFormModal(),
                    getPageABBlackList({
                        lpPageId: pageId,
                        funcKey: ['magicSitePageFormPopupModel', 'magicSiteFormStayModal'],
                    }),
                ];

                let showFormModalStyle = {
                    ...defaultState,
                };

                let enableFormModal = {
                    enableLeavePageShowModal: false,
                    enableNoOperateShowModal: false,
                    enableAnchorModal: false,
                };
                Promise.allSettled(requestList).then((result) => {
                    result.forEach((item, index) => {
                        if (item.status === 'fulfilled') {
                            switch (index) {
                                case ResolvePromise.cancelAnchorForm:
                                    // 存在popup或anchorClear则不展示锚点定位/弹窗
                                    const data = Boolean(item.value);
                                    if (data || window.location.search?.includes('anchorClear=1')) {
                                        showFormModalStyle = {
                                            ...showFormModalStyle,
                                            hasCancelAnchorModal: true,
                                        };
                                    } else {
                                        showFormModalStyle = {
                                            ...showFormModalStyle,
                                            hasCancelAnchorModal: false,
                                        };
                                    }
                                    break;
                                //页面锚点弹窗展示配置，包含是否展示及展示哪种利益卡
                                case ResolvePromise.anchorModal:
                                    // 1. 接口返回
                                    const anchorAPIRes = item.value?.showAnchoredModal; // 返回1.2代表展示，返回0代表不展示
                                    // 2. 整合
                                    enableFormModal.enableAnchorModal = Boolean(anchorAPIRes);
                                    showFormModalStyle = {
                                        ...showFormModalStyle,
                                        // 这里只有锚点和留资存在互斥，与挽留无互斥关系
                                        isShowAnchorModal: enableFormModal.enableAnchorModal,
                                        anchorModalStyle: {
                                            ...showFormModalStyle.anchorModalStyle,
                                            profitCardType: anchorAPIRes,
                                        },
                                    };
                                    break;
                                //页面挽留弹窗展示配置，包含是否展示及展示哪种利益点
                                case ResolvePromise.stayModal:
                                    const showNewModal = Boolean(item.value?.showFormStayModal);
                                    enableFormModal.enableLeavePageShowModal = showNewModal;
                                    showFormModalStyle = {
                                        ...showFormModalStyle,
                                        showModalType: showNewModal
                                            ? XiansuoFormModalType.leavePageModal
                                            : XiansuoFormModalType.none,
                                        leavePageModalStyle: {
                                            waitTimer:
                                                WaitTimer[item.value?.showFormStayModal] || 0,
                                            showCountDown: item.value?.showFormCountDown || 0,
                                            showHeadImg: item.value?.showFormHeadImg || 0,
                                        },
                                    };
                                    break;
                                case ResolvePromise.noOperateModal:
                                    //留资弹窗展示配置，包含是否展示及展示样式
                                    //其中，优先级为：挽留弹窗 > 留资弹窗，如果已经展示挽留弹窗，则留资弹窗样式不生效
                                    //不展示锚点&不展示挽留，展示留资

                                    enableFormModal.enableNoOperateShowModal =
                                        item.value?.showPopup || false;
                                    showFormModalStyle = {
                                        ...showFormModalStyle,
                                        showModalType:
                                            showFormModalStyle.showModalType !==
                                                XiansuoFormModalType.leavePageModal &&
                                            !showFormModalStyle.isShowAnchorModal &&
                                            item.value?.showPopup
                                                ? XiansuoFormModalType.noOperateModal
                                                : showFormModalStyle.showModalType,
                                        noOperateModalStyle: item.value?.popupStyleType || 0,
                                    };
                                    break;
                                case ResolvePromise.blackList:
                                    //是否命中弹窗黑名单
                                    const abBlackList = {
                                        magicSitePageFormPopupModel: true,
                                        magicSiteFormStayModal: true,
                                    };
                                    item.value?.data?.whiteList?.forEach((item) => {
                                        abBlackList[item.funcKey] = item.white;
                                    });

                                    const { enableNoOperateShowModal } = enableFormModal;
                                    const showModalType = showFormModalStyle.showModalType;

                                    //命中挽留弹窗黑名单，且弹窗展示为挽留弹窗时
                                    //判断是否命中留资弹窗黑名单，是则不展示弹窗，否则，如果留资弹窗命中实验组，则展示留资弹窗
                                    if (
                                        showModalType === XiansuoFormModalType.leavePageModal &&
                                        !abBlackList.magicSiteFormStayModal
                                    ) {
                                        showFormModalStyle.showModalType =
                                            abBlackList.magicSitePageFormPopupModel &&
                                            enableNoOperateShowModal
                                                ? XiansuoFormModalType.noOperateModal
                                                : XiansuoFormModalType.none;
                                        //如果命中留资弹窗黑名单，且页面弹窗展示留资弹窗
                                        //则不展示弹窗
                                    } else if (
                                        showModalType === XiansuoFormModalType.noOperateModal &&
                                        !abBlackList.magicSitePageFormPopupModel
                                    ) {
                                        showFormModalStyle.showModalType =
                                            XiansuoFormModalType.none;
                                    }

                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                    dispatch(xiansuoFormModal.actions.setModelState({ ...showFormModalStyle }));
                });
            };
        },
        /** 获取弹窗所需的头图、提交数信息 */
        fetchFormModalData: () => {
            return async (dispatch, getState) => {
                const state: xiansuoFormState = getState().xiansuoForm;
                const { showModalType, leavePageModalStyle, isShowAnchorModal } = state;
                let requestList: Promise<any>[] = [];
                const lpPageId = getUrlParam('pageId');
                if (state?.hasGetFormModalData) {
                    return;
                }
                if (
                    isShowAnchorModal ||
                    (showModalType === XiansuoFormModalType.leavePageModal &&
                        Boolean(leavePageModalStyle.showHeadImg))
                ) {
                    requestList = [
                        getCommitCount({ lpPageId, type: 1 }),
                        getIndustryText({ lpPageId, type: 1 }),
                    ];
                }

                Promise.allSettled(requestList).then((result) => {
                    let commitInfo = {} as any;
                    result.forEach((item, index) => {
                        if (item.status === 'fulfilled') {
                            switch (index) {
                                case ResolveModalData.getCountdown:
                                    commitInfo.commitFormCount = item.value?.showNumber
                                        ? item.value?.showNumber
                                        : '99+';
                                    break;
                                case ResolveModalData.getHeadImg:
                                    commitInfo.headImgList = item.value?.head?.length
                                        ? item.value?.head
                                        : defaultHeadImgList;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });

                    dispatch(
                        xiansuoFormModal.actions.setModelState({
                            ...state,
                            hasGetFormModalData: true,
                            ...commitInfo,
                        }),
                    );
                });
            };
        },
        //全局设置弹窗类型，最高优先级
        handleFormModalStatus(showModalType: XiansuoFormModalType) {
            return async (dispatch) => {
                dispatch(xiansuoFormModal.actions.setModelState({ showModalType }));
            };
        },
        handleCloseAnchorModal(hasCancelAnchorModal: boolean) {
            // 如果用户点击了返回，需要关闭、清除锚点弹窗以及锚点逻辑
            // 提交成功后不展示任何类型弹窗
            // 退出页面前不展示锚点弹窗
            return async (dispatch) => {
                dispatch(xiansuoFormModal.actions.setModelState({ hasCancelAnchorModal }));
            };
        },
    },
});

/**
 * reducer映射。
 */
export const reducerMap = {
    xiansuoForm: xiansuoFormModal?.reducer,
};

/**
 * 获取表单弹窗样式类型的actions
 */
export const useXiansuoFormContainerActions = () => useRenderActions(xiansuoFormModal.actions);
