/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import { IAllFormJson, IClueForm, IGlobalData, IResult } from '@ad/xiansuoSDK';
import { Toast } from '@ad/sharpui';
import { DIALOG_STYLE, useComContext, useRenderState, WeChatType } from 'omega-render';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
// import { createClueForm, ClueFormRender } from '@dc/form-react';
import '@ad/xiansuoSDK/lib/index.esm.css';
import { getPageId } from 'landingPage/common/utils/getPageId';
// import '@dc/form-react/lib/index.esm.css';
import {
    isBeta,
    isLocalhost,
    isProduction,
    isSSR,
    isSSRHydrate,
    isStaging,
} from 'landingPage/common/utils/env';
import { useEditHeight } from 'landingPage/common/hook/useEditHeight';
import { getXianSuoDomain } from 'landingPage/common/utils/getXianSuoDomain';

import { trackClick, trackFormAutoPhoneStatus } from 'landingPage/common/adTrack/util/zt';
import { trackEvent, trackStoreSubmit } from 'landingPage/common/adTrack/monitor';
import { getStoreState } from '../Store/utils/locStore';
import { Div } from '../Blank/Blank';
import { IFormInfoView } from '../../services/models';
import { BaseForm, ExtraForm } from '../xiansuoFormPay/commonFun';
import { WeiXinOpt } from '../WeiXinDrag/WeiXin';
import { FormSetting, transformFormSetting } from './transformFormSetting';
import styles from './style.module.less';
// import { TitleType } from 'landingPage/page/pageEdit/componentEditor/XiansuoFormDragEditor/TitleOptional/index';
import FormUserAgreement from './components/FormUserAgreement';
import { DialogTypeEnum } from './XiaoSuoModal';
import { AfterSuccessDisplayType } from 'omega-shared/types';

import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { AggregatePage } from './components/AggregatePage';
import { useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { AggregateResultPromise } from './components/AggregatePage/utils';
import { signApi } from 'landingPage/common/utils/encrypt';
import { getCallbackInfo, getComponentType } from 'landingPage/common/adTrack/util/sendData';
import { ChatStyleTypeEnum } from '../XiansuoFormMulti/XiansuoForm';
import { getFuWuHaoInfo } from 'landingPage/common/utils/getFuWuHaoInfo';
import { getEnterPageTimeStamp } from '@/landingPage/common/weblog';
import { getPageJson } from 'landingPage/common/utils/fetchCompPropsInPageJson';
import { getFormComponentType } from 'omega-shared/utils';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import {
    getAggregationInfoList,
    CampaignType,
    isAggergateCreativeX,
} from '@/landingPage/common/utils/getAggregatePageInfoList';
import { invoke } from '@yoda/bridge';
import { aggregateInfoReport } from '@/landingPage/common/adTrack/radarReport';
import { handleExtraFormSetting, setABCountDownNum } from './utils';
import { handleRequestHeaders } from '@/landingPage/common/utils/handleTraceId';
import { useXiansuoFormContainerActions, XiansuoFormModalType } from './store';
import { AnchorFormModal } from './anchorFormModal';

/* 当落地页有门店组件的时候，没有选择的情况，不能提交 */
export const assertRequiredStore = () => {
    const storeSelected = getStoreState<'storeSelected'>('storeSelected');
    const storeComponentType = getStoreState<'componentType'>('componentType');
    if (typeof storeComponentType !== 'undefined' && !storeSelected?.id) {
        return false;
    }
    return true;
};

export enum TitleType {
    normal = 'normal',
    optimal = 'optimal',
}

export enum Position {
    base = 'base',
    bottomBlank = 'bottomBlank',
}

export const doTrackStoreSubmit = () => {
    /* 定位状态 */
    const locStatus = getStoreState<'locStatus'>('locStatus');
    const packageId = getStoreState<'packageId'>('packageId');
    if (packageId) {
        const stayDuration = new Date().getTime() - performance.timing.connectStart;
        trackStoreSubmit(stayDuration, locStatus);
    }
};

/* 传递一个获取数据的函数，在提交表单的时候会调用，并附加在提交的表单数据中 */
export const getOtherSubmitData = () => {
    const storeComponentType = getStoreState<'componentType'>('componentType');
    const storeSelected = getStoreState<'storeSelected'>('storeSelected');
    const orientedCity = getUrlParam('cityName') || '';
    if (typeof storeComponentType !== 'undefined') {
        return {
            extMap: {
                orientedCity,
            },
            storeDetail: {
                // packageId,
                storeId: storeSelected?.id,
            },
        };
    }

    return {
        extMap: {
            orientedCity,
        },
    };
};

export enum StyleType {
    SINGLE = 'single',
    MULTI = 'multi',
    CHAT = 'chat',
    PAY = 'pay',
    BOTTOM = 'bottom',
    AUTOSINGLE = 'autoSingle',
}

export interface AccountSelectProps {
    // 微信类型
    packType?: 1 | 2;
    // 1 是单个微信 2是号码包
    wechatType?: WeChatType | undefined;
    onChange?: (
        param: Pick<
            AccountSelectProps,
            | 'wechatType'
            | 'opt'
            | 'funcId'
            | 'dialogStyle'
            | 'dialogStyleType'
            | 'applets'
            | 'appletSwitchState'
            | 'info'
        >,
    ) => void;
    dialogStyle?: DialogTypeEnum | undefined;
    opt?: WeiXinOpt;
    funcId?: string;
    type?: 1;
    // 分布表单
    isFenbuBiaodan?: boolean;
    error?: string;
    // 是否需要弹框样式设置
    needModalStyle?: boolean;
    // 点击弹框样式设置回调
    onClickModalStyle?: () => void;
    tooltip?: string;
    dialogStyleType?: DIALOG_STYLE;
    applets?: { appletsId: string; callback: string }; // 微信小程序的信息
    appletSwitchState?: {
        checked: boolean;
        disabled: boolean;
    }; // 小程序开关的状态
    info?: string;
}

export interface Message {
    id: string;
    value?: string;
}

export interface MessageExpand {
    label?: string;
    options?: { id: string; name: string; message?: string; exitQuestion?: boolean }[];
}

export enum ASSISTANT_SOURCE {
    SELF = 1,
    SHARE = 2,
}

export interface ICustmPrivacyAgreement {
    agreementName: string;
    agreementUrl: string;
    reuse: boolean;
    fileKey: string;
    fileName: string;
}
export interface XiansuoFormProps {
    newUserAgreement: boolean;
    displayType: StyleType;
    showLabel: 'show' | 'hidden';
    theme?: string;
    funcId?: string;
    inputStyleType?: InputStyleType;
    inputStyle?: React.CSSProperties;
    optionStyle?: React.CSSProperties;
    optionActiveStyle?: React.CSSProperties;
    mainTitleStyle?: React.CSSProperties;
    mainTitleRich?: any;
    titleStyle?: React.CSSProperties;
    formStyle?: React.CSSProperties;
    submitStyle?: React.CSSProperties;
    submitWrapperStyle?: React.CSSProperties;
    formItemWrapperStyle?: React.CSSProperties;
    placeholderColor?: string;
    formOpt: IFormInfoView;
    isPreview?: boolean;
    children?: React.ReactNode;
    // 表单建站高级设置
    formSetting?: FormSetting;
    buttonSizeType?: ButtonSizeType;
    titleType?: TitleType;
    btnType?: TitleType;
    /** 是否是新的表单用户协议，https://docs.corp.kuaishou.com/d/home/fcADnoYdRNOUC03QQsGwSg21x# */
    newAgreement: boolean;
    // 支付表单配置
    activityId?: string;
    activityName?: string;
    formId?: string;
    itemId?: string;
    itemName?: string;
    // 对话表单
    startButtonInfo?: string;
    // 欢迎语
    welcome?: Message[];
    // 结束语
    end?: Message[];
    // 客服头像
    serviceAvatarUrl?: string;
    // 客服昵称
    serviceNickname?: string;
    // 辅助消息列表
    messageExpand?: MessageExpand[];
    // 微信相关
    wechatFuncId?: AccountSelectProps['funcId'];
    wechatType?: AccountSelectProps['wechatType'];
    wechatOpt?: AccountSelectProps['opt'];
    wechatInfo?: string;
    applets?: AccountSelectProps['applets'];
    appletSwitchState?: AccountSelectProps['appletSwitchState'];
    dialogStyle?: AccountSelectProps['dialogStyle'];
    dialogStyleType?: AccountSelectProps['dialogStyleType'];
    // 对话表单
    customStyle?: 0 | 1;
    // 是否展示隐私协议弹窗
    showCustomPrivacyAgreement?: boolean;
    // 分布表单单行选项个数
    optionLineNum?: number;
    position?: Position;
    /** 表单提交成功之后是否保留内容 */
    needCache?: boolean;
    privacyTextColor?: string;
    /**是否是新样式 */
    isNewStyle?: boolean;
    clueAccountId?: number;
    /**预约下载表单标识 */
    isAppointment?: boolean;
    chatStyleType?: ChatStyleTypeEnum; //嵌入式样式能力
    /** 是否有按钮动效 */
    buttonMotion?: boolean;
    /** 按钮形状 */
    optionShape?: 'circle' | 'square';
    /** 表单版本 */
    formVersion?: string;
    wechatIsShare?: ASSISTANT_SOURCE;
}

export enum ButtonSizeType {
    DEFAULT = 'default',
    CUSTOM = 'custom',
}

export enum InputStyleType {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

const pageId = getPageId();
// const
export const formClass = 'com-form';
const fixedPositionHeight = 303;
const XiansuoForm: React.FunctionComponent<XiansuoFormProps> = (props) => {
    const {
        formOpt,
        showLabel,
        // theme,
        children,
        funcId,
        placeholderColor,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        titleStyle,
        mainTitleStyle,
        mainTitleRich,
        formStyle,
        submitStyle,
        formSetting,
        submitWrapperStyle,
        formItemWrapperStyle,
        showCustomPrivacyAgreement,
        position,
        needCache,
        privacyTextColor,
        clueAccountId,
        isAppointment,
        formVersion,
    } = props;
    const { color: theme } = useTheme({
        path: 'theme',
    });

    const actions = useXiansuoFormContainerActions();

    const aggregateResultPromise = useRef(new AggregateResultPromise());
    // const pageName = document.querySelector('title')?.innerHTML;
    const { edit, preview } = useComContext();
    const [show, setShow] = React.useState(false);
    const [showAppointment, setShowAppointment] = React.useState(false);
    const [submitted, setSubmitted] = useState<IResult[] | undefined>();
    const { config, modalSetting, richText } = useCommonFormConfig({
        formOpt,
        formSetting,
        edit,
        preview,
        showLabel,
        theme,
        placeholderColor,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        titleStyle,
        mainTitleStyle,
        mainTitleRich,
        clueAccountId,
        onSuccess: async (value) => {
            console.log('[onSuccess]', value);
            setSubmitted(cloneDeep(value?.results));
            //原·聚合落地页需求部分，现在不需要了就先注释掉了
            // const aggregateId = getUrlParam('aggregatePageId');
            // let result = false;
            // try {
            //     result = await canShowAggregatePage(formOpt);
            // } catch (e) {
            //     console.error(e);
            // }
            // console.log('[canShowAggregatePage result]', result);
            // 有aggregateId代表来自聚合落地页，因此不再请求聚合落地页
            // if (!aggregateId && result) {
            //     setShowPopup(true);
            //     const result = await aggregateResultPromise.current.resultPromise;
            //     if (!result) {
            //         if (isAppointment) {
            //             setShowAppointment(true);
            //         } else {
            //             setShow(true);
            //         }
            //     }
            // } else {
            if (isAppointment) {
                setShowAppointment(true);
            } else {
                setShow(true);
            }
            // }
        },
        isSSR,
        customSuccessPage: true,
        showCustomPrivacyAgreement,
        needCache,
        show,
        privacyTextColor,
        formVersion,
    });
    const { editHeight } = useEditHeight(true);
    const [reRender, setReRender] = React.useState<any>(false);
    React.useEffect(() => {
        // 强制reRender，解决hydrate表单初次渲染不触发styled-components render的问题
        if (isSSRHydrate) {
            setReRender(true);
            window.setTimeout(() => {
                setReRender(false);
            });
        }
        //初始化表单弹窗类型&样式
        actions.initModalStatus();
    }, []);

    const [showPopup, setShowPopup] = React.useState(false);

    return (
        <Div className={formClass} data-form-id={funcId} style={{ height: editHeight }}>
            {/* <button
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                click me
            </button> */}
            <BaseForm
                placeholderColor={placeholderColor}
                formClass={formClass}
                reRender={reRender}
                config={config}
                richText={richText}
                formStyle={formStyle}
            />
            <ExtraForm
                // eslint-disable-next-line react/no-children-prop
                children={children}
                edit={edit}
                preview={preview}
                show={show}
                showAppointment={showAppointment}
                modalSetting={modalSetting}
                isAppointmentUser={isAppointment}
                onClose={() => {
                    setShow(false);
                }}
                onCloseAppointment={() => {
                    setShowAppointment(false);
                }}
            />
            <div
                className="xiansuoModalBtn"
                style={{ display: 'none' }}
                onClick={() => {
                    if (isAppointment) {
                        setShowAppointment(true);
                        setShow(false);
                    } else {
                        setShow(true);
                        setShowAppointment(false);
                    }
                }}
            />
            <div
                className="xiansuoModalClose"
                style={{ display: 'none' }}
                onClick={() => {
                    setShow(false);
                    setShowAppointment(false);
                }}
            />
            {showPopup && (
                <AggregatePage
                    onResult={(val) => {
                        console.log('onResult', val);
                        aggregateResultPromise.current.resolver(val);
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    preValue={submitted!}
                    preFormOpt={formOpt}
                    onClose={() => {
                        setShowPopup(false);
                    }}
                />
            )}
            {position === Position.bottomBlank && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} height={fixedPositionHeight} />
                    <SafeAreaPlace
                        style={{
                            backgroundColor: formStyle?.backgroundColor || '#fff',
                        }}
                    />
                </>
            )}
            <AnchorFormModal setShow={setShow} setShowAppointment={setShowAppointment} />
        </Div>
    );
};

export default XiansuoForm;

interface AggregationInfoForSites {
    siteId: string;
    callbackInfo: string;
    simpleCreateFlag: boolean;
    thirdSiteSourceUrl?: string;
}

interface AggregateInfoProps {
    //聚合ABCD siteId和callback的信息
    aggregationInfoForSites: AggregationInfoForSites[];

    aggSiteId: string; // 聚合展示的落地页X
    realSiteId: string; //真实曝光的落地页A
    //聚合展示的X落地页 账户id
    aggSiteAccountId: string;
    //被聚合的A落地页 账户id
    realSiteAccountId: string;
    // 聚合竞价x的callback
    aggCallback?: string;
    // 聚合竞价A的callback
    realCallback?: string;
}

interface BaseAggregateInfoProps {
    //聚合展示的X落地页 账户id
    aggSiteAccountId: string;
    //被聚合的A落地页 账户id
    realSiteAccountId: string;
}

export interface CommonFormConfigProps {
    formOpt?: IFormInfoView;
    formSetting?: any;
    edit?: boolean;
    preview?: boolean;
    showLabel?: 'show' | 'hidden';
    theme?: string;
    formStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    optionStyle?: React.CSSProperties;
    optionActiveStyle?: React.CSSProperties;
    submitWrapperStyle?: React.CSSProperties;
    formItemWrapperStyle?: React.CSSProperties;
    submitStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    mainTitleStyle?: React.CSSProperties;
    mainTitleRich?: any;
    placeholderColor?: string;
    onSuccess?: (value: IGlobalData | undefined) => void;
    isSSR?: boolean;
    customSuccessPage?: boolean;
    showCustomPrivacyAgreement?: boolean;
    needCache?: boolean;
    show?: boolean;
    privacyTextColor?: string;
    clueAccountId?: number;
    formVersion?: string;
    onSubmitClick?: () => void;
    onClick?: () => void;
    onError?: (error: any) => void;
    handleSubmitSuccess?: () => void;
    tierFormZIndex?: number;
}
export const useCommonFormConfig = (props: CommonFormConfigProps) => {
    const [showUserAgreement, setShowUserAgerement] = useState(false);

    const formCommitInfoAutoFill = useRenderState(
        (state) => state.rolex.opt?.originalPageJson?.pageMeta?.formCommitInfoAutoFill,
    );

    const { color: activeBackground } = useTheme({
        path: 'optionActiveStyle.backgroundColor',
        options: {
            type: 'activeFill',
        },
    });
    const { color: activeBorderColor } = useTheme({
        path: 'optionActiveStyle.borderColor',
        options: {
            type: 'border',
        },
    });
    const { color: activeColor } = useTheme({
        path: 'optionActiveStyle.color',
    });
    const {
        formOpt,
        formSetting,
        edit,
        preview,
        theme,
        showLabel,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        titleStyle,
        mainTitleStyle,
        mainTitleRich,
        placeholderColor,
        onSuccess,
        isSSR,
        customSuccessPage,
        showCustomPrivacyAgreement,
        needCache,
        show,
        privacyTextColor,
        clueAccountId,
        formVersion,
        onSubmitClick,
        onClick,
        onError,
        handleSubmitSuccess,
        tierFormZIndex,
    } = props;
    const [aggregationInfo, setAggregationInfo] = React.useState<
        AggregateInfoProps | BaseAggregateInfoProps
    >({} as AggregateInfoProps);
    const [
        aggregateBiddingPageId,
        callbackInfo,
        ksXAccountId,
        ksSiteAccountId,
        ksCampaignType,
        isAggregatePage,
    ] = getUrlParam([
        'aggregateBiddingPageId',
        'callback',
        'ksXAccountId',
        'ksSiteAccountId',
        'ksCampaignType',
        'isAggregatePage',
    ]);

    React.useEffect(() => {
        /**
         * 聚合竞价/极简创编 信息拼接
         * 极简创编  建站后端、crm依赖前端透传的账户信息判断是否需要转换clueSource中的账户
         * 聚合竞价  crm依赖前端透传的ABCD聚合信息进行线索裂变
         */
        const fetch = async () => {
            try {
                //是否是聚合明测X
                const isAggregateCreativeX = isAggergateCreativeX();

                //聚合明测A：获取BCD 落地页信息
                //聚合明测X：获取ABCD 落地页信息
                const urlRes = await getAggregationInfoList();
                /**
                 * 设置聚合竞价/极简创编的aggSiteAccountId（X的账户id）
                 * 极简创编时，使用ksSiteAccountId（是A的accountId），当聚合竞价x极简创编时，
                 * 可能出现异常情况，即上游会携带ksXAccountId（X的accountId）,如果是这种情况，前端透传ksXAccountId并上报数据异常监控
                 * 聚合竞价x标准创编时，使用ksXAccountId（X的accountId，引擎下发）
                 */
                const aggSiteAccountId = () => {
                    //如果是聚合明测X，则返回ksSiteAccountId（X的accountId）
                    if (isAggregateCreativeX) {
                        return ksSiteAccountId;
                    }
                    //聚合竞价明测A x 极简创编时，上游正常不携带ksXAccountId，直接复制A的accountId
                    if (ksCampaignType === CampaignType.Light) {
                        if (ksXAccountId) {
                            //异常情况，前端不做处理，加数据上报&报警
                            aggregateInfoReport({
                                isLightCreative: ksCampaignType === CampaignType.Light,
                                ksXAccountId,
                            });
                            return ksXAccountId;
                        } else {
                            //正常情况
                            return ksSiteAccountId;
                        }
                    }
                    //聚合明测A x 标准创编，上游正常携带ksXAccountId，进行上报
                    return ksXAccountId;
                };
                //聚合账户信息
                const baseAggInfo = {
                    //聚合展示的X落地页 账户id
                    aggSiteAccountId: aggSiteAccountId(),
                    //被聚合的A落地页 账户id
                    //如果是聚合明测X，则取二次请求ABCD信息的第一条作为A的账户id
                    realSiteAccountId: isAggregateCreativeX
                        ? urlRes?.[0]?.ksSiteAccountId
                        : ksSiteAccountId,
                };
                /**
                 * 非聚合竞价时
                 * 标准创编返回{aggSiteAccountId: undefined,realSiteAccountId: A的accountId}
                 * 极简创编返回{aggSiteAccountId: A的accountId，realSiteAccountId: A的accountId}
                 */
                if (!isAggregatePage?.length) {
                    setAggregationInfo(baseAggInfo);
                    aggregateInfoReport({
                        handleAggInfoSuccess: 'true',
                        isAggAd: 'false',
                        aggInfo: JSON.stringify(baseAggInfo),
                    });

                    return;
                }

                //聚合ABCD落地页的siteId和callback信息拼接
                //拼接BCD的
                const aggregationInfoForSites: AggregationInfoForSites[] = urlRes?.map((item) => {
                    return {
                        siteId: item.siteId,
                        callbackInfo: item.callbackInfo,
                        simpleCreateFlag: item.ksCampaignType === CampaignType.Light,
                        //只有三方落地页拼接该字段，建站落地页不携带这个字段
                        thirdSiteSourceUrl: !item.siteId ? item?.originUrl : undefined,
                    };
                }) as AggregationInfoForSites[];
                //聚合ABCD落地页的siteId和callback信息拼接
                //如果是聚合明测A，则bridge返回的只是BCD的信息，需要额外拼接A的
                //如果是聚合明测X，则bridge返回的是ABCD的信息，不需要额外拼接A的
                if (!isAggregateCreativeX) {
                    aggregationInfoForSites?.push({
                        siteId: aggregateBiddingPageId,
                        callbackInfo,
                        simpleCreateFlag: ksCampaignType === CampaignType.Light,
                    });
                }

                const aggInfo = {
                    aggSiteId: pageId || '', // 聚合展示的落地页X
                    realSiteId: isAggregateCreativeX
                        ? urlRes?.[0]?.siteId
                        : aggregateBiddingPageId || '', //真实曝光的落地页A
                    aggCallback: callbackInfo, // 聚合竞价x的callback
                    realCallback: urlRes?.[0]?.callbackInfo || '', // 聚合竞价A的callback
                    //聚合ABCD siteId和callback的信息
                    aggregationInfoForSites,
                    ...baseAggInfo,
                };

                setAggregationInfo(aggInfo);

                aggregateInfoReport({
                    handleAggInfoSuccess: 'true',
                    isAggAd: 'true',
                    aggInfo: JSON.stringify(aggInfo),
                });
            } catch (e) {
                aggregateInfoReport({
                    handleAggInfoError: 'true',
                    error: String(e),
                });
            }
        };
        fetch();
    }, []);

    const actions = useXiansuoFormContainerActions();
    return React.useMemo(() => {
        // const themes = generate(theme as string);
        const formOptKeys = Object.keys(formOpt ?? {});
        if (!formOpt || !formOptKeys?.length) {
            return {
                config: null,
            };
        }
        // if (isSSR) {
        //     return {
        //         ClueFormHoc: null,
        //     };
        // }
        if (!isProduction) {
            // console.log('formOpt:', formOpt);
        }

        let realFormOpt;
        let modalSetting;
        let cunstomAgreementSetting;
        if (formSetting) {
            const res = transformFormSetting(formOpt, formSetting);
            realFormOpt = res.realFormOpt;
            modalSetting = res.modalSetting;
            cunstomAgreementSetting = formSetting?.privacy?.itemConfig;
        } else {
            realFormOpt = formOpt;
        }
        const enableTierForm =
            !!formOpt.tierFormComponents?.length &&
            (formSetting?.submitTurn?.switch
                ? modalSetting?.afterSuccessDisplayType === AfterSuccessDisplayType.TIER_FORM
                : true);

        const newFormStyle = {
            background: 'transparent',
            backgroundImage: '',
        };

        const config: IClueForm = {
            domConfs: {
                theme,
                isInEdit: Boolean(edit),
                clueAccountId,
                isPreview: !!(edit ?? preview),
                previewTierFormModal:
                    modalSetting?.afterSuccessDisplayType === AfterSuccessDisplayType.TIER_FORM &&
                    show,
                disableTierForm: !enableTierForm,
                tierFormModalPreviewParent: edit && (document.getElementById('preview-app') as any),
                hideProtocol: true,
                /** 自定义用户协议，插入到提交按钮之前 */
                // customProtocolBefore: FormUserAgreement,
                customProtocolBefore: () => {
                    return (
                        <FormUserAgreement
                            showCustom={showCustomPrivacyAgreement}
                            hasCunstomContent={formSetting?.privacy?.switch}
                            {...cunstomAgreementSetting}
                            color={privacyTextColor || '#666'}
                            buttonColor={theme}
                            showAgreementByOut={showUserAgreement}
                            onClose={() => {
                                setShowUserAgerement(false);
                            }}
                        />
                    );
                },
                /** 自定义提交按钮文案 */
                customSubmitText: (origin: string) => `同意并${origin}`,
                showLabel: showLabel === 'show',
                formTheme: {
                    formStyle: newFormStyle,
                    inputStyle: {
                        borderRadius: 4,
                        opacity: 1,
                        ...inputStyle,
                    },
                    optionStyle,
                    optionActiveStyle: {
                        ...optionActiveStyle,
                        backgroundColor: activeBackground,
                        borderColor: activeBorderColor,
                        color: activeColor,
                    },
                    submitWrapperStyle,
                    formItemWrapperStyle,
                    submitStyle,
                    titleStyle: titleStyle ?? {},
                    mainTitleStyle: mainTitleStyle ?? {},
                    placeholderColor,
                },
                customSuccessPage,
                tierFormZIndex, // 当前是锚点表单，则控制分层表单在锚点之上
            },
            data: {
                type: 'sync',
                pageId,
                getOtherSubmitData,
                // initData({ })
                pubBizId: 1003,
                formJson: realFormOpt as IAllFormJson,
                callback: getCallbackInfo(),
                renderType: 'XIANSUO_FORM_DRAG',
                preFillData: getUrlParam('preName')
                    ? {
                          phone: getUrlParam('prePhone'),
                          name: getUrlParam('preName'),
                      }
                    : undefined,
                fuWuHaoInfo: getFuWuHaoInfo(),
                enterPageTime: getEnterPageTimeStamp(),
                formType:
                    getPageJson()?.pageMeta?.formComponentType ||
                    getFormComponentType(getPageJson())?.formComponentType,
                formVersion: realFormOpt?.version || formVersion,
                formCommitInfoAutoFill,
                aggregationInfo,
                extraFormSettingInfo: handleExtraFormSetting(formSetting),
            },
            callBackHooks: {
                onAutoFillStatusChange(status) {
                    trackFormAutoPhoneStatus(status);
                },
                handleFormUserAgreementClick: (value: boolean) => {
                    setShowUserAgerement(value);
                },
                handleUpdateABTestInfo: (params: { countdown: number; showLimit?: boolean }) => {
                    const { countdown } = params;
                    setABCountDownNum(countdown);
                },
                beforeSubmit: async (params) => {
                    const aggregatePageId = getUrlParam('aggregatePageId');
                    if (aggregatePageId) {
                        params.formSubmitParam.clueSource = {
                            ...params.formSubmitParam.clueSource,
                            preLpPageId: getUrlParam('preLpPageId'),
                            preCreativeId: getUrlParam('preCreativeId'),
                            aggregatePageId,
                        };
                    }
                    let signRes: string = '';
                    try {
                        signRes = await signApi({
                            query: { sigCatVer: 1 },
                            body: JSON.stringify({ ...params.formSubmitParam }),
                            // cookie: document.cookie,
                        });
                    } catch (e) {
                        console.log('e');
                    }
                    return {
                        ...params,
                        __NS_sig3: signRes,
                    };
                },
                submitBtnClick: () => {
                    // console.log('提交成功');
                    // setShow(true);
                    onSubmitClick?.();
                    trackClick('XIANSUO_FORM_BUTTON_CLICK');
                    trackEvent('XIANSUO_FORM_BUTTON_CLICK');
                    if (!assertRequiredStore()) {
                        Toast.info({
                            content: '提交失败！请选择门店',
                            className: styles.assertRequiredToast,
                        });
                        return false;
                    }
                    return true;
                },
                onSubmitSuccess: (value) => {
                    try {
                        console.log('onSubmitSuccess!!!', JSON.stringify(value?.results));
                        // CPL2场景，表单提交成功后向外层同步该状态
                        if (!isLocalhost) {
                            invoke('event.dispatchGlobalEvent', {
                                type: 'ad_consult_sku_clue_status',
                                data: JSON.stringify({ siteId: pageId }) as any,
                            });
                        }
                        //important：如果表单提交了，则页面不再展示任何类型的表单弹窗
                        actions.handleFormModalStatus(XiansuoFormModalType.none);
                        !enableTierForm && onSuccess?.(value);
                        // 非分层表单，执行关闭锚点弹窗逻辑收进上面的onSuccess了
                        // !enableTierForm && handleSubmitSuccess?.();
                        doTrackStoreSubmit();
                        trackEvent('XIANSUO_FORM_SUBMIT_SUCCESS');
                        trackClick('XIANSUO_FORM_SUBMIT_SUCCESS');
                    } catch (e) {
                        console.log('submit success handle error', e);
                    }
                },
                onSubmitError: (e) => {
                    onError?.(e);
                },
                onTierFormSubmitSuccess: () => {
                    // onSuccess?.();
                    // 是分层表单，分层表单提交时执行锚点关闭逻辑
                    handleSubmitSuccess?.();
                },
                onClickNode: () => {
                    onClick?.();
                },
            },
            ssr: isSSR,
            needCache: needCache || false,
            env: isBeta ? 'beta' : isStaging ? 'test' : 'production',
            url: getXianSuoDomain().replace('https:', ''),
            componentType: getComponentType(),
            handleFrontTraceId: handleRequestHeaders,
        };

        return {
            richText: mainTitleRich,
            config,
            modalSetting,
        };
    }, [
        edit,
        formItemWrapperStyle,
        formOpt,
        formSetting,
        formStyle,
        inputStyle,
        optionStyle,
        optionActiveStyle,
        placeholderColor,
        preview,
        showLabel,
        submitStyle,
        submitWrapperStyle,
        theme,
        titleStyle,
        mainTitleStyle,
        onSuccess,
        isSSR,
        showCustomPrivacyAgreement,
        needCache,
        aggregationInfo,
    ]);
};
