/**
 * 智能化中间页（动态组件回填逻辑）
 */
import { ComponentJson, componentsForEach } from 'omega-render';

import dateFormat from 'dateformat';
import { midPageTrans, IRecommendElementValue } from 'omega-shared/middlePageTrans';
import {
    trackIntelligentGetDataSuccess,
    trackIntelligentGetDataError,
    trackIntelligentReplaceDataSuccess,
    trackIntelligentReplaceDataError,
    trackIntelligentFetchBridgeTime,
} from './adTrack/monitor';
import { getAdBridge, importBridge } from './bridge';
import { ua, isLocalhost, isAudit } from 'landingPage/common/utils/env';
import generate from './utils/genColor';
import {
    getPackageInfo,
    getAppInfoInAudit,
    getCorporationInfo,
    reportNoFunctionIntroduction,
} from './api';
import { getUrlParam } from './utils/getUrlParam';
import { EnumPageType } from 'omega-shared/types';
import { invoke } from '@yoda/bridge';
// import { getData } from './getData';
/**
 * 从桥方法中拿到动态数据（因为是算法引擎下发的，不直接与后端交互，所以使用桥）
 */
const getRecommendData = async (
    pageId: string,
    components: ComponentJson[],
    pageType: EnumPageType,
) => {
    let recommendElements: IRecommendElementValue = {};
    try {
        let currentTime = +new Date();
        const res = await getDynamicData(components, pageType);
        // await importBridge();
        // const adBridge = getAdBridge();
        // const res: any = await adBridge?.getData();
        /**
         * 追踪中间页数据获取时间
         */
        trackIntelligentFetchBridgeTime(+new Date() - currentTime);
        // const res: any = getData;
        try {
            // trackGetData(res.data);
            const ad = res?.data?.ad;
            const feed = res?.data?.feed;
            const h5Data = JSON.parse(ad?.adDataV2?.h5Data);
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const moli_data = JSON.parse(h5Data?.moli_data || '{}');
            const accountInfo = res?.accountInfo;
            // console.log(1111,h5Data?.h5PageInspireCardText)
            // ad.data.h5Data.description  5

            recommendElements = {
                mainColor: generate(`rgb(${JSON.parse(moli_data?.mainColor ?? '{}')[5]})`), // 主色调
                appHeadImgUrl: moli_data?.appHeadImgUrl || '', // 头图地址  h5Data.coverUrlFrontend
                appIconUrl: ad?.appIconUrl || feed?.headurls?.[0]?.url, //2 下载图标地址  ad.appIconUrl || feed.headurls[0].url
                appName: h5Data?.privacyInfo?.appInfo?.appName || moli_data?.appName || '', //1,3 app名称    h5Data.privacyInfo.appInfo.appName
                appLabel:
                    typeof moli_data?.appLabel === 'string'
                        ? moli_data?.appLabel?.split(' ')
                        : (moli_data?.appLabel ?? []), //4 应用标签  ad.adDataV2.extraDisplayInfo.exposeTagInfoList
                appFlexLabel:
                    typeof moli_data?.appLabel === 'string'
                        ? moli_data?.appLabel
                        : (moli_data?.appLabel ?? []).join(' '), //4 应用标签适配不定宽标签  ad.adDataV2.extraDisplayInfo.exposeTagInfoList
                appPakageName: ad?.packageName || '', // 应用包名	ad.appName
                appVersion: h5Data?.privacyInfo?.appInfo?.appVersion || '', //10 应用版本  h5Data.privacyInfo.appInfo.appVersion
                packageSize: formatAppSize(
                    h5Data?.privacyInfo?.appInfo?.packageSize || moli_data?.appPackageSize,
                ), // 应用大小  h5Data.privacyInfo.appInfo.packageSize
                appUrl: ad?.url || '', // 应用地址  ad.url
                appPrivacyUrl: h5Data?.privacyInfo?.privacyPolicyUrl || '', //10 隐私协议地址  h5Data.privacyInfo.privacyPolicyUrl
                appUpdateTime: getDate(h5Data?.privacyInfo?.appInfo?.updateTime), // 应用更新时间  h5Data.privacyInfo.appInfo.updateTime
                appDetailImage: Array.isArray(moli_data?.appDetailImage)
                    ? (moli_data?.appDetailImage ?? []).map((img) => {
                          const iUrl =
                              typeof img === 'string' ? img.trim() : (img?.imgUrl ?? '').trim();
                          return {
                              imgUrl: iUrl.endsWith('.kpg') ? iUrl.slice(0, -3) + 'jpg' : iUrl,
                              pictureId: typeof img === 'string' ? iUrl : (img?.pictureId ?? iUrl),
                          };
                      })
                    : moli_data?.appDetailImage, //7 应用详情图}), //8 应用下载详情图
                appDescription: moli_data?.appDescription, //9 应用描述  ad.adDescription || feed.caption
                appDeveloper: h5Data?.privacyInfo?.appInfo?.developer, //10 开发者信息  h5Data.privacyInfo.appInfo.developer
                appPermissionUrl: h5Data?.privacyInfo?.appPermissions || [], //10 权限信息地址  h5Data.privacyInfo.appPermissions
                appDownloadCount: formatDownloadCount(moli_data?.appDownloadCnt ?? 0), // 应用下载次数
                appType: moli_data?.appType, // 应用下载类型
                appDownloadRank: moli_data?.appDownloadRank ? moli_data?.appDownloadRank + '' : '-', // 应用下载排名
                appVideoUrl: moli_data?.videoUrl, // 应用视频地址
                appDownloadBtnText: '', // 应用下载按钮文案  目前看都是默认的立即下载，看本期是否有优选方案
                h5PageInspireCardText: h5Data?.h5PageInspireCardText
                    ? h5Data.h5PageInspireCardText
                    : '',
                phoneModeColor: res?.phoneModeColor,
                // appBigImageHeight: res?.appBigImageHeight,
                // appBigImageWidth: res?.appBigImageWidth,
                accountId: accountInfo?.accountId,
                compName: accountInfo?.compName,
                corporationName: accountInfo?.corporationName,
                functionIntroduction: h5Data?.privacyInfo?.functionIntroduction || '',
                appUserInterest: moli_data?.appUserInterest ?? '', // 用户感兴趣，plc中间页
                appUserAttention: moli_data?.appUserAttention ?? '', // 用户已关注， plc中间页
                appUserRecord: moli_data?.appUserRecord ?? [], // 用户浏览记录， plc中间页
                description: h5Data?.description ?? '', //5 应用简介，plc中间页
            };
            trackIntelligentGetDataSuccess(pageId);
        } catch (e) {
            console.log('取数替换error', e);
            trackIntelligentGetDataError(pageId, '字段获取失败：' + (e as Error).message);
        }
    } catch (e) {
        console.log('bridge获取数据error', e);
        trackIntelligentGetDataError(pageId, '桥取数失败：' + (e as Error).message);
    }
    return recommendElements;
};
/**
 * 根据拿到的结果替换组件内容
 */
const intelligentReplace = async (
    components: ComponentJson[],
    pageId: string,
    pageType: EnumPageType,
) => {
    let recommendElements: IRecommendElementValue = {};
    recommendElements = await getRecommendData(pageId, components, pageType);
    try {
        await midPageTrans(components, recommendElements, true, pageType);
        // 更新返回组件的状态栏高度
        const statusHeight = ua.match(/StatusHT\/(\d*)/)?.[1]
            ? Number(ua.match(/StatusHT\/(\d*)/)?.[1])
            : 40;
        console.log('statusHeight', statusHeight);
        replaceNavbackComponentHeight(components, statusHeight + 10);
        trackIntelligentReplaceDataSuccess(pageId);
        if (!recommendElements.functionIntroduction) {
            reportNoFunctionIntroduction({
                pageId: getUrlParam('pageId'),
                failReason: '中间页，未获取功能介绍字段',
                callback: getUrlParam('pageId'),
            });
        }
    } catch (e) {
        trackIntelligentReplaceDataError(pageId, '替换桥数据失败：' + e.message);
    }
    try {
        replaceFlag(components);
    } catch (e) {
        trackIntelligentReplaceDataError(pageId, '空白替换异常：' + e.message);
    }
};

const replaceNavbackComponentHeight = (components: ComponentJson[], statusHeight: number) => {
    components?.forEach((com) => {
        if (com.type === 'NAVBACK_FOR_MIDPAGE') {
            com?.magic?.dragFree && (com.magic.dragFree.y = statusHeight);
        }
        replaceNavbackComponentHeight(com?.components || [], statusHeight);
    });
};
/*
 * 格式化app大小
 * 1.如果大小超过GB，则展示xx.xxGB
 * 2.如果大小不超过GB，则展示xx.xxMB
 */
const formatAppSize = (size: number | string, iCount: number = 2) => {
    if (!size) {
        return '更新中';
    }
    if (typeof size === 'string') {
        return `${size}MB`;
    }
    if (size / 1024 / 1024 / 1024 > 1) {
        // GB
        return `${Number(size / 1024 / 1024 / 1024).toFixed(iCount)}GB`;
    }
    // MB
    return `${Number(size / 1024 / 1024).toFixed(iCount)}MB`;
};

const formatDownloadCount = (count: number) => {
    if (count < 10000) return count + '';
    if (count < 100000000) return (count / 10000).toFixed(1) + '万';
    return (count / 100000000).toFixed(1) + '亿';
};
/**
 * 返回YYYY-MM-DD格式
 */
const getDate = (time: number) => {
    if (!time) return '-';
    return dateFormat(new Date(time), 'yyyy.mm.dd');
};

/**
 * 把没有替换掉的占位符用空字符串代替
 */
const flags = [
    'appIconUrl',
    'appName',
    'appPackageName',
    'packageSize',
    'appVersion',
    'appUrl',
    'appUpdateTime',
    'appPrivacyUrl',
    'appDeveloper',
    'appPermissionUrl',
    'appDownloadRank',
    'appDownloadCount',
    'appHeadImgUrl',
    'Label',
    'Description',
    'DetailImage',
    'Type',
    'VideoUrl',
];
export const replaceFlag = (components: ComponentJson[]) => {
    for (const com of components) {
        if (com.props) {
            replaceObj(com.props);
        }
        com.components && replaceFlag(com.components);
    }
};
const replaceObj = (obj: Object) => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            replaceObj(obj[key]);
        }
        if (typeof obj[key] === 'string' && flags.indexOf(obj[key]) >= 0) {
            obj[key] = '';
        }
    });
};

/** 智能中间页目前会为了送审链路可获得动态替换部分的数据，需要对获取动态数据部分进行链路分拆
 *  1、判断当前是送审链路，则获取appInfo中已填充的packageId/appId 调用应用中心接口去获取数据
 *  2、其他链路，默认使用c端bridge获取动态替换数据
 *  详细方案见 https://docs.corp.kuaishou.com/k/home/VDBJAta3O9us/fcAA3Xd0uEIJfFYZgtxY5JM-C
 */
const getDynamicData = async (components: ComponentJson[], pageType: EnumPageType) => {
    let result: any;
    let accountId = getUrlParam('ksSiteAccountId');
    let compName = '';
    let corporationName = '';
    componentsForEach(components, (com) => {
        if (com.type === 'CORPORATE_INFO') {
            if (!accountId?.length) {
                accountId = com?.props?.midPageAccountInfo?.accountId;
            }

            corporationName = com?.props?.midPageAccountInfo?.corporationName;
        }
    });
    console.log('accountId', accountId);
    if (accountId) {
        try {
            const accountInfo = await getCorporationInfo(accountId);
            console.log('accountInfo', accountInfo);
            if (accountInfo) {
                compName = accountInfo;
            }
        } catch (e) {
            console.log('-----e', e);
            compName = corporationName;
        }
    }

    if (isLocalhost || isAudit) {
        let appInfo: any = {};
        componentsForEach(components, (com) => {
            if (com.type === 'APP_DOWNLOAD_DRAG' || com.type === 'PEC_DOWNLOAD_FOR_MIDPAGE') {
                appInfo = com?.props?.appInfo;
            }
        });

        try {
            let res: any;
            if (appInfo?.packageId && appInfo?.packageId !== '0') {
                console.log('-------packageId', appInfo?.packageId);
                res = await getPackageInfo(String(appInfo?.packageId));
            } else {
                /** 使用老应用生产中间页场景：
                 * 原来的getInfo接口由于强依赖广告主accountId，但中间页不属于任何一个广告主，所以无法查到app信息
                 * 为兼容这一问题，后端新增了一个接口，专门用于老应用生产的中间页送审时使用，便于查询app信息 */
                res = await getAppInfoInAudit(
                    String(appInfo?.appId),
                    'android',
                    getUrlParam('pageId') || '',
                );
            }

            result = {
                data: {
                    ad: {
                        appIconUrl: res?.appIconUrl,
                        url: res?.appDownloadUrl,
                        adDataV2: {
                            h5Data: JSON.stringify({
                                privacyInfo: {
                                    appInfo: {
                                        appName: res?.appName,
                                        appVersion: res?.version,
                                        packageSize: res?.packageSize,
                                        privacyPolicyUrl: res?.privacyUrl,
                                        developer: res?.developerName,
                                        appPermissions: res?.permissionList,
                                    },
                                },
                                moli_data: JSON.stringify({}),
                            }),
                        },
                    },
                    feed: {},
                },
            };
        } catch (e) {
            return {};
        }
    } else {
        try {
            await importBridge();
            const adBridge = getAdBridge();
            result = await adBridge?.getData();
        } catch (e) {}
    }
    try {
        switch (pageType) {
            case EnumPageType.大白中间页: {
                const { darkMode } = await invoke('platform.getDarkMode');
                result = {
                    ...result,
                    phoneModeColor: darkMode ? ['#ffffff', '#9c9c9c'] : ['#222222', '#9c9c9c'],
                };
                break;
            }
            default:
                break;
        }
    } catch (e) {
        console.log('浏览器没有yoda示例，不影响其他展示');
    }

    return {
        ...result,
        accountInfo: {
            accountId,
            compName,
            corporationName,
        },
    };
};
export default intelligentReplace;
