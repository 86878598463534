import React from 'react';
import { Toast } from '@ad/sharpui';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { callApp } from 'landingPage/common/utils/callApp';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { useLongPress } from '../../WeiXin/LongPressDialog';
import { PopupType, WechatActionSceneEnum, WeiXinProps } from '../WeiXin';
import WeixinIcon from '../image/WeiXinIcon';
import styles from '../explicit.module.less';
import { copy, wechatDataTrack } from './commonFun';
import { CopyDialogProps } from './CopyDialog';
import { WeixinToolTip } from './WexinTooltip';
import { getTransCompList } from 'landingPage/common/impression';
// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

export const ExplicitButton: React.FC<WeiXinProps & CopyDialogProps & { preview?: boolean }> = (
    props,
) => {
    const {
        text,
        opt,
        preview,
        explicitButtonStyles,
        explicitWechatStyles,
        explicitPrexStyles,
        explicitDescStyles,
    } = props;
    const wechatElm = React.useRef<HTMLDivElement>(null);
    const [tooltipType, setTooltipType] = React.useState<'top' | 'bottom'>('top');
    const wechatIdElm = React.useRef<HTMLDivElement>(null);
    const [tooltipPosition, setTooltipPosition] = React.useState({
        top: -100,
        left: -100,
    });

    const { wechatId, onClose, itemId, packId, appId } = props;

    const [selected, setSelected] = React.useState(false);

    const longPressCb = React.useCallback(() => {
        // nodePitch(document.querySelector('.wechat-id'))
        setSelected(true);
        if (!wechatId?.length && !itemId?.length) {
            Toast.error('暂无可复制的微信号');
        }
        wechatId &&
            copy({
                text: wechatId,
                itemId: itemId,
                packId: packId,
            });
        console.log('%clongPressed', 'color: pink');
        if (wechatIdElm.current) {
            const wechatIdPosition = wechatIdElm.current?.getBoundingClientRect();
            if (wechatIdPosition.top < 44) {
                setTooltipType('bottom');
                setTooltipPosition({
                    top: wechatIdPosition.bottom - 4,
                    left: wechatIdPosition.left + wechatIdPosition.width / 2,
                });
            } else {
                setTooltipType('top');
                setTooltipPosition({
                    top: wechatIdPosition.top - 47,
                    left: wechatIdPosition.left + wechatIdPosition.width / 2,
                });
            }
        }
    }, [itemId, packId, wechatId]);
    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
                lp_page_type: 1,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: PopupType.COMPONENT,
                }),
            });
    }, [itemId, wechatId]);
    const [onLongPressStart, onLongPressEnd] = useLongPress(500, longPressCb);
    // React.useEffect(() => {
    //   wechatId && copy(wechatId, itemId, packId);
    // }, [info, itemId, packId, wechatId]);
    const callWX = React.useCallback(() => {
        // dataTrack({ eventType: 'EVENT_ADD_WECHAT', wechatItemId: itemId });
        // 多转化事件优化目标，不打除表单转化的EVENT_FORM_SUBMIT点
        if (!multiConversion) {
            dataTrack({
                eventType: 'EVENT_FORM_SUBMIT',
                lp_page_type: 1,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    landing_page_infos: JSON.stringify({
                        page_module_type: getTransCompList(),
                    }),
                }),
            });
        }
        callApp({
            link: 'weixin:// ',
            url: 'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
            successCallBack: () => {
                wechatDataTrack({
                    itemId,
                    invokeSuccess: true,
                    sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                    wechatId,
                    appId,
                    popupType: PopupType.COMPONENT,
                });
            },
            failCallBack: () => {
                wechatDataTrack({
                    itemId,
                    invokeSuccess: false,
                    sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                    wechatId,
                    appId,
                    popupType: PopupType.COMPONENT,
                });
            },
        });
        onClose?.();
    }, [itemId, wechatId, appId, onClose]);
    const onClick = React.useCallback(() => {
        setSelected(false);
        callWX();
    }, [callWX]);

    const getWechatId = React.useCallback(() => {
        if (preview) {
            return opt?.wechatId;
        }
        return wechatId?.length ? wechatId : '';
    }, [opt?.wechatId, preview, wechatId]);

    return (
        <div
            className={styles.root}
            style={explicitButtonStyles}
            onTouchStart={onLongPressStart}
            onTouchEnd={onLongPressEnd}
        >
            <div className={styles.explicitWrapper}>
                <div className={styles.wechat} ref={wechatElm}>
                    <span className={styles.weixinIcon}>
                        <WeixinIcon color={explicitWechatStyles?.color} />
                    </span>
                    <span className={styles.weixinhao} style={explicitPrexStyles}>
                        微信号：
                    </span>
                    <span
                        style={explicitWechatStyles}
                        className={styles.wechatId}
                        ref={wechatIdElm}
                    >
                        {getWechatId()}
                        {selected && !!wechatId?.length && (
                            <WeixinToolTip
                                tooltipPosition={tooltipPosition}
                                tooltipType={tooltipType}
                                onClick={onClick}
                            />
                        )}
                    </span>
                </div>
                <div className={styles.desc} style={explicitDescStyles}>
                    {text}
                </div>
            </div>
        </div>
    );
};
