import Modal from 'landingPage/common/components/Modal/Modal';
import { render } from 'react-dom';
import { ComponentZIndex } from 'omega-shared/utils';
import { useRenderState } from 'omega-render';
import { useEffect, useState } from 'react';
import {
    defaultLeavePageModalStyle,
    LeavePageModalStyleProps,
    modalType,
    useXiansuoFormContainerActions,
    XiansuoFormModalType,
} from '../store';
import { Provider } from 'react-redux';
import styles from './style.module.less';
import { generateRandomEndTime, getRandomIconList, iconList } from './utils';
import { getTimeDiffText, useCountdownHook } from '../../CountdownDrag/countdownHook';
import { exitWebview } from '@/landingPage/common/bridge';
import { sendRetentionCount } from '@/landingPage/common/api';
import { scrollToAnchorForm } from '@/landingPage/common/utils/scrollToAnchorForm';

/** 表单挽留弹窗 */
export const handleNewFormStayModal = (props) => {
    const modal = document.getElementById('xiansuoFormStayModal');

    const showFormModal = props?.getState()?.xiansuoForm?.showModalType;
    const leavePageModalStyle = props?.getState()?.xiansuoForm?.leavePageModalStyle;

    //后端返回支持展示挽留弹窗 & 页面停留时长超过ab实验设置的时长
    const canShowModal =
        showFormModal === XiansuoFormModalType.leavePageModal &&
        new Date().getTime() - performance.timing.connectStart >=
            leavePageModalStyle?.waitTimer * 1000;

    //如果modal存在，说明已经触发过挽留弹窗的逻辑，则下次再触发时直接退出页面
    //如果不满足  支持展示表单挽留弹窗 且 页面停留时长超过ab实验设置的时长，则直接退出页面
    if (modal || !canShowModal) {
        exitWebview();
        return;
    }
    let divElememt = document.createElement('div');
    divElememt.id = 'xiansuoFormStayModal';
    document.body.appendChild(divElememt);
    render(
        <Provider store={props}>
            <NewFormStayModel />,{' '}
        </Provider>,
        // eslint-disable-next-line react/jsx-props-no-spreading
        divElememt,
        // window.document.body,
    );
};

export const NewFormStayModel: React.FunctionComponent = () => {
    const { leavePageModalStyle, commitFormCount, headImgList, anchorForm } = useRenderState(
        (state) => {
            return {
                leavePageModalStyle: (state.xiansuoForm?.leavePageModalStyle ||
                    defaultLeavePageModalStyle) as LeavePageModalStyleProps,
                commitFormCount: state.xiansuoForm?.commitFormCount,
                headImgList: state.xiansuoForm?.headImgList,
                anchorForm: state.rolex.opt?.originalPageJson?.pageMeta?.anchorForm,
            };
        },
    );

    const [showModal, setShowModal] = useState(false);
    const [endTime, setEndTime] = useState(0);
    const [randomImgList, setRandomImgList] = useState<typeof iconList>([]);
    const actions = useXiansuoFormContainerActions();
    const hookProps = useCountdownHook(endTime);

    const { hourdiff, minutediff, seconddiff } = hookProps;

    useEffect(() => {
        // 挽留弹窗展示前先关闭锚点弹窗
        actions.handleCloseAnchorModal(true);
        setShowModal(true);
        setRandomImgList(getRandomIconList(4));
        if (Boolean(leavePageModalStyle?.showHeadImg)) {
            actions.fetchFormModalData();
        }
        sendRetentionCount(modalType.leaveModal);
    }, []);

    useEffect(() => {
        if (leavePageModalStyle?.showCountDown) {
            setEndTime(generateRandomEndTime());
        }
    }, [leavePageModalStyle]);

    const renderTimeDiff = (timediff: number, unit: string) => {
        return (
            <div className={styles.timebox}>
                <div className={styles.timeText}>
                    {getTimeDiffText(timediff).map((item) => {
                        return item;
                    })}
                </div>
                <div className={styles.unit}>{unit}</div>
            </div>
        );
    };

    const handleAnchorForm = () => {
        const index = anchorForm?.checked ? Number(anchorForm?.formIndex || 0) : 0;
        scrollToAnchorForm(index);
    };

    const closePage = () => {
        setShowModal(false);
        exitWebview();
    };

    return (
        <>
            {showModal && (
                <Modal
                    style={{ zIndex: ComponentZIndex.XiansuoFormZIndex }}
                    parent={document.getElementById('preview-app') ?? document.body}
                >
                    <div className={styles.root}>
                        <img
                            className={styles.close}
                            src={
                                'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-12/1731400435663.eac8a6115915f946.png'
                            }
                            onClick={() => {
                                setShowModal(false);
                            }}
                        />
                        <div className={styles.title}>确定放弃以下福利吗</div>
                        {Boolean(leavePageModalStyle.showCountDown) && (
                            <div className={styles.countdownText}>
                                限时领取
                                <div className={styles.countdown}>
                                    {renderTimeDiff(hourdiff, ':')}
                                    {renderTimeDiff(minutediff, ':')}
                                    {renderTimeDiff(seconddiff, '')}
                                </div>
                            </div>
                        )}
                        {Boolean(leavePageModalStyle.showHeadImg) && Boolean(commitFormCount) && (
                            <div className={styles.headImg}>
                                {headImgList?.map((item, index) => (
                                    <img
                                        className={styles.img}
                                        src={item}
                                        key={`img-${index}`}
                                        style={{
                                            marginLeft: index > 0 ? '-5px' : 0,
                                            zIndex: 3 - index,
                                        }}
                                    />
                                ))}
                                <div style={{ marginLeft: 8 }}>
                                    等{commitFormCount}人已领取了免费福利
                                </div>
                            </div>
                        )}
                        <div className={styles.randomIconList}>
                            {randomImgList.map((item, index) => {
                                return (
                                    <div key={index} className={styles.iconList}>
                                        <img src={item.icon} className={styles.icon} />
                                        <div className={styles.iconText}>{item.title}</div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.button}>
                            <div className={styles.leaveBtn} onClick={closePage}>
                                忍痛离开
                            </div>
                            <div
                                className={styles.confirmBtn}
                                onClick={() => {
                                    setShowModal(false);
                                    handleAnchorForm();
                                }}
                            >
                                我再看看
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
