import * as React from 'react';
import { StyleType, XiansuoFormProps as FormSingleProps } from '../XiansuoFormDrag/XiansuoForm';
import { xiansuoFormPayProps as FormPayProps } from '../xiansuoFormPay/xiansuoFormPay';
import XiansuoFormDrag from '../XiansuoFormDrag';
import XiansuoFormMulti from '../XiansuoFormMulti';
import XiansuoFormPay from '../xiansuoFormPay';

export { reducerMap } from '../XiansuoFormDrag/store';

const XiansuoForm: React.FunctionComponent<FormSingleProps> = (props) => {
    const { activityId, displayType, formOpt } = props;
    if (displayType === StyleType.PAY || activityId) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <XiansuoFormPay {...(props as FormPayProps)} />;
    }
    if ([StyleType.MULTI, StyleType.CHAT].includes(displayType) || formOpt?.tag === 'STEP_FORM') {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <XiansuoFormMulti {...(props as any)} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <XiansuoFormDrag {...(props as FormSingleProps)} />;
};

export default XiansuoForm;
