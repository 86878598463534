import ax from 'axios';

import { basePath } from '../utils/env';
import { getXianSuoDomain } from '../utils/getXianSuoDomain';
import { getMoliDomainInAduit } from '../utils/getMoliDomainInAduit';
import { trackApiError, trackApiDuration, sendTraceLog } from '../adTrack/monitor';
import {
    FormParams,
    MSGParams,
    LocationParams,
    IMessagelong,
    IAppDownloadSubmitData,
    IMessageGetSchemaResult,
} from './interface';
import * as model from './interface';
import { IFormSubmitParam } from '@ad/xiansuoSDK/lib/src/clue-model/services/models';
import { ComponentJson } from 'omega-render';
import { SelectItem } from '@/landingPage/components/XiansuoFormMulti/components/IMComponent';
import { LaunchTypeEnum } from '@/landingPage/components/WeiXinDrag/components/JumpWxKefu';
import { handleRequestHeaders } from '../utils/handleTraceId';

// 单独使用的axios
const axios = ax.create({
    withCredentials: true,
    headers: {
        'Moli-Front-Trace-Id': window.SSR_TRACE_ID,
    },
});

axios.interceptors.request.use(
    (config) => {
        config.metadata = { startTime: Date.now() };
        config.headers['Front-Trace-Id'] = handleRequestHeaders(config?.url || '');
        return config;
    },
    async (err) => {
        return Promise.reject(err);
    },
);

axios.interceptors.response.use(
    (res) => {
        const { data } = res;
        try {
            const endTime = Date.now();
            const duration = endTime - (res.config?.metadata?.startTime || 0);
            trackApiDuration(res?.config?.url ?? '', duration);
        } catch (err) {
            console.error(err);
        }
        if (data && +data.result === 1) {
            // trackApi(res?.config?.url!);
            return res;
        }
        trackApiError(res?.config?.url ?? '', `${res?.data?.result}`);
        const pageJson = JSON.parse(window?.pageJsonString || '{}');
        sendTraceLog({
            tags: {
                pageId: pageJson?.id,
                apiUrl: res?.config?.url ?? '',
                pageUrl: window.location.href,
                errs: res?.data?.result,
                ua: navigator?.userAgent,
                specTag: 'API_RESULT_ERROR',
            },
        });

        if (data && +data.result === 109) {
            data.error_msg = '网络连接失败，请刷新当前页面。';
        }
        return Promise.reject(data);
    },
    async (err) => {
        trackApiError(err?.config?.url ?? '', err?.toString());
        const pageJson = JSON.parse(window?.pageJsonString || '{}');
        sendTraceLog({
            tags: {
                pageId: pageJson?.id,
                apiUrl: err?.config?.url ?? '',
                pageUrl: window.location.href,
                errs: err?.toString(),
                ua: navigator?.userAgent,
                specTag: 'jdd_API_404',
            },
        });
        return Promise.reject(err);
    },
);

export interface Messagestring {
    data: IccSubmitOneResult;

    host: string;

    message: string;

    port: number;

    result: number;

    timestamp: string;
}

export interface IccSubmitOneResult {
    successList: string[];
    errorDetailMap: ErrorDetailMap;
    status: string;
}

export interface ErrorDetailMap {
    errorResult: string;
}

export interface ClueFirstSubmitParam {
    /** 扩展字段 */
    extraInfo: IIccExtraInfo;
    /** 线索数据描述信息 */
    iccClueSourceDescView: ClueSourceDescView;
    /** 智能电话id */
    iccId: string;
    /** 门店id */
    storeId?: string;
    /** 门店名称 */
    storeName?: string;
    /** 提交用户id */
    submitUserId?: string;
    /** 定向城市 */
    orientedCity?: string;
    reportRenderInfo: {
        // 新增加的字段
        renderType: string; // renderType的值是字符串类型
    };
    iccUseTypeEnum: number;
}

export interface IIccExtraInfo {
    /** 是否是前置落地页 0未知 1前置 2非前置 */
    isPreLandingPage: number;
    /** 来源 1落地页 2咨询页,默认落地页 */
    lpPageType: number;
}

export interface ClueSourceDescView {
    /** 设备描述符 */
    deviceId: string;
    /** DSP回调 */
    dspCallback: string;
    /** 发布资源id，如落地页id */
    pubSourceId: string;
    /** 线索收集方业务线 */
    publishBizId: number;
    /** 来源url */
    sourceUrl: string;
}

export interface wechatItemList {
    data: wechatItem;

    host: string;

    message: string;

    port: number;

    result: number;

    timestamp: string;
}
export interface wechatItem {
    createTime: number;
    id: string;
    isDel: boolean;
    packId: string;
    updateTime: number;
    useCount: number;
    wechatAppId: string;
    wechatId: string;
    wechatNickname: string;
    wechatType: number;
    wechatQrCodeUrl: string;
    againstFlowPrefixSwitch?: boolean;
    againstFlowPrefix?: string;
}

/** 提交表单 */
export async function submitFormPOST(params: FormParams) {
    const result = await axios.post(`${basePath}/rest/n/lp/form/submit`, params);
    return result.data.data;
}

/** 短信接口 */
export async function sendVerify(params: MSGParams) {
    const result = await axios.post(`${basePath}/rest/n/lp/sms/sendVerify`, params);
    return result.data.data;
}

/** 定位 */
export async function locationSearch(params: LocationParams) {
    const result = await axios.get(
        `${basePath}/rest/n/lp/geo/locationSearch?lat=${params.lat}&lng=${params.lng}`,
    );
    return result.data.data;
}

/** 地域接口 */
export async function regionListGET() {
    const result = await axios.get(`${basePath}/rest/n/lp/region/list`);
    return result.data.data;
}

/** 获取计数器 */
export async function getCounter(pageId: string | null) {
    const result = await axios.get(`${basePath}/rest/n/lp/form/getCounter?pageId=${pageId}`);
    return result.data.data;
}

/** 获取滚动墙 */
export async function getRollWall(pageId: string | null) {
    const result = await axios.get(`${basePath}/rest/n/lp/form/getRollWall?pageId=${pageId}`);
    return result.data.data;
}

/** Icc Clue Controller  线索第一次提交返回中间号 */
export async function submitIccCluePhaseOneUsingPOST(params: {
    iccClueFirstSubmitParam: ClueFirstSubmitParam;
}) {
    const path = getXianSuoDomain();

    const result = await axios.post<Messagestring>(`${path}/rest/c/gemini/iccClue/iccSubmitOne`, {
        ...params.iccClueFirstSubmitParam,
    });
    return result.data.data;
}

export interface AppInfo {
    appId: string;
    appName: string;
    version: string;
    developerName: string;
    privacyUrl: string;
    permissionList: { permissionName: string; permissionDetail: string }[];
    md5List: string[];
    check: boolean;
    appDownloadUrl?: string;
    packageId: string;
    functionIntroduction?: string;
}

export async function getAppInfo(
    appId: string,
    callback: string,
    platform: string,
    pageId: string,
) {
    const res = await axios.get(`${basePath}/rest/n/lp/app/getInfo`, {
        params: {
            appId,
            callback,
            platform,
            pageId,
        },
    });
    return res.data.data as AppInfo;
}

export async function getPackageInfo(packageId: string) {
    const path = getMoliDomainInAduit();
    console.log('-----path', path);
    const res = await axios.get(`${path}/rest/c/lp/package/getInfo`, {
        params: {
            packageId,
        },
    });
    return res.data.data as AppInfo;
}

export async function getAppInfoInAudit(appId: string, platform: string, pageId: string) {
    const path = getMoliDomainInAduit();
    const res = await axios.get(`${path}/rest/c/lp/app/audit/getInfo`, {
        params: {
            appId,
            platform,
            pageId,
        },
    });
    return res.data.data as AppInfo;
}

export async function getCorporationInfo(accountId: string) {
    const path = getMoliDomainInAduit();
    const res = await axios.get(`${path}/rest/c/lp/account/corporationName`, {
        params: {
            accountId,
        },
    });
    return res.data.data;
}

/** Config Controller  查询落地页端版本 */
export async function needVersionUsingGET(payload: { pageId?: string }) {
    const params = {
        pageId: payload.pageId,
    };

    const result = await axios.request<model.IResponseVersionView>({
        url: `/rest/lp/config/need-version`,
        method: 'get',
        params,
    });

    return result.data;
}

export async function getNeedVersion(pageId: string) {
    // 如果是审核、预览就走新接口
    if (window.location.href.includes('/rest/lp/admin/page/getHtml')) {
        const data = await needVersionUsingGET({
            pageId,
        });
        return {
            data,
        };
    }
    const res = await axios.get(`${basePath}/rest/n/lp/config/need-version`, {
        params: {
            pageId,
        },
    });
    return res;
}

export async function xianSuoCopy(param: {
    deviceId?: string;
    deviceType: '0';
    dspCallback: string;
    itemId: string;

    packId: string;
    packageId: string;
    pubBizId: 1003;
    /** 落地页id */
    pubSourceId: string;
    /** 页面地址 */
    sourceUrl: string;
}) {
    const url = getXianSuoDomain();
    await axios.post(`${url}/rest/c/gemini/wechat/record`, param);
}

export async function outlinksUsingGET() {
    const result = await axios.get<{
        data: string[];

        host: string;

        msg: string;

        port: number;

        result: number;

        timestamp: string;
    }>(`/rest/n/lp/config/out-link`);
    return result.data.data;
    // return ['tbopen://m.taobao.com', 'weixin://'];
}

export async function wechatItemSelect(id: string, qrCodeRequired?: boolean) {
    const path = getXianSuoDomain();

    const result = await axios.post<wechatItemList>(`${path}/rest/c/gemini/wechat/item/select`, {
        itemId: id,
        qrCodeRequired,
    });
    return result.data.data;
}

export async function wechatPkgSelect(params: string, qrCodeRequired?: boolean) {
    const path = getXianSuoDomain();
    const result = await axios.post<wechatItemList>(`${path}/rest/c/gemini/wechat/package/select`, {
        packageId: params,
        qrCodeRequired,
    });
    return result.data.data;
}

interface IMessage<T = object> {
    data: T;
    host: string;
    msg: string;
    port: number;
    result: number;
    timestamp: string;
}
/** C端白名单 * */
export async function configSwitchClientUsingGet(params: {
    req: {
        id: number;
        caseName: string;
    };
}) {
    const result = await axios.get<IMessage<boolean>>(`/rest/n/lp/config/switch`, {
        params: { ...params.req },
    });
    return result.data;
}

/** C端应用下载组件接口  应用下载组件数据提交 */
export async function appDownloadSubmitUsingPOST(payload: { submitData?: IAppDownloadSubmitData }) {
    const data = {
        ...payload.submitData,
    };

    const result = await axios.request<IMessagelong>({
        url: `/rest/n/lp/app/download/submit`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

// c端根据定位获取城市
export async function getCityByLocationUsingPOST(location: { lat: string; lon: string }) {
    const result = await axios.post('/rest/c/gemini/region/location', location);
    return result?.data?.data;
}

// c端根据字母获取对应城市列表
export async function getCityListUsingPOST(name: string = '') {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.post(`${xiansuoDomain}/rest/c/gemini/region/city/list`, { name });
    return result?.data?.data;
}

/** 根据定位获取城市 */
export async function regionLocationUsingPost(payload: model.lonLatParamParam) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.request<model.IMessageResultRegionView>({
        url: `${xiansuoDomain}/rest/c/gemini/region/location`,
        method: 'post',
        data: payload,
    });
    return result.data.data;
}

/** 推荐门店 */
export async function geminiStoreListUsingPost(payload: model.storeRecoParam) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.request<model.IMessageResultStoreRecoListView>({
        url: `${xiansuoDomain}/rest/c/gemini/store/list`,
        method: 'post',
        data: payload,
    });
    return result.data.data;
}

/** Store Center Controller  门店id列表 新的推荐门店接口 */
export async function listStoreUsingPOST(payload: { param: model.IStoreListParam }) {
    const xiansuoDomain = getXianSuoDomain();
    const data = {
        ...payload.param,
    };

    const result = await axios.request<model.IMessagestoreRecoListView>({
        url: `${xiansuoDomain}/rest/c/laotie/store/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** Store Center Controller  门店详情 */
export async function storeDetailUsingPOST(payload: { param: { storeId: string } }) {
    const xiansuoDomain = getXianSuoDomain();
    const data = {
        ...payload.param,
    };

    const result = await axios.request<model.IMessagestoreDetailView>({
        url: `${xiansuoDomain}/rest/c/laotie/store/detail`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}
/** 门店  查询门店组信息 */
export async function storeGroupInfoUsingPOST(payload: { groupId?: string }) {
    const data = {
        groupId: payload.groupId,
    };

    const result = await axios.request<model.IResponseStoreGroupView>({
        url: `/rest/lp/store/group/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 门店  查询门店组列表 */
export async function storeGroupListUsingPOST(payload: { req: model.IStoreListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseMultiResponseWrapStoreGroupView>({
        url: `/rest/lp/store/group/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 门店  查询门店信息 */
export async function storeInfoUsingPOST(payload: { storeId?: string }) {
    const data = {
        storeId: payload.storeId,
    };

    const result = await axios.request<model.IResponseStoreView>({
        url: `/rest/lp/store/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 门店  查询门店列表 */
export async function storeListUsingPOST(payload: { req: model.IStoreListReq }) {
    const data = {
        ...payload.req,
    };

    const result = await axios.request<model.IResponseStoreMultiView>({
        url: `/rest/lp/store/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 获取支付表单skuId */
export async function geminiSaleSkuSelectUsingPost(payload: model.skuSelectParam) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.request<model.IMessageSkuSelectView>({
        url: `${xiansuoDomain}/rest/c/gemini/sale/sku/select`,
        method: 'post',
        data: payload,
    });

    return result.data.data;
}

// 获取小程序 schema，C端
export async function getSchemaUsingPost(params: { appletsId: number; callback: string }) {
    const { appletsId, callback } = params;
    const result = await axios.post<IMessageGetSchemaResult>('/rest/n/lp/wechat/getScheme', {
        appletsId,
        callback,
    });
    return result.data;
}

export async function getIpUsingGet() {
    const result = await axios.get<{ data: string }>('/rest/n/lp/common/getRequestIp');
    return result.data.data;
}

/** 门店  获取门店包下任意一个门店 */
export async function getStoreUsingPOST(payload: { packageId?: string }) {
    const data = {
        packageId: payload.packageId,
    };

    const result = await axios.request<model.IMessageStoreDetailView>({
        url: `/rest/lp/store/getStore`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data.data;
}

/** 营销组件接口  获取配置接口，包含本地生活支付页地址domain */
export async function getCommonConfig() {
    const result = await axios.get<model.IMessageJumpUrl>(`/rest/n/lp/config/common`);
    return result.data.data;
}
/** 获取cid宏参数配置接口 */
export async function getCidCommonConfig() {
    const result = await axios.get<model.IMessageMacro>(`/rest/n/lp/config/commonConfig`);
    return result.data.data;
}

/** coupon-controller 卡券明细 */
export async function couponDetailUsingPOST(param: model.ICouponDetailRequest) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.post<model.IMessagecouponView>(
        `${xiansuoDomain}/rest/c/laotie/coupon/detail`,
        {
            ...param,
        },
    );
    return result.data;
}
/** coupon-controller 卡券领取 */
export async function receiveUsingPOST(param: model.ICouponReceiveRequest) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.post<model.IMessageboolean>(
        `${xiansuoDomain}/rest/c/laotie/coupon/receive`,
        {
            ...param,
        },
    );

    return result.data;
}

/** sms-controller 发送短信验证码 */
export async function sendVerifyUsingPOST(param: model.ISmsVerifyParam) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.post<model.IMessageVoid>(
        `${xiansuoDomain}/rest/c/gemini/sms/sendVerify`,
        { ...param },
    );

    return result.data;
}
/** 获取短信倒计时ab */
export async function getCountdownABRes(param) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.get<model.ICountdownRes>(
        `${xiansuoDomain}/rest/c/gemini/user/abtest/msg-interval/params`,
        { params: { ...param } },
    );
    return result.data;
}

/** AB实验分组信息 */
export async function getABParams() {
    const result = await axios.get<model.IMessageAB>(`/rest/n/lp/config/getAbTestParam`);
    return result.data.data;
}

/* 获取互动弹窗信息 */
export async function getPopupInfo(payload: { pageId: string }) {
    const data = {
        pageId: payload.pageId,
    };
    const result = await axios.request<model.IMessagePopup>({
        url: `/rest/n/lp/popup/info`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return result.data.data;
}

export interface IGetCityParam {
    lat: number;
    lon: number;
    pageId: string;
    callback?: string;
}

export async function getCity(params: IGetCityParam) {
    try {
        return (await axios.post('/rest/n/lp/page/getCurrentCity', params)).data.data;
    } catch (e) {
        // message.error(e?.message || '获取城市数据失败');
    }
}

export async function fetchWechatGameInfo(gameId: string) {
    const xiansuoDomain = getXianSuoDomain();
    const result = await axios.post(`${xiansuoDomain}/rest/c/laotie/wechatGame/detail`, {
        gameId,
    });
    return result.data.data;
}

export async function fetchWechatMiniAppInfo(param: { appletsId: string; pageId: string }) {
    const xiansuoDomain = getXianSuoDomain();

    const result = await axios.post(`${xiansuoDomain}/rest/c/gemini/wechat/applets/detail`, {
        ...param,
    });
    return result.data.data;
}

export async function fetchAppPackageCheck(param: { callback: string; pageId: string }) {
    const result = await axios.post(`/rest/c/lp/app/appPackageCheck`, {
        ...param,
    });
    return result.data.data;
}
/**
 *
 * @param param 获取企微加粉详情
 * @returns
 */
export async function fetchWechatServiceInfo(param: { planId: string; pageId: string }) {
    const result = await axios.post(`/rest/c/gemini/wechat/qywx-fans-plan/detail`, {
        ...param,
    });
    return result.data.data;
}

/* 获取聚合落地页信息 */
export async function geAggregateAdInfo(
    payload: model.aggregatePageParams,
    query?: { __NS_sig3: string; sigCatVer: number },
) {
    // const data = {
    //     pageId: payload.pageId,
    // };
    const result = await axios.request<model.IAggregateInfo>({
        url: `/rest/c/lp/aggregate/ad/info`,
        method: 'post',
        data: payload,
        params: query,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return result.data.data;
}

/* 获取pageId数据 */
export async function getPageJSON(pageId: string) {
    const result = await axios.request<{ data: { components: ComponentJson[] } }>({
        url: `/rest/tk/lp/page/getPage`,
        method: 'get',
        params: {
            pageId,
        },
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return result.data.data;
}

/** Form Controller  表单数据提交v2 */
export async function submitV2UsingPOST(
    payload: { formSubmitParam: IFormSubmitParam },
    header?: any,
) {
    const data = {
        ...payload.formSubmitParam,
    };

    const result = await axios.request<model.IMessagestring>({
        url: `${getXianSuoDomain()}/rest/c/gemini/form/submitV2`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
            ...header,
        },
    });

    return result.data;
}
/** user-controller 获取用户信息 */
export async function infoUsingGET() {
    const result = await axios.request<model.IMessageksUserInfo>({
        url: `/rest/c/gemini/user/info`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    });

    return result.data;
}

/** 预约下载获取orderId*/
export async function getOrderId(data: model.IOrderParams) {
    const result = await axios.request<model.IOrderResponse>({
        url: `/rest/c/lp/app/getAppointmentOrderId`,
        method: 'post',
        data,
        headers: { 'Content-Type': 'application/json' },
    });

    return result.data;
}

export async function qywxFansCbMd5UsingPOST(payload: {
    callback: string;
    isMK?: boolean | string;
    followPlanId?: string;
    appVersion?: string;
    renderType?: string;
    componentType?: string;
    customerAcqId?: string;
    pageId?: string;
    cityName?: string;
    type?: number;
    wechatType?: number;
    formId?: string;
    formInfo?: SelectItem[];
    qywxConversionChannelType?: LaunchTypeEnum;
}) {
    let source = ax.CancelToken.source();
    setTimeout(() => {
        source.cancel();
    }, 5000);
    const result = await axios.request<model.IResponseQywxCallback>({
        url: `/rest/c/gemini/wechat/report-qywx-lp-page-callback`,
        method: 'post',
        data: payload,
        cancelToken: source.token,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return result.data;
}

/** 预约下载获取orderId*/
export async function reportNoFunctionIntroduction(data: {
    pageId: string;
    appId?: string;
    packageId?: string;
    failReason: string;
    callback: string;
}) {
    const result = await axios.request<model.IOrderResponse>({
        url: `/rest/c/lp/miss/sixElement/submit`,
        method: 'post',
        data: {
            mk: false,
            outPageId: data.pageId,
            appId: data.appId,
            packageId: data.packageId,
            callback: data.callback,
            failReason: data.failReason,
        },
        headers: { 'Content-Type': 'application/json' },
    });
    return result.data;
}

export const getABResult = async (data) => {
    const result = await axios.request({
        url: `/rest/c/lp/ab/info`,
        method: 'post',
        data: {
            ...data,
        },
        headers: { 'Content-Type': 'application/json' },
    });
    return result.data;
};

export const checkInWhiteList = async (pageId: string) => {
    const result = await axios.request({
        url: `/rest/c/lp/common/checkInWhiteList?pageId=${pageId}`,
        method: 'GET',
    });
    return result.data;
};

export async function fetchWechatGameStayABStatus() {
    const result = await axios.get<model.IMessageWechatAB>(
        `/rest/c/lp/config/getWechatGameStayPageAbTestParam`,
    );
    return result.data.data;
}

export async function fetchXiaomiMarketUri(data: {
    id: string; //跳转的具体应用包名
    senderPackageName: string; //应用跳转来源，即媒体应用包名，可用于后续定向回传下载状态
    detailStyle: string; //详情页交互形式 1：全屏
}) {
    const result = await axios.post(`/rest/c/lp/app/mimarket/deeplink`, { ...data });
    return result.data.data;
}

export async function fetchBatchOpenList() {
    const result = await axios.get(`/rest/c/lp/config/jumpBatchOpenList`);
    return result.data.data;
}
export async function fetchMarketDirectDeeplink(data: {
    id: string;
    senderPackageName: string;
    detailStyle: string;
    manufacture: string;
}) {
    const result = await axios.post(`/rest/c/lp/app/market/deeplink`, { ...data });
    return result.data.data;
}

export async function fetchAggregateMerchantInfo(pageIds: string[]) {
    const result = await axios.post(`/rest/c/lp/aggregate/ad/getCorporationInfos`, { pageIds });
    return result.data.data;
}

export async function fetchInteractionComment() {
    const result = await axios.post<model.IGetCommentResponse>(`/rest/c/lp/getComment`, {});
    return result.data.data;
}
export async function recordFormModalInfo(opened: number, neverOpen: number) {
    const result = await axios.post(`/rest/c/lp/form/popup/record`, { opened, neverOpen });
    return result.data.data;
}

export async function checkShowFormModal() {
    const result = await axios.post(`/rest/c/lp/form/popup/style`);
    return result.data.data;
}

export async function fetchMerchantInfo(accountIds: string[]) {
    const result = await axios.post(`/rest/c/lp/aggregate/ad/getCorporationInfoList`, {
        accountIds,
    });
    return result.data.data;
}

export async function setCountdownNum(params: { lpPageId: string; countdown: number }) {
    const result = await axios.post(`/rest/c/lp/setCountdown`, {
        ...params,
    });
    return result.data.data;
}

export async function getPageABBlackList(params: { lpPageId: string; funcKey: string[] }) {
    const result = await axios.post(`/rest/c/lp/whiteList`, {
        ...params,
    });
    return result.data;
}

export async function fetchShowFormNewStayModal(params: { lpPageId: string; type: number }) {
    const result = await axios.post(`/rest/c/lp/pageRetention`, {
        ...params,
    });
    return result.data.data;
}

export const getIndustryText = async (data: { lpPageId: string; type?: number }) => {
    const result = await axios.post<model.IMessageIndustryText>(`/rest/c/lp/getCopyWriting`, {
        ...data,
    });
    return result.data.data;
};

export const getCommitCount = async (data: { lpPageId: string; type?: number }) => {
    const result = await axios.post<model.IMessageCommitCount>(`/rest/c/lp/getPageShow`, {
        ...data,
    });
    return result.data.data;
};

export const sendRetentionCount = async (type?: number) => {
    const result = await axios.post<model.IMessageCommitCount>(`/rest/c/lp/incrRetentionCount`, {
        type,
    });
    return result.data.data;
};
