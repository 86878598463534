import FunctionIntroduction from '../FunctionIntroduction';
import PermissionInfo from '../PermissionInfo';
import PrivacyFroPlcMidPage from './PrivacyForPlcMidPage';

interface PrivacyForMidpageProps {
    showDownloadBtn: boolean;
    fontSize: number;
    textColor: string;
    btnColor: string;
    btnTextColor: string;
    appPrivacyUrl: string;
    permissions: [];
    functionIntroduction: string;
    alignType: string;
    type: 'plcMidPage' | 'midPage';
    appDeveloper: string;
    appVersion: string;
    platform: 'android' | 'ios';
}
export default function PrivacyForMidpage(props: PrivacyForMidpageProps) {
    const {
        functionIntroduction,
        textColor,
        btnColor,
        btnTextColor,
        permissions,
        showDownloadBtn,
        appPrivacyUrl,
        fontSize,
        alignType,
        type = 'midPage',
    } = props;
    return type === 'plcMidPage' ? (
        <PrivacyFroPlcMidPage {...props} />
    ) : (
        <div style={{ width: '100%', display: 'flex', justifyContent: alignType }}>
            <FunctionIntroduction
                desc={functionIntroduction}
                color={textColor}
                btnColor={btnColor}
                btnTextColor={btnTextColor}
                fontSize={fontSize}
            />
            <PermissionInfo
                permissions={permissions}
                color={textColor}
                showDownloadBtn={showDownloadBtn}
                fontSize={fontSize}
            />
            <span style={{ margin: '0 4px', color: textColor, fontSize: fontSize }}>|</span>
            <span
                style={{
                    color: textColor,
                    fontSize,
                }}
                onClick={() => {
                    window.open(appPrivacyUrl);
                }}
            >
                隐私政策
            </span>
        </div>
    );
}
