import { getAdBridge } from '../../bridge';
import { isDevelopment } from 'src/landingPage/common/utils/env';

// 智能中间页打点迁移
export const AdActionType = {
    AD_MIDDLE_LANDING_PAGE_LOADED: 911, // 智能下载中间页曝光
    AD_MIDDLE_LANDING_PAGE_IMPRESSION: 912, // 智能下载中间页曝光
    AD_MIDDLE_LANDING_PAGE_CLOSED: 913, //  智能下载中间页的关闭  -- 客户端埋点
    AD_ITEM_CLICK: 2, // 点击
    AD_ELEMENT_CLICK: 141, // 标识广告元素的点击
    AD_ELEMENT_IMPRESSION: 140, // 智能中间页转化按钮元素的曝光
};

export enum LandingPageType {
    NORMAL_LANDING_PAGE = 1, // 普通落地页
}

export enum ItemClickType {
    LANDING_PAGE_ACTION_BAR_CLICK = 50,
}

export enum ElementType {
    /**
     * 用户隐私协议埋点需求
     * https://wiki.corp.kuaishou.com/pages/viewpage.action?pageId=625417281
     */
    ELEMENT_PRIVACY_BULLET_IMPRESSION = 72, // “关于此应用”的弹框展现时上报；
    ELEMENT_PRIVACY_BULLET_CLICK_CANCELLED = 74, // 点击“关于此应用”弹框关闭按钮时上报
    ELEMENT_PRIVACY_BULLET_CLICK_RETURNED = 75, // 点击隐私二级弹框返回按钮
    ELEMENT_PRIVACY_BULLET_CLICK = 73, // 点击隐私弹框Tab，同时上报场景参数
    ELEMENT_MIDDLE_PAGE_CLICK = 179, // 智能下载中间页图片点击
    ELEMENT_MIDDLE_PAGE_IMPRESSION = 257, // 智能中间页转换按钮曝光
}

export const sendLogByAdBridge = (actionType, params) => {
    if (isDevelopment) return;
    try {
        getAdBridge()?.log({
            actionType,
            payload: JSON.stringify(params),
        });
    } catch (e) {}
};

// 进入H5页面时打点
export const sendLoadLog = () => {
    const params = {
        client_h5_card_ext_data: {
            LandingPageType: LandingPageType.NORMAL_LANDING_PAGE,
        },
    };
    sendLogByAdBridge(AdActionType.AD_MIDDLE_LANDING_PAGE_LOADED, params);
};

// 曝光时打点,记录加载时长
export const sendImpressionLog = (duration) => {
    const params = {
        ClientParams: {
            landing_page_loaded_duration: duration,
        },
    };
    sendLogByAdBridge(AdActionType.AD_MIDDLE_LANDING_PAGE_IMPRESSION, params);
};

//  行为打点
export const sendClickLog = () => {
    const params = {
        itemClickType: ItemClickType.LANDING_PAGE_ACTION_BAR_CLICK,
    };
    sendLogByAdBridge(AdActionType.AD_ITEM_CLICK, params);
};

//  转换按钮的曝光
export const sendAdBtnShowLog = () => {
    const params = {
        elementType: ElementType.ELEMENT_MIDDLE_PAGE_IMPRESSION,
    };
    sendLogByAdBridge(AdActionType.AD_ELEMENT_IMPRESSION, params);
};

//  标识广告元素的点击
export const sendAdElementClickLog = () => {
    const params = {
        elementType: ElementType.ELEMENT_MIDDLE_PAGE_CLICK,
    };
    sendLogByAdBridge(AdActionType.AD_ELEMENT_CLICK, params);
};
