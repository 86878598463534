export const AnchorFormStyle = {
    titleStyle: {
        color: '#222222',
    },
    formStyle: {
        backgroundColor: '#fff',
        backgroundImage: '',
        backgroundSize: '100%',
    },
    inputStyle: {
        color: '#222222',
        backgroundColor: '#F8F8F8',
        borderColor: '#F8F8F8',
    },
    optionStyle: {
        color: '#222222',
        backgroundColor: '#F8F8F8',
        borderColor: '#F8F8F8',
    },
    optionActiveStyle: {
        color: '#FE3666', // 需要修改
        backgroundColor: '#FFEEF2',
        borderColor: 'rgba(254, 54, 102, 0.3)',
    },
    placeholderColor: '#C6C6C6',
};
