import { addListener } from '@yoda/bridge';
import { getAdBridge } from '../bridge';
import { trackBridgeError, trackBridgeSuccess } from '../adTrack/monitor';
import { preventWakeUpApp } from './preventWakeUpApp';
import { Toast } from '@ad/sharpui';
import { isInApp } from 'omega-shared/utils';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { DEVICE_TYPE, getDeviceType } from './getDeviceInfo';

// async function reportSentry(message: string) {
//     try {
//         getSentry()?.captureEvent({
//             timestamp: +new Date() / 1000,
//             message,
//             extra: {
//                 url: window.location.href,
//                 ua: window.navigator.userAgent,
//                 cookie: document.cookie,
//                 deviceInfo: await bridgeGetDeviceInfo(),
//             },
//         });
//     } catch (e) {
//         console.log(e);
//     }
// }

/**
 * 满足一定版本，会使用客户端的bridge判断调起是否成功
 * @param link 调起的链接
 * 重点注意！dp现在有白名单控制，在preventWakeUpApp()方法中，
 * 会读取rest/n/lp/config/out-link 接口的list 注册dp 白名单
 * 在dp白名单外的链接都会跳失败（并且不会报任何异常，且再用其他任何逻辑都跳不出去）
 * @param url 调起失败的链接
 * @param successCallBack
 * @param failCallBack
 */
export const callApp = async (params: {
    link: string;
    url?: string;
    successCallBack?: () => void;
    failCallBack?: () => void;
    linkValidMap?: Record<string, boolean>;
}) => {
    const { link, url, successCallBack, failCallBack, linkValidMap } = params;
    const callH5 = () => {
        // 唤起微信失败后会返回会多一次曝光
        // if (link.indexOf('weixin://')) {
        //     tractWeixinCallH5(link, url);
        // }
        if (linkValidMap && url && linkValidMap[url] === false) {
            // 链接不合法
            Toast.error('链接失效');
            return;
        }
        if (!url) {
            // 如果跳转应用失败且没有兜底链接，toast提示
            Toast.error('跳转应用失败');
            return;
        }
        window.setTimeout(() => {
            console.log('start callH5');
            // arr.length && console.log(22222, arr);
            url && (window.location.href = url);
        }, 100);
    };

    //由于目前鸿蒙未支持handleDeeplink bridge，所以鸿蒙系统时需要先跳过禁用其他链接的逻辑
    if (getDeviceType() !== DEVICE_TYPE.HARMONY) {
        await preventWakeUpApp();
    }

    if (linkValidMap && linkValidMap[link] === false) {
        // 链接不合法
        Toast.error('链接失效');
        return;
    }

    console.log('link---', link);

    const iframe = document.createElement('iframe');
    iframe.src = link;
    iframe.style.display = 'none';
    // const checkRes = await checkVersion({ appVersionLimit: '7.3.20', nebulaVersionLimit: '2.3.3' });
    // let deviceInfo = {
    //     data: {
    //         systemVersion: 'unknown',
    //     },
    // };
    // try {
    //     deviceInfo = ((await getBridge()?.getDeviceInfo({})) as any) as typeof deviceInfo;
    // } catch (err) {
    //     // 按钮组件跳淘宝兼容审核场景
    //     console.error('getDeviceInfo 调用失败', err);
    //     failCallBack && failCallBack();
    //     url && (window.location.href = url);
    //     return;
    // }
    let callbackFired = false;
    try {
        const adBridge = getAdBridge();
        console.log('adBridge', adBridge);
        const listenerSuccess = ({ result, ...rest }) => {
            trackBridgeSuccess('registerDeeplinkListener');
            console.log('registerDeeplinkListener success', result, rest);
            successCallBack && !callbackFired && successCallBack();
            callbackFired = true;
            if (result !== 1) {
                callH5();
            }
        };

        const listenerError = (err) => {
            console.log('registDeepLink err', err);
            trackBridgeError('registerDeeplinkListener', JSON.stringify(err));
            failCallBack && !callbackFired && failCallBack();
            callbackFired = true;
            callH5();
        };

        if (!isInApp) {
            bridge?.registerDeeplinkListener({
                success: listenerSuccess,
                error: listenerError,
            });
        } else {
            adBridge?.registerDeeplinkListener({
                success: listenerSuccess,
                error: listenerError,
            });

            if (adBridge === undefined) {
                console.log('adBridge为空');
                // reportSentry('adbridge 为空');
                failCallBack && !callbackFired && failCallBack();
            }
        }
    } catch (err) {
        console.log('registerDeeplinkListener catch error');
        console.error(err);
        trackBridgeError('registerDeeplinkListener', err);
        failCallBack?.();
        callH5();
    }

    window.setTimeout(() => {
        if (!callbackFired) {
            callbackFired = true;
            console.log('setTimeout deeplink回调未触发');
            // reportSentry('deeplink回调未触发');
            failCallBack?.();
            callH5();
        } else {
            successCallBack?.();
        }
    }, 2000);
    try {
        await addListener('native_leave', () => {
            if (!callbackFired) {
                callbackFired = true;
            }
        });
    } catch (e) {
        console.error('native_leave', e);
        trackBridgeError('native_leave', e);
        document.addEventListener('visibilitychange', function () {
            console.log('visibilitychange', document.hidden);
            if (document.hidden) {
                if (!callbackFired) {
                    callbackFired = true;
                }
            } else {
                console.log('The page is now visible.');
            }
        });
    }

    window.setTimeout(() => {
        document.body.appendChild(iframe);
        window.setTimeout(() => {
            document.body.removeChild(iframe);
        }, 200);
    }, 200);
};
