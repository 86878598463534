import { AppDownloadProps } from '../AppDownload';
import { AppDownloadState, LINK_TYPE } from '../redux';
import { AppInfo, fetchAppPackageCheck } from 'landingPage/common/api';
import { getMarketDirectLink, mapAppInfo, getDeepLinkType } from '../utils';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { isInApp } from '@/landingPage/common/utils/env';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { getAdBridge } from '@/landingPage/common/bridge';
import {
    deepLinkInvokeError,
    trackBridgeError,
    trackBridgeSuccess,
    trackDeepLinkInvoke,
} from 'landingPage/common/adTrack/monitor';
import { callApp } from 'landingPage/common/utils/callApp';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { checkAdBridge } from 'landingPage/common/utils/callQuickApp';
import { invoke } from '@yoda/bridge';

function webDownload(url?: string) {
    if (url?.length) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url ?? '';
        link.download = url ?? '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const androidDownload = async (
    params: {
        appInfo: AppDownloadProps['appInfo'];
        limitInfo: AppDownloadState['versionLimit'];
        appSecureInfo: AppInfo;
        linkType: AppDownloadProps['linkType'];
        deepLink: AppDownloadProps['deepLink'];
        marketDirect?: boolean;
    },
    // quickAppLink: AppDownloadProps['quickAppLink'],
) => {
    const { appInfo, appSecureInfo, linkType, deepLink, marketDirect } = params;
    let appMarketUri;
    //建站侧开启应用直投场景
    if (marketDirect) {
        appMarketUri = await getMarketDirectLink(appInfo?.packageName);
    }
    console.log('[download]', params, appMarketUri);
    async function callBridgeDownload() {
        // 调用bridge进行下载
        const marketUri = getUrlParam('marketUri') || appMarketUri;
        const params: any = {
            type: 1,
            ...mapAppInfo(appInfo),
        };
        console.log('[download icon]', params.icon);
        // 如果有marketUri，走应用直投逻辑
        if (marketUri) {
            params.marketUri = marketUri;
        }
        /**
         * 建站目前有包一致性校验的逻辑，具体字段为调用handleAdUrl的 params.apkMd5Info.unexpectedMd5Strategy
         * unexpectedMd5Strategy 为2时，端上校验包一致性不通过，会弹一个风险提示，不阻塞下载
         * unexpectedMd5Strategy 为0时，端上校验包一致性不通过，也不会弹提示，同样不阻塞下载
         * 部分客户有对包一致性不通过，但需要不弹提示，即白名单逻辑，需要获取该落地页的白名单状态并判断是否需要弹风险提示
         */
        if (appInfo?.appId && appSecureInfo.appName && linkType === LINK_TYPE.ANDROID) {
            let unexpectedMd5Strategy = 0;
            try {
                const res = await fetchAppPackageCheck({
                    callback: getUrlParam('callback'),
                    pageId: getUrlParam('pageId'),
                });
                if (res.executeCheck) {
                    unexpectedMd5Strategy = 2;
                }
            } catch (e) {}
            params.apkMd5Info = {
                apkMd5s: appSecureInfo.md5List,
                unexpectedMd5Strategy,
            };
        }
        console.log('unexpectedMd5Strategy: ', params?.apkMd5Info?.unexpectedMd5Strategy);

        /** 联盟 */
        if (!isInApp) {
            bridge?.handleAdUrl({ ...params });
            // callLianmengBridge({
            //     action: 'handleAdUrl',
            //     data: JSON.stringify(params),
            //     callback: '',
            // });
        } else {
            // 该方法默认支持下载后自动安装
            getAdBridge()
                ?.handleAdUrl(params)
                .catch((e) => {
                    throw e;
                });
        }
    }
    if (deepLink) {
        try {
            const handleInstalledApp = (installed: string) => {
                if (installed) {
                    trackBridgeSuccess(`[Android]hasInstalledApp${appInfo?.packageName ?? ''}`);
                    trackDeepLinkInvoke(getDeepLinkType(deepLink), 'APP_DOWNLOAD_DRAG');
                    callApp({
                        link: deepLink,
                        url: '',
                        successCallBack: () => {
                            dataTrack({
                                eventType: 'EVENT_DEEPLINK_INVOKED',
                                deeplink_app_type: getDeepLinkType(deepLink),
                                diyExtraInfo: JSON.stringify({
                                    renderType: 'APP_DOWNLOAD_DRAG',
                                }),
                            });
                        },
                        failCallBack: () => {
                            dataTrack({
                                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                                deeplink_app_type: getDeepLinkType(deepLink),
                                diyExtraInfo: JSON.stringify({
                                    renderType: 'APP_DOWNLOAD_DRAG',
                                }),
                            });
                            deepLinkInvokeError(getDeepLinkType(deepLink), 'APP_DOWNLOAD_DRAG');
                            // callBridgeDownload();
                        },
                    });
                    return;
                } else {
                    //正常未安装应用，不应该归属到trackBridgeError的报警中
                    // trackBridgeError(
                    //     '[Android]hasInstalledApp',
                    //     (appInfo?.packageName ?? '') + installed,
                    // );
                    console.log('android err', installed);
                }
            };
            if (!isInApp) {
                // window.callback_appInstalled = (res) => {
                //     let result: any = res;
                //     if (typeof res === 'string') {
                //         try {
                //             result = JSON.parse(res);
                //         } catch (e) {
                //             console.log('error', e);
                //         }
                //     }
                //     const { installed } = result;
                //     handleInstalledApp(installed);
                // };
                // callLianmengBridge({
                //     action: 'checkAppInstalled',
                //     data: JSON.stringify({ android: appInfo?.packageName ?? '' }),
                //     callback: 'callback_appInstalled',
                // });
                const { data } = await bridge.checkAppInstalled({
                    android: appInfo?.packageName ?? '',
                });

                handleInstalledApp(data?.isInstalled);
            } else {
                const { installed } = await invoke('tool.checkAppInstalled', {
                    android: appInfo?.packageName ?? '',
                });
                handleInstalledApp(installed);
            }
        } catch (err) {
            trackBridgeError(
                '[Android]hasInstalledApp',
                (appInfo?.packageName ?? '') + JSON.stringify(err),
            );
            console.log('android err', err);
        }
    }

    // 联盟支持handleAdUrl bridge调用，不再需要web下载；不校验版本，直接调用bridge(补充，联盟也需要判断是否存在adBridge)
    // if (lbpBizId === BizId.lianmeng) {
    //
    //     callBridgeDownload();
    // }
    const hasAdBridge = checkAdBridge();
    if (!hasAdBridge) {
        // 安卓非广告webview场景下，降级到普通下载
        webDownload(appInfo?.url);
        console.warn('未找到ks-ad-bridge,进行普通下载');
        return;
    }
    try {
        await callBridgeDownload();
    } catch (e) {
        try {
            if (isInApp) {
                await invoke('webview.setClipBoard', { text: appInfo?.url });
                trackBridgeSuccess('setClipBoard');
            } else {
                await bridge?.setClipBoard({ text: appInfo?.url || '' });
                trackBridgeSuccess('setClipBoard');
            }
        } catch (e) {
            trackBridgeError('setClipBoard', e);
        }

        try {
            await invoke('ui.showToast', {
                type: 'normal',
                text: '应用下载失败，请打开系统浏览器粘贴（已复制链接）应用链接下载。',
            });

            trackBridgeSuccess('showToast');
        } catch (e) {
            trackBridgeError('showToast', e);
        }
    }
};
