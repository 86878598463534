import { showAnchorFormIcon } from './showAnchorFormIcon';

/** 锚点到指定的表单
 * @param index 指定的表单index
 */
export const scrollToAnchorForm = (index: number) => {
    const node =
        document.querySelectorAll(`div[data-component-type^="${'XIANSUO_FORM_DRAG'}"]`)?.[index] ||
        document.querySelectorAll(`div[data-component-type^="${'XIANSUO_FORM'}"]`)?.[index];
    // const node = document.querySelectorAll(`.${formClass}`)[index];
    node?.scrollIntoView();

    showAnchorFormIcon({ targetNode: node });
};
