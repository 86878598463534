import React from 'react';
import dayjs from 'dayjs';
import { generate } from '@ant-design/colors';
import { hexToRgba } from 'landingPage/common/utils/color';
import { useTheme } from '@/landingPage/common/utils/useTheme';

export const useCountdownHook = (endTime: number) => {
    const { color: backgroundTheme = 'rgba(255,255,255,0)' } = useTheme({
        path: 'backgroundColor',
    });
    const [endTimeMom, setEndTimeMom] = React.useState(
        dayjs(endTime).diff(dayjs(), 'second') < 0 ? dayjs().add(30, 'minute') : dayjs(endTime),
    );

    const [now, setNow] = React.useState(dayjs());
    const daydiff = endTime ? endTimeMom.diff(now, 'd') : 0;
    const hourdiff = endTime ? endTimeMom.diff(now, 'hour') % 24 : 0;
    const minutediff = endTime ? endTimeMom.diff(now, 'minute') % 60 : 0;
    const seconddiff = endTime ? endTimeMom.diff(now, 'second') % 60 : 0;
    let colors: any[] = generate(backgroundTheme);
    // 透明背景色处理
    if (backgroundTheme.includes('rgba')) {
        const regRes = backgroundTheme.match(
            /rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(([0-9]*[.])?[0-9]+)\)/,
        );
        if (regRes?.length && regRes.length > 4) {
            const opacity = regRes[4];
            colors = colors.map((color) => {
                return hexToRgba(color, +opacity);
            });
        }
    }
    React.useEffect(() => {
        setNow(dayjs());
        setEndTimeMom(
            dayjs(endTime).diff(dayjs(), 'second') < 0
                ? dayjs().add(30, 'minute').add(100, 'millisecond')
                : dayjs(endTime),
        );
    }, [endTime]);

    React.useEffect(() => {
        if (!endTime) return;

        if (endTimeMom.diff(now, 'second') > 0) {
            window.setTimeout(() => {
                setNow(dayjs());
            }, 1000);
        }
    }, [endTime, endTimeMom, now, setNow]);
    return {
        daydiff,
        hourdiff,
        minutediff,
        seconddiff,
        colors,
        endTimeMom,
    };
};

export const getTimeDiffText = (diff: number): string[] => {
    let diffText = '00';
    if (diff > 100) {
        diffText = '99';
    } else if (diff >= 10) {
        diffText = String(diff);
    } else if (diff > 0) {
        diffText = `0${diff}`;
    } else {
        diffText = '00';
    }
    return [diffText[0], diffText[1]];
};
