import Modal from 'landingPage/common/components/Modal';
import { useRenderState } from 'omega-render';
import React, { useEffect, useState } from 'react';
import { createPortal, render, unmountComponentAtNode } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import styled from 'styled-components';

import { AppInfo } from 'landingPage/common/api';
import { isDevelopment } from 'landingPage/common/utils/env';
import { getWindow } from 'landingPage/common/utils/getWindow';
import { EnumPageType } from 'omega-shared/types';
import { LINK_TYPE } from './redux';

export const PortalHasRendered = () => {
    const { mount } = GetTopMountNode();

    return !!(mount && mount.childElementCount > 0);
};

export function GetTopMountNode() {
    const win = isDevelopment ? getWindow() : window;
    const mount = win.document.getElementById('security-node-mount');
    return { win, mount };
}

export const TopPortal = ({
    children,
}: React.PropsWithChildren<React.ReactNode | React.ReactNodeArray>) => {
    const { win, mount } = GetTopMountNode();
    useEffect(() => {
        if (!mount) {
            win.document.body.insertAdjacentHTML(
                'afterbegin',
                '<div id="security-node-mount"></div>',
            );
        }
    }, [mount, win.document.body]);

    return mount && createPortal(children, mount);
};

export const SecurityNoteBg = styled.div`
    background: #f5f7f9;
    padding: 8px 48px;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    color: #bfbfbf;
    line-height: 14px;
`;

function SecureNote({ styles, appInfo, win, linkType }: any) {
    const [show, setShow] = useState(false);
    const [showDesc, setShowDesc] = useState(false);
    const { state } = useRenderState((state) => {
        return {
            state: state.appDownload?.appInfo as AppInfo,
        };
    });

    return (
        <>
            <div className={styles.secureBg} onClick={(e) => e.stopPropagation()}>
                <div>
                    应用名称：
                    {state.appName}
                    {linkType !== LINK_TYPE.HARMONY && '｜版本：'}
                    {linkType !== LINK_TYPE.HARMONY && state.version}
                    ｜开发者：
                    {state.developerName}
                </div>
                <div>
                    {appInfo?.functionIntroduction ? (
                        <>
                            <span
                                onClick={() => {
                                    setShowDesc(true);
                                }}
                            >
                                功能介绍
                            </span>
                            <span style={{ margin: '0 2px' }}> | </span>
                        </>
                    ) : (
                        ''
                    )}
                    {linkType !== LINK_TYPE.HARMONY && (
                        <>
                            <span
                                onClick={() => {
                                    setShow(true);
                                }}
                            >
                                权限详情
                            </span>
                            ｜
                            <span
                                onClick={() => {
                                    window.open(appInfo.privacyUrl);
                                }}
                            >
                                隐私政策
                            </span>
                        </>
                    )}
                </div>
            </div>
            {show && (
                <Modal
                    parent={win.document.body}
                    onClose={() => setShow(false)}
                    style={{
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        alignItems: 'inherit',
                    }}
                >
                    <div className={styles.permissionDialog}>
                        <h1>权限管理</h1>
                        <p>该应用需要以下权限</p>
                        <div className={styles.scroll}>
                            {state.permissionList.length ? (
                                state.permissionList.map((p) => (
                                    <div key={p.permissionName}>{p.permissionDetail}</div>
                                ))
                            ) : (
                                <div className={styles.noPerm}>权限信息扫描中…</div>
                            )}
                        </div>
                        <div className={styles.dialogBottom} onClick={() => setShow(false)}>
                            我知道了
                        </div>
                    </div>
                </Modal>
            )}
            {showDesc && (
                <Modal
                    parent={win.document.body}
                    onClose={() => setShowDesc(false)}
                    style={{
                        justifyContent: 'flex-end',
                        flexDirection: 'column',
                        alignItems: 'inherit',
                    }}
                >
                    <div className={styles.permissionDialog}>
                        <h1>功能介绍</h1>
                        <div className={styles.scroll}>{appInfo?.functionIntroduction}</div>
                        <div className={styles.dialogBottom} onClick={() => setShowDesc(false)}>
                            我知道了
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

export function useSecureNote(
    styles: any,
    preview?: boolean,
    state?: any,
    linkType?: LINK_TYPE,
    hide?: boolean,
) {
    const { win, mount } = GetTopMountNode();

    console.log('-----------state', state);

    // 为了getInfo接口获取较慢时mount创建过晚导致应用信息加载失败
    const [createMount, setCreateMount] = React.useState(false);

    const pageJson = JSON.parse(window?.pageJsonString || '{}');

    useEffect(() => {
        // 智能中间页需与之前样式保持一致（已包含隐私内容），无需再重新添加
        if (
            [EnumPageType.智能中间页, EnumPageType.PLC中间页, EnumPageType.大白中间页].includes(
                pageJson.type,
            )
        )
            return;
        /**表单预约下载添加的下载组件，页面不展示五要素 */
        if (hide) return;
        const { win, mount } = GetTopMountNode();
        if (!!preview || linkType === LINK_TYPE.IOS || !state?.appDownload?.appInfo?.check) {
            return;
        }
        const store = createStore((s) => s, state);

        if (!mount) {
            win.document.body.insertAdjacentHTML(
                'afterbegin',
                '<div id="security-node-mount"></div>',
            );

            setCreateMount(true);
        } else {
            render(
                <Provider store={store}>
                    <SecureNote
                        styles={styles}
                        appInfo={state.appDownload?.appInfo}
                        win={win}
                        linkType={linkType}
                    />
                </Provider>,
                mount,
            );
            // try {
            //     win.scrollTo(0, 0);
            // } catch (e) {}
        }
        return () => {
            if (mount) {
                setTimeout(() => {
                    win.document.querySelectorAll(`div[data-component-type^="APP_DOWNLOAD"]`)
                        .length === 0 && unmountComponentAtNode(mount);
                }, 100);
            }
        };
    }, [mount, win, styles, preview, state, createMount]);

    useEffect(() => {
        // 智能中间页需与之前样式保持一致（已包含隐私内容），无需再重新添加
        if (
            [EnumPageType.智能中间页, EnumPageType.PLC中间页, EnumPageType.大白中间页].includes(
                pageJson.type,
            )
        )
            return;
        if (hide) return;
        if (!preview) {
            try {
                win.scrollTo(0, 0);
            } catch (e) {
                console.error(e);
            }
        }
    }, [preview, win]);
}
