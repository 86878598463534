import Modal from 'landingPage/common/components/Modal/Modal';
import { useRenderState } from 'omega-render';
import styles from './style.module.less';
import React, { useEffect } from 'react';
import { ReceiveInfo } from './components/RecieveInfo';
import { ProfitIconBigSize, ProfitIconSmallSize } from './components/ProfitIcon';
import { useCommonFormConfig } from '../XiansuoForm';
import { fetchXiansuoFormInfoInPageJson } from '@/landingPage/common/utils/fetchCompPropsInPageJson';
import {
    AnchorModalStyleProps,
    modalType,
    ProfitCardType,
    useXiansuoFormContainerActions,
} from '../store';
import { ClueForm } from '@ad/xiansuoSDK';
import { isSSR } from '@/landingPage/common/utils/env';
import { sendRetentionCount } from '@/landingPage/common/api';
import { AnchorFormStyle } from './utils';
import { handleAnchorForm } from '@/landingPage/common/handlePageMeta';
import { ComponentZIndex } from 'omega-shared/utils';

// 接收extra参数, 提交时控制显隐
interface AnchorModalProps {
    setShow: any;
    setShowAppointment: any;
}
/**
 * 锚点表单：进行锚点弹窗展示
 * 依据fetchXiansuoFormInfoInPageJson返回值
 * @returns
 */
let hasShowedAnchorModal = false; // 多表单情况下会有多锚点弹窗，限制只展示一次
export const AnchorFormModal = (props: AnchorModalProps) => {
    // 提交后Extra组件展示
    const { setShow, setShowAppointment } = props;

    // 锚点弹窗的开启与关闭
    const [showAnchorModal, setShowAnchorModal] = React.useState(false);

    // store返回是否展示锚点弹窗
    const { isShowAnchorModal, anchorModalStyle, anchorForm, hasCancelAnchorModal } =
        useRenderState((state) => {
            return {
                isShowAnchorModal: state.xiansuoForm?.isShowAnchorModal,
                anchorModalStyle: state.xiansuoForm?.anchorModalStyle as AnchorModalStyleProps,
                anchorForm: state.rolex.opt?.originalPageJson?.pageMeta?.anchorForm,
                hasCancelAnchorModal: state.xiansuoForm?.hasCancelAnchorModal,
            };
        });

    const actions = useXiansuoFormContainerActions();

    // 获取基础表单物料
    const { XiansuoFormInfo = {}, isOpenCount } = fetchXiansuoFormInfoInPageJson() as any;

    // 不勾选，或不满足普通表单要求，都不执行：return null
    if (
        !anchorForm?.checked ||
        Object.keys(XiansuoFormInfo).length === 0 ||
        !XiansuoFormInfo?.formOpt
    ) {
        return null;
    }

    useEffect(() => {
        // 是否展示锚点逻辑：无popup弹窗
        if (isShowAnchorModal !== undefined && !hasCancelAnchorModal && !hasShowedAnchorModal) {
            // 锚点弹窗逻辑：1. 接口返回允许展示
            if (isShowAnchorModal) {
                // 获取弹窗所需头图等物料
                actions.fetchFormModalData();
                // 展示锚点弹窗
                setShowAnchorModal(true);
                // 发送曝光函数：向后端发送锚点弹窗展示次数统计
                sendRetentionCount(modalType.anchorModal);
                hasShowedAnchorModal = true;
            }
            // 锚点定位
            else {
                // 不满足弹窗，执行锚点定位
                handleAnchorForm(anchorForm);
            }
        } else {
            setShowAnchorModal(false);
        }
    }, [isShowAnchorModal, hasCancelAnchorModal]);

    const {
        formOpt,
        formSetting,
        showLabel,
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle,
        mainTitleStyle,
        mainTitleRich,
        clueAccountId,
        showCustomPrivacyAgreement,
        needCache,
        formVersion,
        isAppointment,
    } = XiansuoFormInfo || {};

    const { config } = useCommonFormConfig({
        ...AnchorFormStyle,
        formOpt,
        formSetting,
        mainTitleStyle: {
            ...mainTitleStyle,
            color: '#222222',
        },
        edit: false,
        preview: true,
        showLabel,
        theme: '#FE3666',
        submitWrapperStyle,
        formItemWrapperStyle,
        submitStyle: {
            ...submitStyle,
            color: '#FFFFFF',
        },
        mainTitleRich,
        clueAccountId,
        handleSubmitSuccess: () => {
            // 关闭锚点弹窗
            setShowAnchorModal(false);
        },
        onSuccess: async (value) => {
            console.log('[onSuccess]', value);
            // 关闭锚点弹窗
            setShowAnchorModal(false);
            // 与表单保持一致
            if (isAppointment) {
                setShowAppointment(true);
            } else {
                setShow(true);
            }
        },
        onError: () => {
            // 关闭当前锚点弹窗
            setShowAnchorModal(false);
        },
        onSubmitClick: async () => {},
        isSSR,
        customSuccessPage: true,
        showCustomPrivacyAgreement,
        needCache,
        show: false,
        privacyTextColor: '#666666',
        formVersion,
        tierFormZIndex: ComponentZIndex.ArchorTierModalZIndex,
    });

    return (
        <>
            {showAnchorModal && (
                <Modal
                    style={{ padding: 0, zIndex: ComponentZIndex.XiansuoFormAnchorZIndex }}
                    parent={document.getElementById('preview-app') ?? document.body}
                    onClose={() => {
                        setShowAnchorModal(false);
                    }}
                >
                    <div className={styles.modalContainer}>
                        <div className={styles.containerTop}>
                            <div
                                className={
                                    !isOpenCount ? styles.topTitleHasImg : styles.topTitleNoImg
                                }
                            >
                                <div className={styles.topTitleText}>预约领取好礼</div>
                                {!isOpenCount && <ReceiveInfo />}
                            </div>
                            <img
                                className={styles.close}
                                src={
                                    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2024-11-12/1731400435663.eac8a6115915f946.png'
                                }
                                onClick={() => {
                                    setShowAnchorModal(false);
                                }}
                            />
                        </div>

                        {anchorModalStyle.profitCardType === ProfitCardType.smallSize && (
                            <div className={styles.containerProfitSmallSize}>
                                <ProfitIconSmallSize />
                            </div>
                        )}
                        {anchorModalStyle.profitCardType === ProfitCardType.bigSize && (
                            <div className={styles.containerProfitBigSize}>
                                <ProfitIconBigSize />
                            </div>
                        )}

                        <div className={styles.containerForm}>
                            {config && <ClueForm {...config} />}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
