/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-floating-promises */
import * as React from 'react';
import cs from 'classnames';
import { debounce } from 'lodash';
import { callApp } from 'landingPage/common/utils/callApp';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { PopupType, WechatActionSceneEnum } from '../WeiXin';
import styles from '../style.module.less';
import { copy, wechatDataTrack } from './commonFun';
import { sendFormSubmitAndEffectiveCustomerReport } from '../util';

// 多转化事件 优化目标
const multiConversion = getUrlParam('multiConversion') === 'true';

export interface CopyDialogProps {
    info?: string;
    wechatId?: string;
    appId?: string;
    onClose?: () => void;
    itemId?: string;
    packId?: string;
    // schemaRes?: { schema: string; message: string };
    // appletSwitchState?: {
    //     checked: boolean;
    //     disabled: boolean;
    // }; // 小程序开关的状态
    againstFlowPrefixSwitch?: boolean; // 是否分流
    againstFlowPrefix?: string; // 分流前缀（公众号名称）
    wechatStay?: boolean;
    needSendEffectiveAction?: boolean;
    renderType?: string;
}

function useLongPress(time: number, cb: () => void) {
    // let timer: number;
    const [timer, setTimer] = React.useState<number>();
    const touchStart = React.useCallback(() => {
        const timer = window.setTimeout(() => {
            cb();
        }, time);
        setTimer(timer);
    }, [cb, time]);
    const touchEnd = React.useCallback(() => {
        clearTimeout(timer);
    }, [timer]);
    return [touchStart, touchEnd];
}

export const LongPressDialog: React.FunctionComponent<CopyDialogProps> = (props) => {
    const {
        info,
        wechatId = '',
        onClose,
        itemId,
        appId,
        packId,
        // appletSwitchState,
        // schemaRes,
        againstFlowPrefixSwitch = false,
        againstFlowPrefix = '',
        wechatStay,
        needSendEffectiveAction,
        renderType,
    } = props;

    const [selected, setSelected] = React.useState(false);
    // const [miniAppChecked] = React.useState(
    //     !!(appletSwitchState?.checked && schemaRes?.schema.length),
    // );

    const processedWechatId = React.useMemo(() => {
        return againstFlowPrefixSwitch ? `#公众号：${againstFlowPrefix}/${wechatId}` : wechatId;
    }, [againstFlowPrefix, againstFlowPrefixSwitch, wechatId]);
    const longPressCb = React.useCallback(() => {
        // nodePitch(document.querySelector('.wechat-id'))
        setSelected(true);
        wechatId &&
            copy({
                text: processedWechatId,
                itemId,
                packId,
                wechatStay,
            });
    }, [wechatId, processedWechatId, itemId, packId]);
    React.useEffect(() => {
        wechatId &&
            dataTrack({
                eventType: 'EVENT_VIEW_WECHAT',
                wechatItemId: itemId,
                lp_page_type: 1,
                wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
                diyExtraInfo: JSON.stringify({
                    renderType: 'WEI_XIN_DRAG',
                    popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                }),
            });
    }, [itemId, wechatId]);
    const [onLongPressStart, onLongPressEnd] = useLongPress(500, longPressCb);
    const callWX = React.useCallback(
        debounce(
            () => {
                // 多转化事件优化目标，不打除表单转化的EVENT_FORM_SUBMIT点
                if (!multiConversion) {
                    // dataTrack({
                    //     eventType: 'EVENT_FORM_SUBMIT',
                    //     wechatItemId: itemId,
                    //     lp_page_type: 1,
                    //     WechatActionStatus: WechatActionSceneEnum.COPY_WECHAT_ID,
                    //     eventProps: {
                    //         actionName: 'EXTERNAL_ACTION904',
                    //     },
                    //     diyExtraInfo: JSON.stringify({
                    //         renderType: renderType || 'WEI_XIN_DRAG',
                    //         landing_page_infos: JSON.stringify({
                    //             page_module_type: getTransCompList(),
                    //         }),
                    //     }),
                    // });
                    sendFormSubmitAndEffectiveCustomerReport({
                        wechatActionStatus: WechatActionSceneEnum.COPY_WECHAT_ID,
                        wechatItemId: itemId,
                        enableSend: needSendEffectiveAction,
                        renderType: renderType,
                    });
                }

                callApp({
                    link: 'weixin://',
                    url: 'https://weixin.qq.com/cgi-bin/readtemplate?t=download/ver_voice',
                    successCallBack: () => {
                        wechatDataTrack({
                            itemId,
                            invokeSuccess: true,
                            sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                            wechatId,
                            appId,
                            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                        });
                        onClose?.();
                    },
                    failCallBack: () => {
                        wechatDataTrack({
                            itemId,
                            invokeSuccess: false,
                            sence: WechatActionSceneEnum.COPY_WECHAT_ID,
                            wechatId,
                            appId,
                            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
                        });
                        onClose?.();
                    },
                });
            },
            300,
            { trailing: false, leading: true },
        ),
        [itemId, onClose],
    );
    const onClick = React.useCallback(() => {
        setSelected(false);
        callWX();
    }, [callWX]);

    return (
        <div className={cs([styles.dialog, styles.pressDialog])}>
            <div className={styles.exit} data-target="wechat-close" onClick={onClose} />
            {/* <Checked className={styles.icon}></Checked> */}
            <div className={styles.text}>{info}</div>
            <div
                className={styles.account}
                onTouchStart={onLongPressStart}
                onTouchEnd={onLongPressEnd}
            >
                <span className={styles.label}>微信号：</span>
                <span
                    className={cs(
                        { 'wechat-id': true, [styles.selected]: selected },
                        styles.textHidden,
                    )}
                >
                    {processedWechatId || '请选择微信'}
                </span>
            </div>
            {selected && (
                <span className={styles.toolTip}>
                    <span className={styles.toolTipContent}>
                        <span className={styles.toolTipArrow}>
                            <span className={styles.toolTipArrowContent} />
                        </span>
                        <span className={styles.toolTipText} onClick={onClick}>
                            <span>复制并前往微信</span>{' '}
                        </span>
                    </span>
                </span>
            )}
            <div className={styles.info}>长按复制微信号，去微信搜索添加</div>
            {/* <div className={styles.close} onClick={callWX}>
                去添加
            </div> */}
        </div>
    );
};
