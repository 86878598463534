import { useEffect, useState } from 'react';
import styles from './style.module.less';
import { getRandomIconList, iconList } from '../../../newFormStayModal/utils';

// 利益大卡
export const ProfitIconBigSize = () => {
    const [randomImgList, setRandomImgList] = useState<typeof iconList>([]);

    useEffect(() => {
        setRandomImgList(getRandomIconList(4));
    }, []);

    return (
        <div className={styles.randomIconList}>
            {randomImgList.map((item, index) => {
                return (
                    <div key={index} className={styles.iconListBigSize}>
                        <img src={item.icon} className={styles.iconBigSize} />
                        <div className={styles.iconTextBigSize}>{item.title}</div>
                    </div>
                );
            })}
        </div>
    );
};

// 利益小卡
export const ProfitIconSmallSize = () => {
    const [randomImgList, setRandomImgList] = useState<typeof iconList>([]);

    useEffect(() => {
        setRandomImgList(getRandomIconList(3));
    }, []);

    return (
        <div className={styles.randomIconList}>
            {randomImgList.map((item, index) => {
                return (
                    <div key={index} className={styles.iconListSmallSize}>
                        <img src={item.icon} className={styles.iconSmallSize} />
                        <div className={styles.iconTextSmallSize}>{item.title}</div>
                    </div>
                );
            })}
        </div>
    );
};
