/* eslint-disable @typescript-eslint/no-floating-promises */
import './IntersectionObserver';
import { subscribe } from 'subscribe-ui-event';
import { addListener } from '@yoda/bridge';
import { sendTraceLog } from 'src/utils/adMonitor';
import { getUrlParam } from '../../utils/getUrlParam';

import {
    trackBridgeError,
    trackBridgeSuccess,
    trackEvent,
    trackStayDuration,
    trackStayDurationFMP,
    trackUnusualPage,
    // trackIsPre,
    // trackIsPreAfter,
    trackEventByFront,
    // trackPageId,
} from '../monitor';
import { sendData, getCallbackInfo } from './sendData';
import addClickEvent from './click';
// eslint-disable-next-line import/no-cycle
import { isTrackedEl, isEnableTrack, getTrackDataFromEl } from './dom';
import { trackPVLeave, trackClick } from './zt';
import {sendABCountDownNum} from '../../../components/XiansuoFormDrag/utils'

export const EVENT_TYPE = {
    impression: 'EVENT_PAGE_IMPRESSION',
    formSubmit: 'EVENT_FORM_SUBMIT',
    pageLoad: 'EVENT_PAGE_LOAD',
    close: 'EVENT_PAGE_CLOSE',
    mapClick: 'EVENT_PAGE_COMPONENT_CLICK',
    buttonClick: 'EVENT_BUTTON_CLICK',
};

/**
 *  首屏时间默认为加载时间
 *  loadTime = performance.timing.connectStart - document.body.children[0] 时间
 */

let proportion = 0;
const pageCommonParam = {
    lp_page_type: 1,
};

const callBcakInfo = getCallbackInfo();
export function pageViewedProportion() {
    const viewPortHeight = document.documentElement.clientHeight;
    let hasScrolled = false;

    subscribe('scroll', () => {
        hasScrolled = true;
        const height =
            (document.documentElement.scrollTop || document.body.scrollTop) + viewPortHeight;

        const maxHeight = document.body.offsetHeight;

        const proportionNow = (height / maxHeight).toFixed(4);
        proportion = proportion > proportionNow ? proportion : proportionNow;

        // isDebugMode() &&
    });

    if (!document.body) {
        return null;
    }

    // 当前的 body 高度 < 视口高则返回 100%
    if (viewPortHeight >= document.body.offsetHeight) {
        proportion = 1;
    }

    // 没有发生滚动事件的话浏览比例
    if (!hasScrolled) {
        proportion = (viewPortHeight / document.body.offsetHeight).toFixed(4);
    }
}

/* 
    老的曝光事件，新的已迁移到html的inline-script和fmp口径一致
    现在只包含pageViewedProportion和pageLoad的计算逻辑
*/
export function impressionTrack() {
    // trackEnv();
    trackUnusualPage();
    // trackPageId();
    const startTime = performance.timing.connectStart;
    // 查找 ksad-track 如果有 Intersection Observer, 如果没有报错
    const el = document.body.children[0];

    if (!el) {
        throw new Error('no element is tracked');
    }

    pageViewedProportion();

    // 曝光打点 (已迁移到inline-script)
    // eslint-disable-next-line
    
    /** 这部分还需要吗？ */
    // if (!window?.IMPRESSION_TRACKED) {
    //     const params = { ...pageCommonParam, eventType: EVENT_TYPE.impression };
    //     sendData(params);
    // }

    const io = new IntersectionObserver(() => {
        // 发送埋点
        // 停止回调
        const endTime = new Date().getTime();
        const pageLoadDurationMs = endTime - startTime;

        const params = {
            ...pageCommonParam,
            eventType: EVENT_TYPE.pageLoad,
            pageLoadDurationMs,
        };
        sendData(params);
        trackEvent(EVENT_TYPE.pageLoad);
        io.unobserve(el);
        io.disconnect();
    });
    io.observe(el);
}

const closeTrackHandler = () => {
    const stayDuration = new Date().getTime() - performance.timing.connectStart;
    const fmp = window?.performance?.getEntriesByName('universalFmp')?.[0]?.startTime;

    const params = {
        ...pageCommonParam,
        pageViewedProportion: proportion >= 1 ? 1 : proportion,
        eventType: EVENT_TYPE.close,
        pageStayDurationMs: stayDuration,
    };
    //离开页面时发送剩余的倒计时时间给后端
    sendABCountDownNum()
    sendData(params, 'POST');
    trackStayDuration(stayDuration);
    trackStayDurationFMP(stayDuration - fmp);
    // trackPVLeave
    trackPVLeave();
};

export function mpReport() {
    return {
        ...pageCommonParam,
        pageViewedProportion: proportion >= 1 ? 1 : proportion,
        eventType: EVENT_TYPE.close,
        pageStayDurationMs: performance.timing.connectStart,
        lpPageId: getUrlParam('pageId'),
        clientTimestamp: new Date().getTime(),
    };
}

export async function leaveTrack() {
    window.closeTrackHandler = closeTrackHandler;
    try {
        await addListener('native_leave', closeTrackHandler);
        trackBridgeSuccess('native_leave');
    } catch (e) {
        trackBridgeError('native_leave', e);
        sendTraceLog({
            message: `${JSON.stringify(e)} ,native_leave_error`,
        });
    }
}

export const observeTrackElTrack = function () {
    addClickEvent(document.body, (event) => {
        const { target } = event;
        if (isEnableTrack(target)) {
            sendData(getTrackDataFromEl(target));
        }
    });
};

// export const observeTrackElTrack = function () {
//   document.addEventListener("DOMContentLoaded", () => {
//     addClickEvent(document.body, event => {
//       const {target} = event
//       if (isEnableTrack(target)) {
//         sendData(getTrackDataFromEl(target))
//       }
//     })
//   })
// }

export function elTrack(el) {
    if (isTrackedEl(el)) {
        sendData(getTrackDataFromEl(el));
    } else {
        throw new Error('element is not be tracked');
    }
}

export function dataTrack(params) {
    sendData(params);
    !callBcakInfo?.length && trackEventByFront(params?.eventType);
    trackEvent(params?.eventType);
    // 打点事件备份投递到weblog
    trackClick(params?.eventType);
}
