/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { useRef } from 'react';
import { componentsForEach, useComContext, useRenderState } from 'omega-render';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import cs from 'classnames';
import { invoke } from '@yoda/bridge';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { checkVersion } from 'landingPage/common/utils/compareVersion';
import { isBeta, isInApp } from 'landingPage/common/utils/env';
import { trackerAd } from 'landingPage/common/utils/trackAd';
import { getIm, importBridge } from 'landingPage/common/bridge';
import qs from 'query-string';
import {
    trackBridgeError,
    trackBridgeSuccess,
    trackCustomerClick,
    trackGetCustomerURLError,
    trackIMCost,
} from 'landingPage/common/adTrack/monitor';
import { getPrivateLetterInfo } from 'landingPage/services/apis';
import { getStoreState } from '../Store/utils/locStore';
import Button, { ButtonProps } from '../ButtonDrag/Button';
import { Div } from '../Blank/Blank';
import { trackState } from './leaveTrack';
import { ReactComponent as CommonIcon } from './consult.svg';
// import { ReactComponent as RightBottomIcon } from './right-icon.svg';
import styles from './style.module.less';
import { BtnType } from '../WechatGame/WechatGame';
import { BottomRichStyle } from './BottomRichStyle';
import ConsultIframeModal from './Modal';
import { CustomerState, useCustomerServiceActions } from './redux';
import { Toast } from '@ad/sharpui';
import { getTransCompList } from 'landingPage/common/impression';
import { getUrlParams } from 'landingPage/common/adTrack/util/env';
import { getFuWuHaoInfoInCustom } from 'landingPage/common/utils/getFuWuHaoInfo';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import {
    fetchConsultPageUrl,
    getImApiUrl,
    isIos,
    sendConsultInfoTrack,
    replacePrivateLetterMacros,
} from './utils';

export enum IM_TYPE {
    KUAI = 1,
    OTHER = 2,
    PRIVATE_LETTER = 3,
}

/**
 * 咨询页打开样式
 */
export enum OpenMode {
    /** 新开页面 */
    New = 'new',
    /** 当前页面打开 */
    Modal = 'modal',
}

export enum EStyleType {
    /** 底部悬浮丰富样式 */
    BOTTOM_RICH = 1,
}

export enum AVATAR_TYPE {
    DEFAULT,
    CUSTOM,
}

const H5_URL_PARAMS = {
    //落地页id
    lpPageId: 'pageId',
    //广告callback信息（包含创意、单元、计划id等）
    callback: 'callback',
    //是否是多转化目标（dsp设置）
    multiConversion: 'multiConversion',
    //定向城市id（dmp下发）
    areaCode: 'cityName',
};
export interface CustomerServiceProps
    extends Pick<ButtonProps, 'buttonStyle' | 'backgroundColor' | 'text' | 'dynamicStatus'> {
    position?: 'bottom' | 'base' | 'rightBottom' | 'bottomBlank';
    url?: string;
    /** 咨询页ID */
    funcId?: string;
    backgroundFillMode?: boolean;
    /** 右侧悬浮的样式 */
    rightBottomTextStyle?: React.CSSProperties;
    /** 右侧按钮背景色 */
    rightBottomBackgroundColor?: string;
    /** 右侧图片颜色 */
    rightBottomIconColor?: string;
    rightBottomButtonStyle?: React.CSSProperties;
    /***  样式类型，目前只有底部悬浮丰富样式有这个只 */
    styleType?: EStyleType;
    /** 底部悬浮丰富样式的配置项 */
    bottomRichConfig?: {
        avatarType: AVATAR_TYPE;
        avatarAnimation: boolean;
        avatarUrl: string;
        title: string;
        questions: string[];
    };
    /** 按钮和底部悬浮的icon颜色 */
    iconColor?: string;
    /** 客服id，是美洽、53等 */
    appId?: number;
    /** 客服类型 * */
    csType?: IM_TYPE;
    /** 私信uid */
    uid?: number;
    /** 私信deeplink */
    deepLink?: string;
    index?: number;
    btnType?: BtnType; // 新样式新增属性
    radiusStyle?: string; // 圆角类型
    openMode?: OpenMode; // 新开页面还是当前页面打开
    /** 在当前页面停留时长，自动打开咨询页的时间，单位ms */
    durationToOpen?: number;
    btnSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    sizeType?: 'normal' | 'large';
    iconSize?: number;
    themeConfig?: any;
}

/**
 * 打开咨询页工具方法。
 * 需要携带lpPageId，callback等参数给私信页/咨询页
 * 下游依赖这些参数向快聊、crm留资
 *
 * @param url 要跳转的URL。
 * @param openMode 打开页面的方式。默认为`OpenMode.Blank`。
 * @returns 如果打开方式为`OpenMode.Modal`，则返回一个Promise resolve为H5咨询页地址，否则返回undefined。
 */
export const openServiceUrl = async (params: {
    comType: string;
    url?: string;
    funcId?: string;
    csType?: IM_TYPE;
    openMode?: OpenMode;
    // 私信快链
    privateDeepLink?: string;
    // 三方咨询页新链接开关，打开时根据端环境判断跳转dp还是h5，关闭时保留原链接逻辑
    linkSwitch?: boolean;
    openH5?: boolean;
    // 是否自动跳转，否则返回链接
    auto?: boolean;
    extraUrlParams?: Record<string, string | number>;
}) => {
    const {
        comType,
        url,
        funcId,
        csType,
        extraUrlParams = {},
        openMode = OpenMode.New,
        privateDeepLink,
        auto = true,
        openH5,
        linkSwitch,
    } = params;

    let realUrlResponse = '';
    if (csType !== IM_TYPE.PRIVATE_LETTER && !url) {
        return;
    }
    trackState.clicked = true;
    const { dataTrack } = trackerAd;
    const storeId = getStoreState<'storeSelected'>('storeSelected')?.id || '';
    dataTrack({
        eventType: 'EVENT_CUSTOMER_SERVICE_CLICK',
        // consultationPageId: funcId,
        consultationPageId: getUrlParam('cprId', url) || funcId, // 由于快聊排期问题，后端临时调整方案：更新改为新增，所以需要前端配合更改上报字段
        diyExtraInfo: JSON.stringify({
            storeId,
            renderType: 'CUSTOMER_SERVICE_DRAG',
            consultationPageId: funcId,
            landing_page_infos: JSON.stringify({
                page_module_type: getTransCompList(),
            }),
        }),
    });
    if (csType === IM_TYPE.PRIVATE_LETTER || (url || '').startsWith('kwai')) {
        const targetDeepLink = csType === IM_TYPE.PRIVATE_LETTER ? privateDeepLink : url;
        if (!targetDeepLink) return;
        //对于私信页/切私信的快聊咨询页 进行宏参数替换，携带callback，lpPageId等信息
        let formatted = replacePrivateLetterMacros(targetDeepLink);
        if (getUrlParams('lpFromChannel') === '1') {
            formatted = appendFuWuHaoInfo(formatted);
        }

        // TODO 上线前删除测试代码
        // fetch('https://joe-code.staging.kuaishou.com/messages', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         content: 'H5跳转deepLink:' + formatted,
        //     }),
        //     credentials: 'omit',
        // });

        sendConsultInfoTrack(formatted, csType);
        const a = window.document.createElement('a');
        a.href = formatted as string;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return;
    }
    // const [callback, pageId, multiConversion, cityName] = getUrlParam([
    //     'callback',
    //     'pageId',
    //     'multiConversion',
    //     'cityName',
    // ]);

    // u.searchParams.append('lpPageId', pageId);
    // u.searchParams.append('callback', callback);
    // u.searchParams.append('multiConversion', multiConversion);

    //对于h5咨询页，站外h5咨询页 进行宏参数替换，携带callback，lpPageId等信息

    const [host, urlParams] = url?.split('?') || [];
    const h5UrlParams = qs.parse(urlParams);

    const h5UrlParamsKeys = Object.keys(H5_URL_PARAMS);
    //拼接从建站url上获取的参数
    h5UrlParamsKeys?.forEach((key) => {
        h5UrlParams[key] = getUrlParam(H5_URL_PARAMS[key]);
    });
    h5UrlParams.storeId = storeId;
    h5UrlParams.viewComponentType = comType;
    h5UrlParams.source = 'landingPage';

    const u = new URL(`${host}?${qs.stringify({ ...h5UrlParams })}`);

    // let newUrl = `${url}${
    //     (url as string).includes('?') ? '&' : '?'
    // }lpPageId=${pageId}&callback=${callback}&multiConversion=${multiConversion}&storeId=${storeId}&areaCode=${cityName}&viewComponentType=${comType}&source=landingPage`;

    // const u = new URL(newUrl);

    const extraUrlParamsKeys = Object.keys(extraUrlParams || {});
    if (extraUrlParamsKeys.length > 0) {
        extraUrlParamsKeys.forEach((key) => {
            u.searchParams.set(key, `${extraUrlParams[key]}`);
        });
    }
    if (isBeta) {
        u.host = 'adim-beta.corp.kuaishou.com';
    }
    const checkRes = await checkVersion({ appVersionLimit: '8.2.40' });
    console.log('import Bridge start.....');
    await importBridge();
    console.log('import Bridge end.....');
    const getImUrl = getIm();
    const startTime = Date.now();
    // 客户端开发沟通问题
    // 11.9.30版本以下，需要把dataTrack字段放进extraInfo中，否则放进subbizContext字段中
    const dataTrackInExtraInfo = !Boolean(await checkVersion({ appVersionLimit: '11.9.30' }));
    try {
        let realUrl = await getImUrl?.({
            h5Url: u.href,
            imURL: getImApiUrl() as any,
            needH5Link:
                linkSwitch ||
                openH5 ||
                !(csType === IM_TYPE.KUAI && checkRes) ||
                openMode === OpenMode.Modal,
            dataTrackInExtraInfo,
            callbackExtraInfo: getFuWuHaoInfoInCustom(),
        });
        console.log('[getImUrl realUrl]', realUrl);
        // realUrl =
        //     'http://localhost:3001/newChat/MTQwMTAwMDA3MDMzOTU2?layoutType=4&type=jianzhan&cprId=553968543743185328&lpPageId=508277735738464636&callback=1&multiConversion=&storeId=&areaCode=&viewComponentType=CUSTOMER_SERVICE_DRAG&source=landingPage&';
        /** 之前获取失败，没有提示，会静默失败，这次加个提示 */
        if (!realUrl) {
            try {
                trackGetCustomerURLError({
                    pageId: getUrlParam('pageId'),
                    error: 'no realUrl',
                    openMode,
                });
            } catch (e) {
                console.error(e);
            }
            Toast.error('获取客服链接失败');
            return;
        }
        /** 当前页面，浮层打开，直接返回地址给后续 */
        if ((openMode === OpenMode.Modal && !isIos) || !auto) {
            realUrlResponse = realUrl;
            return realUrl;
        }
        // setModal
        trackIMCost(Date.now() - startTime);
        // 走scheme（native）
        if (realUrl.startsWith('kwai')) {
            sendConsultInfoTrack(realUrl, csType);
            const a = window.document.createElement('a');
            a.href = realUrl;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            // H5场景
            let h5RealUrl = realUrl;
            if (getUrlParams('lpFromChannel') === '1') {
                const fuwuhaoInfo = getFuWuHaoInfoInCustom();
                h5RealUrl += `&extraInfo=${encodeURIComponent(JSON.stringify(fuwuhaoInfo))}`;
            }
            h5RealUrl += '&hyId=commercial_im_h5';

            sendConsultInfoTrack(h5RealUrl, csType);
            openNewPageFromUrl(h5RealUrl);
        }
    } catch (err) {
        console.error('CustomeServuce::::', err);
        trackIMCost(Date.now() - startTime);
        try {
            trackGetCustomerURLError({
                pageId: getUrlParam('pageId'),
                error: String(err),
                openMode,
            });
        } catch (e) {
            console.error(e);
        }
    }
    if (openMode === OpenMode.Modal) {
        return realUrlResponse;
    }
};

/**
 * 咨询组件。
 * @param props 组件属性。
 * @returns 渲染的组件。
 */
const CustomerService: React.FunctionComponent<CustomerServiceProps> = (props) => {
    const {
        url,
        uid,
        rightBottomTextStyle,
        rightBottomBackgroundColor,
        rightBottomIconColor,
        rightBottomButtonStyle,
        buttonStyle,
        backgroundColor,
        text,
        children,
        iconColor,
        funcId,
        csType,
        index,
        dynamicStatus,
        position: positionProps,
        iconSize,
        styleType,
        openMode,
        durationToOpen,
    } = props;
    const { color: buttonBackgroundColor } = useTheme({
        path: 'buttonStyle.backgroundColor',
    });
    const { color: buttonColor } = useTheme({
        path: 'buttonStyle.color',
    });
    const [consultPageUrl, setConsultPageUrl] = React.useState(url);
    const { id, magic, edit } = useComContext();

    const components = useRenderState((state) => {
        return state.rolex.components;
    });
    /** 是否是第一个咨询组件，当有多个咨询组件的时候，使用浮窗打开咨询页的时候，复用第一个组件的Modal */
    const [isFirstCSComp, setIsFirstCSComp] = React.useState(false);
    const actions = useCustomerServiceActions();
    const { customerState } = useRenderState((state) => {
        return { customerState: state.customerService as CustomerState };
    });
    const [openH5, setOpenH5] = React.useState(false);
    React.useEffect(() => {
        const customerServiceIds = [] as string[];
        componentsForEach(components, (component) => {
            if (component.type === 'CUSTOMER_SERVICE_DRAG') {
                customerServiceIds.push(component.id);
            }
        });
        if (customerServiceIds[0] === id) {
            setIsFirstCSComp(true);
        }
    }, [components]);

    const [privateLetterDeepLink, setPrivateLetterDeepLink] = React.useState<string>();
    const [linkSwitch, setLinkSwitch] = React.useState<boolean>(false);

    const getPrivateLetterDeepLink = async () => {
        const res = await getPrivateLetterInfo({
            userId: uid as number,
        });
        if (isInApp) {
            return res?.data?.deepLink;
        } else {
            return res?.data?.h5Url;
        }
    };
    React.useEffect(() => {
        if (csType === IM_TYPE.PRIVATE_LETTER && uid) {
            getPrivateLetterDeepLink().then((res) => {
                setPrivateLetterDeepLink(res);
            });
        }
    }, [csType]);
    // 由于咨询页支持修改，修改后咨询页的页面url也会改变，所以在c端实时读取咨询页的url
    // 考虑到用户体验问题，获取url的操作前置而不是点击的时候请求

    React.useEffect(() => {
        if (!funcId) return;
        fetchConsultPageUrl(funcId).then((res) => {
            const { switchStatus, consultPageUrl, isH5Url } = res;
            setLinkSwitch(switchStatus);
            setConsultPageUrl(consultPageUrl);
            setOpenH5(isH5Url);
        });
    }, [funcId, url]);
    const ref = useRef<HTMLDivElement>(null);
    const position = positionProps ?? 'base';
    const onClick = React.useCallback(
        async (params?: { initialInputValue?: string; auto?: boolean }) => {
            try {
                trackCustomerClick({
                    pageId: getUrlParam('pageId'),
                    openMode,
                    auto: params?.auto,
                });
            } catch (e) {
                console.error(e);
            }

            const realUrl = await openServiceUrl({
                comType: 'CUSTOMER_SERVICE_DRAG',
                url: consultPageUrl,
                funcId,
                csType,
                openMode,
                openH5,
                privateDeepLink: privateLetterDeepLink || props.deepLink,
                linkSwitch,
                extraUrlParams: {
                    jumpSource: 0,
                },
            });

            /** 浮层弹窗打开,ios由于跨域无法携带cookie，暂时不兼容 */
            if (openMode === OpenMode.Modal && realUrl && !isIos) {
                const target = qs.stringifyUrl({
                    url: realUrl,
                    query: {
                        initialInputValue: params?.initialInputValue,
                    },
                });
                actions.openCustomerServiceModal();
                actions.setCurrentConsultUrl(target);
            }
        },
        [funcId, csType, consultPageUrl, privateLetterDeepLink, openH5, linkSwitch],
    );
    React.useEffect(() => {
        /**
         *   第一个咨询组件，且是浮层打开，且有设置自动弹出
         *  如果是编辑态，不自动弹出
         */
        if (isFirstCSComp && openMode === OpenMode.Modal && durationToOpen && !isIos) {
            // actions.setModalUrl(consultPageUrl)
            const timeId = setTimeout(() => {
                if (!edit && !customerState.currentUrl) {
                    onClick({ auto: true });
                }
            }, durationToOpen);
            return () => {
                clearTimeout(timeId);
            };
        }
    }, [onClick, isFirstCSComp]);
    const [width, setWidth] = React.useState(
        ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree.width,
    );
    React.useEffect(() => {
        setWidth(ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree.width);
    }, [magic?.globalDragFree.width]);
    // const [modalVisible, setModalVisible] = React.useState(false);
    if (position === 'rightBottom') {
        return (
            <>
                <Div className={styles.rightBottom} onClick={() => onClick()}>
                    {index === 1 ? (
                        <>
                            {dynamicStatus ? (
                                <div
                                    className={styles.customGif}
                                    style={{ width: width - 12, height: width - 12 }}
                                />
                            ) : (
                                <div
                                    className={styles.customGifStatic}
                                    style={{ width: width - 16, height: width - 24 }}
                                />
                            )}
                        </>
                    ) : (
                        <div
                            // ref={ref}
                            className={cs({
                                [styles.rightIcon]: true,
                            })}
                            style={{
                                // rightBottomButton相关属性为了兼容旧编辑器，新版编辑器不会修改该属性
                                ...buttonStyle,
                                backgroundColor:
                                    rightBottomBackgroundColor ?? buttonBackgroundColor,
                                backgroundImage: buttonStyle?.backgroundImage,
                                ...rightBottomButtonStyle,
                                height: width - 16,
                                width: width - 16,
                            }}
                        >
                            <CommonIcon
                                fill={rightBottomIconColor ?? iconColor}
                                className={cs({
                                    [styles.dynamicIcon]: dynamicStatus === 1,
                                })}
                                style={{
                                    width: 0.41 * (parseInt(width) - 16 || 73),
                                    height: 0.41 * (parseInt(width) - 16 || 73),
                                }}
                            />
                        </div>
                    )}

                    <p
                        style={{
                            backgroundSize: '100% 100%',
                            fontFamily: buttonStyle?.fontFamily,
                            fontSize: buttonStyle?.fontSize,
                            fontWeight: 'bold',
                            color: `${
                                index === 1
                                    ? '#FF4906'
                                    : (rightBottomTextStyle?.color ?? buttonColor)
                            }`,
                            marginTop: dynamicStatus && index === 1 ? 0 : 4,
                            textAlign: 'center',
                            lineHeight: '16px',
                            ...rightBottomTextStyle,
                        }}
                    >
                        {text.length > 4 ? `${text.slice(0, 4)}...` : text}
                    </p>
                </Div>
                {isFirstCSComp && <ConsultIframeModal />}
            </>
        );
    }
    // 底部丰富样式
    if (styleType === EStyleType.BOTTOM_RICH) {
        return (
            <>
                <BottomRichStyle {...props} onClick={onClick} />
                {isFirstCSComp && <ConsultIframeModal />}
            </>
        );
    }
    return (
        <>
            <Button
                bottom={position === 'bottomBlank'}
                icon={
                    <CommonIcon
                        className={cs(styles.icon)}
                        fill={buttonColor}
                        style={{ width: iconSize, height: iconSize }}
                    />
                }
                text={text}
                buttonStyle={buttonStyle}
                onClick={onClick}
                backgroundColor={backgroundColor}
                className={cs({ [styles.dynamic]: dynamicStatus === 1 })}
            >
                {children}
                {position === 'bottomBlank' && (
                    <>
                        <BlockPlaceholder pos="BOTTOM" preview={edit} />
                        <SafeAreaPlace />
                    </>
                )}
            </Button>
            {/* 浮层弹窗 */}
            {isFirstCSComp && <ConsultIframeModal />}
        </>
    );
};

export default CustomerService;
export async function openNewPageFromUrl(realUrl: string) {
    console.log('customer open new page start is in app::', isInApp);
    if (isInApp) {
        try {
            await invoke('tool.loadUrlOnNewPage', {
                url: realUrl,
                type: 'back',
            });
            trackBridgeSuccess('loadUrlOnNewPage');
        } catch (e) {
            trackBridgeError('loadUrlOnNewPage', e);
        }
    } else {
        const a = window.document.createElement('a');
        a.href = realUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}

/** 替换deeplink的宏参数 */
export const transformDeepLink = (
    url: string,
    macros: Record<string, string>,
    // urlType: URL_TYPE,
): string => {
    let res = url;
    // const urlObj = new URL(url);
    // const params = new URLSearchParams(urlObj.search);
    const macroKeyWhiteList = Object.keys(macros);

    // // 如果没有__LP_ID__字段，则手动在快聊后面拼接落地页id
    // if (!res.includes('__LP_ID__')) {
    //     const [host, urlParams] = res?.split('?') || [];
    //     let kwaiParams = qs.parse(urlParams);

    //     const subbizContext = JSON.parse((kwaiParams?.subbizContext as string) || '{}');
    //     kwaiParams = {
    //         ...kwaiParams,
    //         subbizContext: JSON.stringify({
    //             ...subbizContext,
    //             messageExtra: {
    //                 ...subbizContext?.messageExtra,
    //                 lpPageId: '__LP_ID__',
    //             },
    //         }),
    //     };

    //     res = `${host}?${qs.stringify({ ...kwaiParams })}`;
    // }

    const matchedMacros = res.match(/__.*?__/g);
    if (!matchedMacros?.length) return res;

    // Display the key/value pairs
    for (const matchedMacro of matchedMacros) {
        // console.log(`${key}, ${value}`);
        // const matchMacro = rawMacros.find((rawMacro) => (value as string).includes(rawMacro));
        const inWhiteList = macroKeyWhiteList.includes(matchedMacro);
        if (inWhiteList) {
            const macroValue = macros[matchedMacro];
            console.log('[macroValue]', matchedMacro, macroValue);
            if (macroValue) {
                res = res.replace(matchedMacro, macroValue);
            } else {
                console.error(`获取宏参数${macros[matchedMacro]}失败`);
                // trackGetMacroError(matchedMacro, getPageId(), urlType);
            }
        }
    }

    return res;
};

export const appendFuWuHaoInfo = (url: string): string => {
    let res = url;
    let fuWuHaoInfo = getFuWuHaoInfoInCustom();
    let urlParams = url.split('&');
    try {
        for (let item in urlParams) {
            if (urlParams[item].includes('extraInfo') || urlParams[item].includes('msgExtraInfo')) {
                let itemInfo = urlParams[item].split('=');
                let infoDetail: any = {};
                try {
                    infoDetail = JSON.parse(decodeURIComponent(itemInfo?.[1] || ''));
                } catch (e) {}

                infoDetail = {
                    ...infoDetail,
                    ...fuWuHaoInfo,
                };
                itemInfo[1] = encodeURIComponent(JSON.stringify(infoDetail));
                urlParams[item] = itemInfo.join('=');
            }
        }
    } catch (e) {
        console.log('-----error', e);
    }
    res = urlParams.join('&');

    return res;
};
