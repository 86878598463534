/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-param-reassign */
import {
    ComponentJson,
    // Render,
    RenderOpt,
    PageMetaJson,
    Render,
} from 'omega-render';

import InteractivePopup from '../components/InteractivePopup';
import {
    sendPerfLogLog,
    trackDynamicElementNumber,
    trackIntelligentPageId,
    trackIntelligentReplaceDataTime,
    trackIntelligentUnknownError,
} from './adTrack/monitor';

import { getPageId, sendData } from './adTrack/util/sendData';
import { mpReport, EVENT_TYPE } from './adTrack/util/track';
import { importBridge } from './bridge';
import { componentsMap } from './componentsInfo.gen';
import Config from './config';
// import upgradeCDN from './upgradeCDN';
import dynamicTrans, { getSendData } from './dynamicComponents';
import handlePageMeta from './handlePageMeta';
import { trackHoc } from './hoc/trackHoc';
import { modelMap } from './model';
import { pageInfoModel } from './model/pageInfoModel';
import { initRadar } from './radar';
import styles from './style.module.less';
import updateComponent from './jsonTransform/updateComponent';
import { isFalsy, isInApp, isProduction, RenderEnv } from './utils/env';
import { getUrlParam } from './utils/getUrlParam';
import { initVConsole } from './vconsole';
import asyncUpdateFormComponent from './jsonTransform/updateFormUserAgreement';
import { fillSuggestContent } from './brandSuggest';
import upgradeCDN from './upgradeCDN';
import { handlePCPreview } from './handlePCPreview';
import intelligentReplace from './intelligentPage';
import { initHeatmapSdk } from './utils/heatmap';
import { isInWhiteDomain } from './utils/domainLimit';
import { sendLoadLog, sendImpressionLog } from './adTrack/adBridgeLog';
import { initWeblog, setEnterPageTimeStamp } from './weblog';
import { errorHoc } from './errorBoundary';
import { reportPerformanceTime } from './adTrack/performanceLog';
import { findLinkId, findPlanId, getWexinKefuType, sendImpression } from './impression';
import { EnumPageType } from 'omega-shared/types';
import { getCookieByName } from './adTrack/monitor/adBaseLog';
import { getContainerType } from './utils/getContainerType';
import { CONTAINER_TYPE } from 'landingPage/components/Blank/utils/useContainerStatus';
import { invoke } from '@yoda/bridge';
import { getCallbackInfoByBridge } from './adTrack/util/fetchCallbackByBridge';
import { isQwDirectCall } from './utils/qwDirectCall';
import { initLianmengBridgeSDK } from './utils/lianmengBridge';
import { assembleComps } from './utils/assembleComps';

if (getUrlParam('moliDebug')) {
    initVConsole();
}
if (window.__wxjs_environment !== undefined) {
    try {
        performance.mark('mploadstart');
        const jssdk = document.createElement('script');
        jssdk.setAttribute('src', 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js');
        jssdk.onload = () => {
            window?.wx?.miniProgram?.postMessage({
                data: {
                    hook: 'leave', // 关闭webview | 分享给好友
                    action: 'start', // 上报
                    data: {
                        entries: performance.getEntries().map((e) => e.toJSON()),
                        now: Date.now(),
                    }, // 上报相关的数据，待约定
                },
            });
        };
        document.head.appendChild(jssdk);
    } catch (e) {
        console.log('加载小程序sdk失败', e);
    }
}

/**
 * 组件解析器
 * @param components
 * @param opt
 * @param pageJson
 */
export async function umdRenderEntry(
    components: ComponentJson[],
    opt?: RenderOpt,
    pageJson?: {
        preview?: boolean;
        pageTitle?: string;
        id?: string;
        outPageId: string;
        type: number;
        pageMeta?: PageMetaJson;
    },
) {
    if (!isInWhiteDomain()) return;
    // 是否是企微直跳，如果是则不进行后续渲染动作
    let flag = await isQwDirectCall(pageJson, components);
    if (flag) {
        return;
    }
    if (!isInApp) {
        initLianmengBridgeSDK();
    }
    if (getUrlParam('lpFromChannel') === '1') {
        /** 链接中携带服务号场景参数时，从bridge中前置获取callback信息 */
        await getCallbackInfoByBridge();
    }
    const pageType = getUrlParam('pageType');
    handlePCPreview(opt?.edit, opt?.preview);
    // 非优选情况下发送数仓曝光埋点
    if (pageJson?.type !== EnumPageType.优选) {
        sendImpression();
    }
    setEnterPageTimeStamp();
    initWeblog();
    console.log('this is ====== yoda2.0 ---111');

    /**
     *  preview预览状态判断
     *  1. 渲染参数opt传入preview参数
     *  2. pageJson中携带
     *  3. 通过getPreview和getPageHtml路由访问
     *  4. pageType = 2， 批量生成小说落地页预览
     *
     * TODO：后续收口preview状态，到1或者2中
     */
    const preview =
        !!opt?.preview ||
        !!pageJson?.preview ||
        window.location.pathname.includes('getPreview') ||
        window.location.pathname.includes('getPageHtml') ||
        String(pageType) === '2';
    RenderEnv.init(preview, !!opt?.edit);
    // 落地页组件版本升级逻辑
    updateComponent(components);
    // 【CDN域名逃生】：更新图片CDN的host
    components = upgradeCDN(components);

    // listenPlcContainerStatus();

    const pageJsonComponents = await assembleComps(components);

    //是否需要隐藏agreement组件
    if (pageJson?.pageMeta?.hideAgreement) {
        pageJsonComponents.pop();
    }

    // 建站渲染时，在所有组件最后插入用户协议
    const instance = new Render([...pageJsonComponents], {
        ...opt,
        componentHoc: [...(opt?.componentHoc ?? []), trackHoc],
        appHoc: [errorHoc],
        preview,
        componentsMap,
        injectModel: modelMap,
        independentComponents: [!opt?.edit && !preview && InteractivePopup].filter(
            (a) => !!a,
        ) as React.ComponentType[],
        originalPageJson: pageJson,
    });

    // RadarWorker.postMessage({
    //     type: 'init-components',
    //     data: components.slice(),
    //     location: 'landpage', // 页面标识别
    // }); // 统计首屏的

    if (opt?.node) {
        // 此处为undefined？
        opt.node.classList.add(styles.root);

        if (opt?.needCoverBlank) {
            opt.node.classList.add(styles.editView);
        }
    }
    instance.init();
    const { store } = instance.componentTree.pageStore;
    asyncUpdateFormComponent(components, store);
    if (!preview && isProduction) {
        // 激励广告完播时跳转落地页，会携带incentiveComp参数，此时需要落地页锚点到页面中第一个表单
        if (getUrlParam('incentiveComp')) {
            const node = document.querySelector(`.com-form`);
            // 获取表单距页面顶部高度
            const top = node?.getBoundingClientRect().top;
            // 滚动整个页面，避免出现  BLANK底部> 最后一个组件底部，导致页面滚动后，顶部组件被裁切
            window.scrollTo(0, top ?? 0);

            /** 另一个方案：
             * 获取表单父元素并用scrollIntoView 这样是保证表单底部展示在可视范围内
             * 考虑到有的表单超长，超过一屏，从体验上来说保持表单顶部对齐页面顶部会更好一点
             * */
            // const parent = node?.parentElement;
            // parent?.scrollIntoView(false);

            sendPerfLogLog({
                subtag: 'MOLI_AD_PARAM',
                extra1: 'incentiveComp',
                extra2: node ? '1' : '0',
            });
        }

        Config.initializeConfig(preview);
        console.log('importBridge');
        await importBridge();
        // 获取bridge之后注册事件
        Config.leaveTrack();
        initRadar();

        if (window.__wxjs_environment !== undefined) {
            try {
                const jssdk = document.createElement('script');
                jssdk.setAttribute('src', 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js');
                jssdk.onload = () => {
                    window?.wx?.miniProgram?.postMessage({
                        data: {
                            hook: 'leave', // 关闭webview | 分享给好友
                            action: 'report', // 上报
                            data: mpReport(), // 上报相关的数据，待约定
                        },
                    });
                    window?.wx?.miniProgram?.postMessage({
                        data: {
                            hook: 'leave', // 关闭webview | 分享给好友
                            action: 'end', // 上报
                            data: {
                                entries: performance.getEntries().map((e) => e.toJSON()),
                                now: Date.now(),
                            }, // 上报相关的数据，待约定
                        },
                    });
                };
                document.head.appendChild(jssdk);
            } catch (e) {
                console.log('加载小程序sdk失败', e);
            }
        }
    }

    /** 处理pageMeta & 页面级别的一些操作埋点 */
    handlePageMeta({
        pageMeta: pageJson?.pageMeta ?? {
            anchorForm: { checked: true, formIndex: '0' },
            pageModal: { isShowModal: false },
        },
        edit: opt?.edit,
        preview: opt?.preview,
        store: store,
    });

    /** 打点统计不一致pageId */
    if (pageJson?.outPageId && String(pageJson?.outPageId) !== getPageId()) {
        sendPerfLogLog({
            subtag: 'MOLI_CLIENT_INCONSISTENT_PAGE_ID',
            extra1: String(pageJson?.outPageId),
            extra2: String(getPageId()),
        });
    }
    if (pageJson?.pageTitle) {
        const { store } = instance.componentTree.pageStore;
        // 初始化bizId
        store.dispatch(
            pageInfoModel.actions.setModelState({
                pageTitle: pageJson.pageTitle,
            }),
        );
    }
    function ifHaveStorePage() {
        if (window.pageJsonString) {
            return Number(window.pageJsonString.includes('STORE_PAGE'));
        }
        return 0;
    }
    //plc容器时设置顶部导航栏title需要额外的bridge
    if (getContainerType() === CONTAINER_TYPE.halfScreen) {
        invoke('plcCodContainer.updateNavigationBar', {
            titleText:
                pageJson?.pageTitle ||
                (pageJson?.type === EnumPageType.PLC中间页 ? '应用信息' : '广告详情页'),
        });
    }
    // 动态渲染
    if (pageJson?.type === EnumPageType.优选) {
        try {
            trackDynamicElementNumber(pageJson?.id as string);
            await dynamicTrans(components, pageJson?.id as string);
            const store = instance.componentTree.pageStore;
            store.setPageJson(await assembleComps(components), {
                ...opt,
                preview,
                originalPageJson: pageJson,
            });
            const reportData = (await getSendData(pageJson?.id as string)) || '';

            const finalData = {
                isPreLandingpage: window.isPreLandingPage || 0,
                elementImpressionId: reportData,
                isIncludeStore: ifHaveStorePage(),
                link_id: findLinkId(),
                followPlanId: findPlanId(),
                // https://docs.corp.kuaishou.com/k/home/VRCBe0Bu4fdA/fcACIWRevXHRjfuE49wX3pdeu
                isLogin: !isFalsy(getCookieByName('userId')),
                uid: getCookieByName('userId') || '',
            };
            sendData(
                {
                    eventType: EVENT_TYPE.impression,
                    lp_page_type: 1,
                    clientTimestamp: new Date().getTime(),
                    wechatActionScene: getWexinKefuType(),
                    lpPageId: getUrlParam('pageId'),
                    diyExtraInfo: JSON.stringify(finalData),
                },
                'POST',
            );
        } catch (e) {
            console.log(e);
        }
    }
    if (
        [
            EnumPageType.智能中间页,
            EnumPageType.大图中间页,
            EnumPageType.大白中间页,
            EnumPageType.PLC中间页,
        ].includes(pageJson?.type || 0)
    ) {
        try {
            let currentTime = +new Date();
            trackIntelligentPageId(pageJson?.id as string);
            await intelligentReplace(components, pageJson?.id as string, pageJson?.type || 0);
            const store = instance.componentTree.pageStore;
            store.setPageJson(await assembleComps(components), {
                ...opt,
                preview,
                originalPageJson: pageJson,
            });
            /**
             * 追踪中间页数据获取时间
             */
            trackIntelligentReplaceDataTime(+new Date() - currentTime);
        } catch (e) {
            console.log('error', e);
            trackIntelligentUnknownError(pageJson?.id as string, e);
        }
        sendLoadLog();
        window?.fmpValue && sendImpressionLog(window?.fmpValue);
    }

    // 小说增加推荐组件
    fillSuggestContent(instance, pageJson, components);
    // console.log('!preview && !opt?.edit && isTest', !preview && !opt?.edit && isTest, isTest, process.env, process.env.REACT_APP_STAGE)
    // initVConsole();
    initHeatmapSdk();
    // 自定义性能埋点上报
    reportPerformanceTime();
    return instance;
}
