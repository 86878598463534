/** 构建一个 Pending 状态的 Promise，同时返回 resolver 和 rejector */
export function createPromiseAndExecutor<T>() {
    let promiseResolver: (value: T) => void = () => {};
    let promiseRejector: (reason?: any) => void = () => {};
    const promise: Promise<T> = new Promise((resolve, reject) => {
        promiseResolver = resolve;
        promiseRejector = reject;
    });

    return {
        selfPromise: promise,
        promiseResolver,
        promiseRejector,
    };
}
