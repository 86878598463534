import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { invoke } from '@yoda/bridge';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import {
    deepLinkInvokeError,
    trackBridgeError,
    trackBridgeSuccess,
    trackDeepLinkInvoke,
} from 'landingPage/common/adTrack/monitor';
import { callApp } from 'landingPage/common/utils/callApp';
import { isInApp } from '@/landingPage/common/utils/env';
import { getDeepLinkType } from '../utils';

export const iosDownload = async (deepLink, serverIOSLink, iOSLink) => {
    const isiOSAppointment = getUrlParam('iOSAppointment') === 'true';
    if (isiOSAppointment) {
        dataTrack({
            eventType: 'EVENT_APPOINT_DOWNLOAD',
            diyExtraInfo: JSON.stringify({
                renderType: 'APP_DOWNLOAD_DRAG',
            }),
        });
        // getUrlParam('callback') && trackDownload(appInfo, id ?? '');
    } else {
        dataTrack({
            eventType: 'EVENT_GOTO_APPSTORE',
            diyExtraInfo: JSON.stringify({
                renderType: 'APP_DOWNLOAD_DRAG',
            }),
        });
    }
    // dataTrack({ eventType: 'EVENT_FORM_SUBMIT' });

    if (deepLink) {
        const schema = deepLink.split('://')[0];
        console.log('schema', schema, deepLink);
        try {
            let hasInstalled = '';
            if (isInApp) {
                const { installed } = await invoke('tool.checkAppInstalled', {
                    ios: `${schema}://`,
                });
                hasInstalled = installed;
            } else {
                const { data } = await bridge.checkAppInstalled({
                    ios: `${schema}://`,
                });

                hasInstalled = data?.isInstalled;
            }

            console.log('[res]', hasInstalled);
            if (hasInstalled) {
                console.log('success: ', hasInstalled);
                trackBridgeSuccess(`[iOS]hasInstalledApp${deepLink}`);
                trackDeepLinkInvoke(getDeepLinkType(deepLink), 'APP_DOWNLOAD_DRAG');
                callApp({
                    link: deepLink,
                    url: '',
                    successCallBack: () => {
                        dataTrack({
                            eventType: 'EVENT_DEEPLINK_INVOKED',
                            deeplink_app_type: getDeepLinkType(deepLink),
                            diyExtraInfo: JSON.stringify({
                                renderType: 'APP_DOWNLOAD_DRAG',
                            }),
                        });
                    },
                    failCallBack: () => {
                        dataTrack({
                            eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                            deeplink_app_type: getDeepLinkType(deepLink),
                            diyExtraInfo: JSON.stringify({
                                renderType: 'APP_DOWNLOAD_DRAG',
                            }),
                        });
                        deepLinkInvokeError(getDeepLinkType(deepLink), 'APP_DOWNLOAD_DRAG');
                    },
                });
            } else {
                console.error('err: ', hasInstalled);
                //正常未安装应用，不应该归属到trackBridgeError的报警中
                // trackBridgeError('[iOS]hasInstalledApp', deepLink + installed);
                console.log(serverIOSLink, iOSLink);
                window.location.href = serverIOSLink || iOSLink || '';
            }
        } catch (e) {
            console.error('err: ', JSON.stringify(e));
            trackBridgeError('[iOS]hasInstalledApp', deepLink + JSON.stringify(e));
            window.location.href = serverIOSLink || iOSLink || '';
        }
    } else {
        window.location.href = serverIOSLink || iOSLink || '';
    }

    return;
};
