import { isStaging, isDevelopment, isLocalhost, isPrt } from './env';
// import { getDeviceType, DEVICE_TYPE, isHarmony } from './getDeviceInfo';

// const envUrlMap = {
//     'ad-lp.test.gifshow.com': 'featstore.jinx.corp.kuaishou.com',
//     'feat-coupon-card--kuaishou-frontend-ad-site-editor.jinx.corp.kuaishou.com':
//         'coupon.jinx.corp.kuaishou.com',
//     'ad-lp01.test.gifshow.com': 'ad-geimini-test01.test.gifshow.com',
//     'ad-lp02.test.gifshow.com': 'ad-geimini-test02.test.gifshow.com',
// };
/**
 * 获取线索通域名function
 * @param isEditor 是否是编辑器使用该方法；编辑器中需要根据域名 & 环境 返回对应的线索通域名；
 * 非编辑器（落地页，卡券核销页）直接使用建站域名
 */

export const getXianSuoDomain = (isEditor?: boolean) => {
    // // ios 小流量实验切换域名
    // const isIos = getDeviceType() === DEVICE_TYPE.IOS;
    /** 测试域名不切换,prt和线上域名都使用建站域名，保证cookie能够正常携带（所有端都一样）
     * 现在yoda不允许跨域携带cookie了
     */
    if (!isEditor) {
        return '';
    }
    if (isLocalhost) {
        return '';
    }
    if (isStaging) {
        return 'https://ad-clue-laotie.staging.kuaishou.com';
    }
    if (isDevelopment) {
        return 'https://ad-clue-laotie.staging.kuaishou.com';
    }
    if (isPrt) {
        return 'https://xiansuo.prt.kuaishou.com';
    }
    return 'https://xiansuo.kuaishou.com';
};
