import Modal from 'landingPage/common/components/Modal/Modal';
import * as React from 'react';
import { Toast } from '@ad/sharpui';
import { DIALOG_STYLE, useComContext, WeChatType } from 'omega-render';
import { ComponentZIndex } from 'omega-shared/utils';
import { dataTrack } from 'landingPage/common/utils/trackAd';
import { DialogInfo, Applets } from '../../WeiXinDrag/WeiXin';
import {
    DialogStyleModal,
    fetchWechatId,
    useRandomItem,
} from '../../WeiXinDrag/components/commonFun';
import styles from './index.module.less';
import { JumpType, SystemType } from '../transformFormSetting';
import { callApp } from 'landingPage/common/utils/callApp';
import { DEVICE_TYPE, getDeviceType } from 'landingPage/common/utils/getDeviceInfo';
import { DeeplinkAppType } from 'landingPage/components/ButtonDrag/Button';
import { deepLinkInvokeError } from 'landingPage/common/adTrack/monitor';
import { AfterSuccessDisplayType } from 'omega-shared/types';
import { SelectItem } from '../../XiansuoFormMulti/components/IMComponent';
import { handleFormJumpUrl } from '../utils';
import { createPromiseAndExecutor } from 'landingPage/common/utils/promise';
import { getPageId } from '@/landingPage/common/utils/getPageId';
import { postCheckOutLink } from '@/landingPage/services/apis';

export enum DialogTypeEnum {
    DEFAULT = 1,
    QRCODE = 2,
}

export interface SubmitTurnValue {
    type: 'taobao' | 'ios';
    url: string;
}
export interface IWeiXinModalProps {
    afterSubmitText?: string;
    submitTurnValue?: SubmitTurnValue;
    afterSuccessDisplayType?: AfterSuccessDisplayType;
    info?: string;
    opt?: any;
    // 1 是单个号码 2 是号码包
    wechatType?: WeChatType;
    dialogStyle?: DialogTypeEnum;
    dialogStyleType?: DIALOG_STYLE;
    preview?: boolean;
    show?: boolean;
    onClose?: () => void;
    // 提交后时候转跳
    switch?: boolean;
    // 控制表单提交后设置的开关
    jumpSwitch?: boolean;
    isEdit?: boolean;
    isFenbuMessageType?: boolean;
    applets?: Applets; // 微信小程序的信息
    appletSwitchState?: {
        checked: boolean;
        disabled: boolean;
    }; // 小程序开关的状态
    showAppointment?: boolean;
    selectFormItemList?: SelectItem[];
    chatFormId?: string;
    needSendEffectiveAction?: boolean;
}

const { selfPromise: checkFormTargetUrlValidPromise, promiseResolver } = createPromiseAndExecutor();

/** 和url拼接组成唯一值，标识该链接是否需要走接口校验 */
const NEED_CHECK_FORM_URL_KEY = 'NEED_CHECK_FORM_URL_KEY';

export function WeiXinModal(props: IWeiXinModalProps) {
    const {
        info,
        opt,
        wechatType,
        preview,
        afterSubmitText,
        submitTurnValue,
        afterSuccessDisplayType,
        show,
        onClose,
        isFenbuMessageType,
        applets,
        dialogStyleType,
        showAppointment,
        selectFormItemList,
        chatFormId,
        needSendEffectiveAction,
    } = props;
    const { edit } = useComContext();
    // eslint-disable-next-line react/destructuring-assignment
    const isNeedJump = props.switch && props.jumpSwitch;
    const dialogInfoMemo = useRandomItem(props) as DialogInfo;
    const [dialogInfo, setDialogInfo] = React.useState(dialogInfoMemo);
    React.useEffect(() => setDialogInfo(dialogInfoMemo), [dialogInfoMemo]);

    const [minuter, setMinuter] = React.useState(0.5);

    const linkValidRef = React.useRef({});

    // const [schemaRes, setSchemaRes] = React.useState({ schema: '', message: '' });

    React.useEffect(() => {
        setMinuter(0.5);
    }, [show]);

    React.useEffect(() => {
        const checkTargetUrlValid = async () => {
            const jumpInfo = getJumpInfo(submitTurnValue);
            const links: string[] = [];
            if (jumpInfo) {
                jumpInfo.url && links.push(jumpInfo.url);
                jumpInfo.dpBackupH5Url && links.push(jumpInfo.dpBackupH5Url);
            }

            // 防止接口一直不返回，5s后直接resolve
            setTimeout(() => {
                promiseResolver('done');
            }, 5000);

            const linkValid = {};

            if (links.length > 0 && !!getPageId()) {
                try {
                    const linkChckList = await Promise.all(
                        links.map((link) => {
                            return postCheckOutLink({ pageId: getPageId(), url: link as string });
                        }),
                    );
                    console.log('linkChckList---->form', linkChckList);
                    linkChckList.forEach((item) => {
                        // newLogicSwitch 为true 走新的接口校验逻辑取status，为false 走老逻辑默认不校验，默认认为接口校验通过了
                        linkValid[item.url] = item.newLogicSwitch ? item.status : true;
                        linkValid[`${item.url}_${NEED_CHECK_FORM_URL_KEY}`] = item.newLogicSwitch;
                    });
                } catch (e) {
                    console.log('postCheckOutLink error', e);
                }
            }
            linkValidRef.current = { ...linkValidRef.current, ...linkValid };
            promiseResolver('done');
        };

        checkTargetUrlValid();
    }, []);

    React.useEffect(() => {
        if (!edit) {
            let timer: number;
            if (
                isNeedJump &&
                show &&
                afterSuccessDisplayType === AfterSuccessDisplayType.LINK &&
                minuter > 0 &&
                !preview
            ) {
                timer = window.setInterval(() => {
                    setMinuter(minuter - 0.5);
                }, 500);
            }
            if (minuter === 0 && show) {
                // window.location.href = submitTurnValue?.url;
                submitReturnJump(submitTurnValue);
            }
            return () => {
                if (timer) {
                    clearInterval(timer);
                }
            };
        }
        // eslint-disable-next-line
    }, [submitTurnValue, afterSuccessDisplayType, minuter, preview, show]);

    const getJumpInfo = (submitTurnValue) => {
        if (!submitTurnValue) {
            return null;
        }
        let jumpInfo: null | {
            jumpType: JumpType;
            url: string;
            dpBackupH5Url: string;
        } = null;
        if (submitTurnValue.systemType !== SystemType.分端) {
            // 全局和历史数据
            console.log('enter global');
            jumpInfo = submitTurnValue;
        } else {
            switch (getDeviceType()) {
                case DEVICE_TYPE.IOS:
                    jumpInfo = submitTurnValue.iosConfig;
                    break;
                case DEVICE_TYPE.HARMONY:
                    //鸿蒙场景，分端跳转的，如果配置了鸿蒙的分端跳转就使用；如果没配置，使用安卓的做一次兜底
                    jumpInfo = submitTurnValue?.harmonyConfig || submitTurnValue.androidConfig;
                    break;
                default:
                    jumpInfo = submitTurnValue.androidConfig;
                    break;
            }
        }

        return jumpInfo;
    };
    //  表单提交后跳转
    const submitReturnJump = async (submitTurnValue) => {
        await checkFormTargetUrlValidPromise;

        const jumpInfo = getJumpInfo(submitTurnValue);

        if (jumpInfo?.jumpType === JumpType.DP链接) {
            // deeplink
            console.log('xiansuoSdk callApp');
            callApp?.({
                link: jumpInfo?.url,
                url: jumpInfo?.dpBackupH5Url,
                successCallBack: () => {
                    dataTrack({
                        eventType: 'EVENT_DEEPLINK_INVOKED',
                        deeplink_app_type: DeeplinkAppType.UNKNOWN_DEEPLINK_APP_TYPE,
                        diyExtraInfo: JSON.stringify({
                            renderType: 'XIANSUO_FORM_DRAG',
                        }),
                    });
                },
                failCallBack: () => {
                    dataTrack({
                        eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                        deeplink_app_type: DeeplinkAppType.UNKNOWN_DEEPLINK_APP_TYPE,
                        diyExtraInfo: JSON.stringify({
                            renderType: 'XIANSUO_FORM_DRAG',
                        }),
                    });
                    deepLinkInvokeError(DeeplinkAppType.TAOBAO, 'XIANSUO_FORM_DRAG');
                },
                linkValidMap: linkValidRef.current,
            });
        } else {
            console.log('enter h5');
            let targetUrl = jumpInfo?.url;

            if (linkValidRef.current && targetUrl && linkValidRef.current[targetUrl] === false) {
                onClose?.();
                Toast.error('链接失效');
                return;
            }
            targetUrl = await handleFormJumpUrl(targetUrl);

            // return setTimeout(() => {
            targetUrl && (window.location.href = targetUrl);
            // }, 2000);
        }
    };
    const updateWechatInfo = React.useCallback(() => {
        let hasError = false;
        fetchWechatId({
            opt,
            wechatType,
            dialogInfo,
            dialogStyleType: dialogStyleType ?? DIALOG_STYLE.DEFAULT,
            onError: () => {
                hasError = true;
            },
        }).then((res) => {
            if (!hasError) {
                res && setDialogInfo(res);
            } else {
                setDialogInfo({ itemId: '', wechatId: '', packId: '', qrcode: '' });
            }
        });
    }, [dialogInfo, dialogStyleType, opt, wechatType]);

    React.useEffect(() => {
        updateWechatInfo();
    }, []);

    React.useEffect(() => {
        if (edit && show && wechatType === WeChatType.PACKAGE && opt?.id) {
            updateWechatInfo();
        }
    }, [show, edit, wechatType, opt?.id, dialogStyleType]);

    const WeChatDialogStyle = (
        <DialogStyleModal
            dialogStyleType={dialogStyleType ?? DIALOG_STYLE.DEFAULT}
            dialogInfo={dialogInfo}
            opt={opt}
            info={info ?? '添加微信号了解更多资讯'}
            applets={applets}
            onClose={onClose}
            renderType="XIANSUO_FORM_DRAG"
            formInfo={selectFormItemList}
            formId={chatFormId}
            needSendEffectiveAction={needSendEffectiveAction}
        />
    );
    // 普通表单成功后弹框
    const Content = (
        <>
            {isNeedJump && afterSuccessDisplayType === AfterSuccessDisplayType.WE_CHAT ? (
                WeChatDialogStyle
            ) : (
                <div className={styles.preview}>
                    <div className={styles.content}>
                        <div className={styles.head}>
                            <div className={styles.close} onClick={onClose} />
                        </div>
                        <div className={styles.box}>
                            <div className={styles.success} />
                            <div className={styles.successText}>提交成功</div>
                            {/* eslint-disable-next-line react/destructuring-assignment */}
                            {props.afterSubmitText && props.afterSubmitText !== '提交成功！' && (
                                <div className={styles.successInfo}>{afterSubmitText}</div>
                            )}
                            {isNeedJump &&
                                afterSuccessDisplayType === AfterSuccessDisplayType.LINK && (
                                    <div className={styles.time}>
                                        {minuter}
                                        秒后自动跳转…
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    const modalParent = edit
        ? (document.getElementById('preview-app') ?? document.body)
        : undefined;

    // 分层表单不展示弹窗
    if (afterSuccessDisplayType === AfterSuccessDisplayType.TIER_FORM) {
        return <></>;
    }

    // 分布消息类型弹框
    if (isFenbuMessageType) {
        if (!show) {
            return <></>;
        }
        if (
            dialogStyleType === DIALOG_STYLE.KEFU ||
            dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT ||
            dialogStyleType === DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP
        ) {
            return (
                <Modal parent={modalParent} style={{ zIndex: ComponentZIndex.WechatZIndex }}>
                    {WeChatDialogStyle}
                </Modal>
            );
        }
        if (dialogStyleType !== DIALOG_STYLE.JUMPWX && !dialogInfo?.itemId?.length && !edit) {
            return <>{(Toast.error('暂无可复制的微信号'), onClose?.())}</>;
        }
        if (dialogStyleType === DIALOG_STYLE.JUMPWX || dialogInfo?.itemId?.length) {
            return (
                <Modal parent={modalParent} style={{ zIndex: ComponentZIndex.WechatZIndex }}>
                    {WeChatDialogStyle}
                </Modal>
            );
        }
        return <></>;
    }

    if (preview) {
        return Content;
    }
    if (showAppointment) {
        onClose?.();
        return null;
    }
    return show ? (
        <Modal parent={modalParent} style={{ zIndex: ComponentZIndex.WechatZIndex }}>
            {Content}
        </Modal>
    ) : (
        <></>
    );
}
