import React from 'react';
import cs from 'classnames';
import { useComContext, useRenderState } from 'omega-render';
import { ComponentType } from 'landingPage/common/componentsInfo.gen';
import { trackerAd } from 'landingPage/common/utils/trackAd';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { callApp } from 'landingPage/common/utils/callApp';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { Div } from '../Blank/Blank';
import { hasFormOrDragForm, ButtonJumpType, DeeplinkAppType } from '../ButtonDrag/Button';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { getTransCompList } from 'landingPage/common/impression';
import { useWhiteListHooks } from 'landingPage/common/hooks/useWhiteListHooks';

export interface ButtonProps {
    text: string;
    buttonStyle?: React.CSSProperties;
    // 按钮外部背景色
    backgroundColor?: string;
    /** 是否展示下载按钮 */
    showIcon?: boolean;
    /** 自定义图标 */
    icon?: React.ReactNode;
    /** 是否吸低 */
    bottom?: boolean;
    /** 跳转链接配置 */
    targetUrl?: string;
    /** 暂时没有用到，必须为deepLink */
    targetType?: string;
    /** 有onClick时，自定义跳转动作，targetType和targetUrl时效 */
    onClick?: () => void;
    className?: string;
    loadend?: any;
    index?: number;
}

const { dataTrack } = trackerAd;

const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const {
        className,
        text,
        buttonStyle,
        backgroundColor,
        showIcon,
        bottom,
        targetUrl,
        targetType,
        children,
        icon,
        loadend,
        onClick,
        ...rest
    } = props;
    const hasForm = useRenderState((state) => {
        return hasFormOrDragForm(state.rolex.components);
    });
    const whiteListData = useWhiteListHooks();
    const buttonClick = React.useCallback(() => {
        if (onClick) {
            onClick();
            return;
        }
        (async () => {
            if (targetType === 'deepLink' && targetUrl) {
                // 调起淘宝返回后，可能会循环调起多次
                const baseUrl =
                    /* tslint:disable-next-line max-line-length */
                    'tbopen://m.taobao.com/tbopen/index.html?action=ali.open.nav&appkey=24648154&packageName=com.smile.gifmaker&module=h5&h5Url=';
                const backSchema =
                    getProduct() === 13
                        ? 'ksnebula://action/bringToFront'
                        : 'kwai://action/bringToFront';
                const backUrl = `backURL=${encodeURIComponent(backSchema)}`;
                const url = `${baseUrl}${encodeURIComponent(targetUrl)}&${backUrl}`;

                dataTrack({
                    eventType: 'EVENT_BUTTON_CLICK',
                    button_jump_type: ButtonJumpType.DEEPLINK_INVOKED,
                    deeplink_app_type: DeeplinkAppType.TAOBAO,
                });
                if (!hasForm) {
                    // 如果落地页不存在表单按钮同时存在需要添加埋点 form_submit
                    // const tracker = window.trackerAd;
                    if (trackerAd && whiteListData.status) {
                        dataTrack({
                            eventType: 'EVENT_FORM_SUBMIT',
                            diyExtraInfo: JSON.stringify({
                                renderType: 'BUTTON_DRAG',
                                landing_page_infos: JSON.stringify({
                                    page_module_type: getTransCompList(),
                                }),
                            }),
                        });
                    }
                }

                await callApp({
                    link: url,
                    url: targetUrl,
                    successCallBack: () => {
                        dataTrack({
                            eventType: 'EVENT_DEEPLINK_INVOKED',
                            deeplink_app_type: DeeplinkAppType.TAOBAO,
                        });
                    },
                });
            }
        })();
    }, [hasForm, onClick, targetType, targetUrl, whiteListData]);
    const { type, edit } = useComContext();
    const componentType = type as ComponentType;

    return (
        <>
            <Div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                data-component-type={componentType}
                className={cs(styles.root, className, {
                    [styles.bottom]: bottom,
                })}
                style={{ backgroundColor }}
            >
                <div
                    className={cs(styles.button, {
                        [styles.icon]: showIcon,
                    })}
                    onClick={buttonClick}
                    style={buttonStyle}
                >
                    {icon}
                    {text}
                    {!bottom && children}
                </div>
                {bottom && (
                    <>
                        <BlockPlaceholder pos="BOTTOM" height={68} preview={edit} />
                        <SafeAreaPlace />
                    </>
                )}
            </Div>
        </>
    );
};

export default Button;
