import PermissionInfo from '../PermissionInfo';

interface PrivacyForPlcMidPageProps {
    showDownloadBtn: boolean;
    fontSize: number;
    textColor: string;
    btnTextColor: string;
    appPrivacyUrl: string;
    permissions: [];
    appDeveloper: string;
    appVersion: string;
    alignType: string;
    platform: 'android' | 'ios';
}
export default function PrivacyForPlcMidPage(props: PrivacyForPlcMidPageProps) {
    const {
        textColor,
        permissions,
        showDownloadBtn,
        appPrivacyUrl,
        fontSize,
        alignType,
        appDeveloper,
        appVersion,
    } = props;
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: alignType }}>
            <span
                style={{
                    color: textColor,
                    fontSize,
                }}
            >
                {appDeveloper}
            </span>
            <span style={{ margin: '0 4px', color: textColor, fontSize: fontSize }}>|</span>
            <span
                style={{
                    color: textColor,
                    fontSize,
                }}
            >
                版本 {appVersion}
            </span>
            <span style={{ margin: '0 4px', color: textColor, fontSize: fontSize }}>|</span>
            <PermissionInfo
                text="权限"
                permissions={permissions}
                color={textColor}
                showDownloadBtn={showDownloadBtn}
                fontSize={fontSize}
                style={{ lineHeight: undefined }}
            />
            <span style={{ margin: '0 4px', color: textColor, fontSize: fontSize }}>|</span>
            <span
                style={{
                    color: textColor,
                    fontSize,
                }}
                onClick={() => {
                    window.open(appPrivacyUrl);
                }}
            >
                隐私
            </span>
        </div>
    );
}
