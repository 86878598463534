import { useEffect, useState, useRef, useMemo } from 'react';
import styles from './style.module.less';
import cs from 'classnames';

const maxLine = 3;
// const imageUrl =
//     'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-09-14/1694681990741.1c9de56fec247dad.png';

const config = {
    SPACE_LEN: 5, // 空几个字符
    SHOW_MORE_FLAG: '...',
};

interface IProps {
    text: string;
    style: React.CSSProperties;
    showMoreText?: string;
    collapseTextColor?: string;
}
const CollapseText: React.FunctionComponent<IProps> = (props) => {
    const { text, style, showMoreText = '展开', collapseTextColor } = props;
    const textRef = useRef<HTMLDivElement | null>(null);

    const originDescText = text.replace(/<br\/>/g, '');

    const descText = useMemo(() => {
        return text.replace(/<br\/>/g, '');
    }, [text]);

    const [expand, setExtend] = useState(false);
    const [isOverFlow, setIsOverFlow] = useState(false);
    const [outHeight, setOutHeight] = useState({
        minHeight: 0,
        maxHeight: 0,
    });
    const [curContent, setCurContent] = useState(originDescText);
    useEffect(() => {
        const minHeight = getHeight('-') * maxLine;
        const maxHeight = getHeight(descText);
        setOutHeight({
            minHeight,
            maxHeight,
        });
        const isOverflow = checkIsOverFlow(descText, minHeight);
        setIsOverFlow(isOverflow);
        if (!isOverflow) {
            setCurContent(descText);
        }
    }, [descText]);

    useEffect(() => {
        trimContentToFit();
    }, [isOverFlow]);

    // 获取一段文字的行高
    const getHeight = (text) => {
        const matrixing = document.getElementById('matrixing');
        textRef.current && (textRef.current.textContent = text.replace(/<br\/>/g, ''));
        return matrixing?.offsetHeight || 0;
    };
    // 判断文字是否溢出
    const checkIsOverFlow = (text, minHeight) => {
        return getHeight(text) > minHeight;
    };
    // 点击更多按钮
    const showMore = () => {
        setExtend(true);
        setCurContent(descText);
    };

    // 截取content，后面加...用于显示
    const trimContentToFit = () => {
        let cur = descText;
        if (!isOverFlow) return;
        const { SPACE_LEN, SHOW_MORE_FLAG } = config;
        while (checkIsOverFlow(cur, outHeight.minHeight) && cur.length > 0) {
            cur = cur.slice(0, cur.length - 1);
        }
        setCurContent(`${cur.slice(0, cur.length - SPACE_LEN)}${SHOW_MORE_FLAG}`);
    };

    return (
        <div
            className={styles.root}
            style={{
                height:
                    !expand && isOverFlow
                        ? outHeight.minHeight + Number(style?.paddingTop || 0)
                        : outHeight.maxHeight + Number(style?.paddingTop || 0),
                ...style,
            }}
        >
            <div
                className={cs(styles.contentWrapper, {
                    // [styles.maxThreeLine]: !expand && isOverFlow,
                })}
                style={{
                    height: !expand ? outHeight.minHeight : outHeight.maxHeight,
                }}
            >
                {curContent.replace(/<br\/>/g, '')}
            </div>
            {!expand && isOverFlow && (
                <div
                    className={styles.expandWrapper}
                    onClick={showMore}
                    style={{ marginTop: -(outHeight.minHeight / maxLine) }}
                >
                    <div
                        style={{
                            color: collapseTextColor ?? style?.color,
                            fontSize: style?.fontSize,
                        }}
                    >
                        {showMoreText}
                    </div>
                    {/* <img src={imageUrl} /> */}
                </div>
            )}
            <div className={styles.matrixingBox}>
                <p id="matrixing" ref={textRef}></p>
            </div>
        </div>
    );
};

export default CollapseText;
