import styles from './style.module.less';
import { useRenderState } from 'omega-render';

export const ReceiveInfo = () => {
    const { commitFormCount, headImgList } = useRenderState((state) => {
        return {
            commitFormCount: state.xiansuoForm?.commitFormCount,
            headImgList: state.xiansuoForm?.headImgList,
        };
    });

    return (
        <>
            {Boolean(commitFormCount) && Boolean(headImgList.length) && (
                <div className={styles.headImg}>
                    {headImgList?.map((item, index) => (
                        <img
                            className={styles.img}
                            src={item}
                            key={`img-${index}`}
                            style={{
                                marginLeft: index > 0 ? '-5px' : 0,
                                zIndex: 3 - index,
                            }}
                        />
                    ))}
                    <div style={{ marginLeft: 8 }}>等{commitFormCount}人已领取了免费福利</div>
                </div>
            )}
        </>
    );
};
